import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '~app/pipes/pipes.module';
import { SharedModule } from '~utils/shared.module';
import { CustomerLayoutComponent } from './customer-layout.component';
import { CustomerDashboardModule } from '~modules/customer-dashboard/customerdashboard.module';
@NgModule({
    imports: [
      RouterModule,
      SharedModule,
      PipesModule,
      CustomerDashboardModule,
    ],
    declarations: [
      CustomerLayoutComponent
    ],
    providers: [],
    exports: []
  })
  export class CustomerLayoutModule {
  }
