import { Component, AfterViewInit, ViewChild, ChangeDetectorRef, OnInit, AfterViewChecked } from '@angular/core';

import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ProductService } from '~services/product.service';
import { AuthService } from '~services/auth.service';

import { GetListRequest, DeleteRequest } from '~app/models';

import { GetProductListRequest, ProductViewModel } from '~models/product/index';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~app/utils/messages';
import { Pager } from '~modules/ng-pager/pager.model';


@Component({
    selector: 'app-client.row',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    providers: [ProductService]
  })
  export class ProductComponent implements AfterViewInit, OnInit, AfterViewChecked {
    pager: Pager;
    key = "";
    public pageSize = 10;
    public currentPage = 0;
    public page = 0 ;
    public isLoading = false;
    public isTotalReached = false;
    public totalItems = 0;
    public search = '';
    public customerId = null;
    public products: ProductViewModel[] = [];
    constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private productService: ProductService,
      private authService: AuthService,
      private router: Router,
      private toastr: ToastrService
        ) { }

    ngOnInit() {
      if (!this.authService.loggedIn.getValue()) {
        this.router.navigate(['/login']);
      }
    }

    ngAfterViewInit() {
      this.getData();
    }
    ngAfterViewChecked() {
      this.changeDetectorRef.detectChanges();
    }

    public onPaginateChange(event: any): void {
      this.page = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      this.getData();
    }

    public applyFilter(filterValue: string): void {
      filterValue = filterValue.trim().toLowerCase();
      this.getData();
    }

    getData(request ?: GetProductListRequest): void {
      if(request == undefined){
        request = new GetProductListRequest();
        request.pageSize = this.pageSize;
      }
      request.key = this.key;
      
      this.productService.getList(request).subscribe(x => {
        this.totalItems = x.totalItems;
        this.page = x.currentPage;
        this.products = x.list;

        this.totalItems = x.totalItems;
        this.currentPage = request==undefined ? 0 : request.pageIndex;
        this.pager = new Pager( x.totalItems, this.currentPage, this.pageSize);
        this.isLoading = true;

      },err=>{
        this.isLoading=false;
      });
    }

    delete(id): void {
      const request = new DeleteRequest();
      request.id = [];
      request.id.push(id);

      this.productService.delete(request).subscribe((data) => {
        this.toastr.success(MESSAGES.product.delete.success);

        const index = this.products.findIndex(x => x.id === id);
        this.products.splice(index, 1);
      },
      (err) => {
        this.toastr.error(MESSAGES.product.delete.error);
      });
    }

  }
