import { Component } from '@angular/core';

@Component({
    selector: 'ck-card-body-content',
    template: `
    <p class="card-text"><ng-content></ng-content></p>
    `,
  })
export class AppCorkCardBodyContentComponent {
    
  }
