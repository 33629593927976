export const MESSAGES = {
    customerLayout:{
        userLevelInfo:
        {
            message:``,
            title:'سطح کاربری',
            okButtonText:'باشه',
            color:'#8030c3'
        }
    },
    authorization : {

    },
    login: {
        success: 'عملیات موفق',
        error: 'خطا',
    },
    register : {
        sendOtp: {
            success: 'عملیات موفق',
            error: 'خطا'
        }
    },
    ticket: {
        update: {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        create: {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        reply : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        changeStatus : {
            success : 'عملیات موفق',
            error : 'خطا'
        }
    },
    user : {
        sendconfirmationmail: {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        sendmobileconfirmation: {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        verifyconfirmationmobile: {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        verifyconfirmationmail: {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        changerole : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        changelock : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        create : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        update : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        delete : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        attachment :{
            create : {
                success : 'عملیات موفق',
                error:'خطا'
            },
            delete :{
                success:'عملیات موفق',
                error :'خطا'
            }
        }
    },
    backaccount : {
        lock : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        unlock : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        lockPermission: {
            error : 'خطا'
        },
        create : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        update : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        delete : {
            success : 'عملیات موفق',
            error : 'خطا'
        }
    },
    variant : {
        update : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        create : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        delete : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
    },
    product : {
        update : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        create : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        delete : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
    },
    variz : {
        update : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        create : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        delete : {
            success : 'واریز با موفقیت حذف شد',
            error : 'خطا در حذف واریز'
        },
        attachment: {
            delete : {
                success : 'پیوست واریز با موفقیت حذف شد',
                error :'خطا در حذف پیوست واریز'
            }
        }
    },
    license : {
        update : {
            success : 'لایسنس با موفقیت بروزرسانی شد',
            error : 'بروزرسانی لایسنس با خطا مواجه شد'
        },
        create : {
            confirmation:{
                content:'آیا صحت کدهای مورد نظر را تایید میکنید؟',
                okButtonText: 'بله',
                cancelButtonText: 'خیر',
                title: 'تایید صحت لایسنس'
            },
            licenseCheck:{
                okButtonText: 'باشه',
                title: 'لایسنس نامعتبر یافت شد'
            },
            success : 'لایسنس های شما با موفقیت ثبت شدند',
            error : 'خطا در ثبت لایسنس',
            verifyMobileRequired:'لطفا موبایل خود را تایید کنید',
            verifyEmailRequired:'لطفا ایمیل خود را تایید کنید',
            verifyNationalCardRequired:'لطفا کد ملی خود را تایید کنید',
            verifyPhoneRequired:'لطفا شماره تلفن خود را تایید کنید'
        },
        delete : {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        sold: {
            success : 'عملیات موفق',
            error : 'خطا'
        },
        conflict: {
            error : 'این کد در سیستم موجود است'
        }
    }

};
