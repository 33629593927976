import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toman'})

  export class TomanPipe extends CurrencyPipe implements PipeTransform {
    transform(value: any, ...args: any[]): string{
        const formatedByCurrencyPipe = super.transform(value, '','','0.0-0');
        console.log(formatedByCurrencyPipe);
        // do your thing...
        return formatedByCurrencyPipe;
    }
  }
