import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login-layout',
    template: `<router-outlet></router-outlet>`
})
export class LoginLayoutComponent {
    constructor() { }
}
