
import { ProductViewModel } from '../product';
import { LicenseStatus } from './LicenseStatus';
import { LicenseViewModel } from './LicenseViewModel';
export class LicenseGroupViewModel {
    licenses?:LicenseViewModel[];
    groupcreatedAt?:string;
    groupProductName?:string;
    groupCount?: number;
    groupPrice?:number;
    groupUser?:string;
    groupUserId?:number;
    groupStatus?: LicenseStatus;
    groupProduct?: ProductViewModel;
    groupIsForSale?: boolean;
    groupId?:string;
    groupIsSold?:boolean;
    groupPriceInOrderDate?:number;
    groupProductVariantId?:number;
    groupDescription?:string;
}