<div class="col-lg-12 col-12 idkSpacing">
    <div class="statbox widget box box-shadow">
        <div class="widget-header">
            <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
                        <h4 *ngIf="isAddMode">ثبت واریز جدید</h4>
                        <h4 *ngIf="!isAddMode">ویرایش واریز</h4>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
                        <ck-btn-cancel class="back-button"  routerLink="/admin/variz"></ck-btn-cancel>
                    </div>
            </div>
        </div>
        <div class="widget-content widget-content-area">
            <form [formGroup]="frmMain" (ngSubmit)="onSubmit($event)" novalidate>

                <div class="form-group" id="customerContainer" *ngIf="isAddMode && isAdmin">
                     <!--Using ng-option and for loop-->
                     <ng-select [(ngModel)]="selectedUserId" [ngModelOptions]="{standalone: true}"
                     placeholder="مشتری"  *ngIf="isAdmin"
                     class="basic placeholder js-states select2"
                     notFoundText="یافت نشد">
                         <ng-option *ngFor="let row of customers; index as i;" [value]="row.id">{{ row.fullName }} {{row.mobile}} {{row.email}}</ng-option>
                     </ng-select>
                </div>

                <div class="form-group">
                    <label for="payDate">تاریخ پرداخت</label>
                    <dp-date-picker 
                        id="payDate"
                        [ngModelOptions]="{standalone: true}"
                        dir="rtl"
                        [(ngModel)]="payDate"
                        mode="daytime"
                        placeholder="تاریخ"
                        theme="dp-material">
                    </dp-date-picker>
                    <small>{{ payDate | jalali }}</small>
                </div>

                <div class="form-group">
                    <label for="price">قیمت</label>
                    <input type="number" class="form-control" 
                    formControlName="price" name="price" id="price" placeholder="قیمت">
                </div>
                <div class="form-group">
                    <textarea class="form-control form-control-rounded" 
                    formControlName="description" class="form-control" name="description" id="description"
                    rows="8" placeholder="توضیحات"></textarea>
                </div>

                <div class="d-flex">
                    <div class="relative mr-2" *ngFor="let attachment of variz.attachments; index as i;" >
                        <span *ngIf="isAdmin" (click)="deleteAttachment(attachment.id)" class="deleteAttachment">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                        </span>
                        <a 
                        [attr.href]="attachment.address"
                        download="download" class="btn btn-dark mb-2 mr-2 rounded-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                        </a>
                    </div>
                </div>

                <div class="form-group file-upload" [ngClass]="{'edit-mode':!isAddMode}">
                    <input type="file" class="form-control form-control-rounded" alife-file-to-base64 multiple [(fileModel)]="files" />
                </div>

                <div class="text-right">
                    <ck-btn-save>ذخیره</ck-btn-save>
                </div>

            </form>

        </div>
    </div>
</div>