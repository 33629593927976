export const LICENSE_STATUS_OPTIONS = [
    {
        value : 'PreInProgress',
    }, {
        value : 'InProgress',
    }, {
        value : 'Approved',
    },{
        value : 'Reject',
    },
]