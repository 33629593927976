import { Component } from '@angular/core';
import { EventEmitter, Input, Output, ViewContainerRef, Directive } from '@angular/core';

@Component({
    selector: 'ck-form-row',
    template: `
    <div class="form-row mb-4">
           <ng-content></ng-content>
    </div>
    `,
  })
export class AppCorkFormRowComponent {
    @Input() col = 'col-lg-12';
 }
