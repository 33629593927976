import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-register-layout',
    template: `<router-outlet></router-outlet>`
})
export class RegisterLayoutComponent {
    constructor() { }
}
