import { Roles } from '~models/Roles';
import { UserStatus } from '../UserStatus';
import { UpdateRequest } from '../UpdateRequest';
import { UserLevel } from '../UserLevel';
export class UpdateUserRequest extends UpdateRequest {
    email: string;
    isEmailConfirmed: boolean;
    mobile: string;
    isMobileConfirmed: boolean;
    phone: string;
    isPhoneConfirmed: boolean;
    name: string;
    surname: string;
    fatherName: string;
    nationalCode: string;
    isLock: boolean;
    isNationalCardVerified?:boolean;
    role: Roles;
    userStatus: UserStatus;
    userLevel: UserLevel;
}


