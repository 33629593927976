import { OrderStatus } from  '~models/orders/OrderStatus';

import {
    Pipe,
    PipeTransform
  } from '@angular/core';

  @Pipe({
    name: 'orderStatusFa'
  })
  export class OrderStatusFaPipe implements PipeTransform {

    transform(status: string | null): string {
        if(!status){
            return '';
          }
          let name = '';
      
          switch (status.toLowerCase()) {
            case OrderStatus.Pending.toLowerCase():
              name = 'در انتظار بررسی';
              break;
            case OrderStatus.Reject.toLowerCase():
              name = 'رد شده';
              break;
            case OrderStatus.Approved.toLowerCase():
              name = 'تایید شده';
              break;
          }
          return name;
    }
  }
