import { Component, AfterViewInit, ChangeDetectorRef, OnInit, AfterViewChecked } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { LicenseService } from '~services/license.service';
import { AuthService } from '~services/auth.service';

import * as licenseModel from '~app/models/license';
import { ToastrService } from 'ngx-toastr';
import { LicenseViewModel } from '~app/models/license';
import { Pager } from '~modules/ng-pager/pager.model';
import { UserViewModel } from '~app/models/user/UserViewModel';
import { UserService } from '~app/services/user.service';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { SearchUserRequest } from '~app/models/user/SearchUserRequest';

@Component({
    selector: 'app-client.row',
    templateUrl: './license.component.html',
    styleUrls: ['./license.component.scss'],
    providers: [LicenseService]
  })
  export class LicenseComponent implements AfterViewInit, OnInit, AfterViewChecked {
    constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private licenseService: LicenseService,
      private authService: AuthService,
      private router: Router,
      private toastr: ToastrService,
      private routes: ActivatedRoute,
      private userService: UserService,
    public ngxSmartModalService: NgxSmartModalService
    ) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     }
     showStatus=false;
      public userId ?: number;
      pager: Pager;
      public key ?: string;
    disablePager = false;
    public pageSize = 20;
    public currentPage = 0;
    public page = 0 ;
    public totalItems = 0;

    public isAdmin = false;
    public isLoading = false;
    public search = '';
    public licenses: LicenseViewModel[] = [];
    public groupedLicenses : licenseModel.LicenseGroupViewModel[] = [];
    public customers: UserViewModel[] = [];

    isCustomerLoading = false;
    isSold: boolean | null = null;
    forSell: boolean | null = null;
    fromDate: string | null;
    toDate: string | null;
    selectedUserId?:number;
    licenseCodes: string;
    ngOnInit() {
      this.isAdmin = this.authService.isAdmin();

      this.routes.queryParams
      .subscribe((params) => {
        console.log(params);
        if (params) {

          if (params.isSold) {
            const isSold = params.isSold;
            this.isSold = isSold === 'false' ? false : true;
          }

          if (params.forSell) {
            this.forSell = params.forSell === 'false' ? false : true;
          }
        }
      }
    );


    }

    getCustomers(): void {
      this.isCustomerLoading = true;
      let request = new SearchUserRequest();
      request.pageSize = 99999;
      this.userService.getList(request).subscribe((x) => {
        if (x.isSuccess) {
          this.customers = x.list;
          this.isCustomerLoading = false;
          this.totalItems = x.totalItems;
          this.page = x.currentPage;
        }
      });
    }

    title() {
      let str = 'لیست لایسنس ها';

      if (this.isSold === true) {
        str = 'لایسنس خریداری شده از ما';
      } else if (this.isSold === false) {
        str = 'فروخته شده به ما';
      } else if (this.forSell === true) {
        str = 'لایسنس های آماده فروش';
      }

      return str;
    }

    ngAfterViewInit() {
      if (this.isAdmin) {
        this.getCustomers();
      }
      this.getData();
    }

    ngAfterViewChecked() {
      this.changeDetectorRef.detectChanges();
    }

    getData(request ?: licenseModel.LicenseSearchRequest): void {
      this.groupedLicenses = [];

      this.userId = Number(this.selectedUserId);

      this.isLoading = true;
      if (request == undefined) {
        request = new licenseModel.LicenseSearchRequest();
        request.pageSize = this.pageSize;
      }
      if (this.userId != undefined) {
        request.userId = Number(this.userId);
      }
      if (this.isSold != null) {
        request.isSold = this.isSold;
      }
      if (this.forSell != null) {
        request.forSell = this.forSell;
      }
      if (this.fromDate != undefined) {
        request.fromDate  = new Date(this.fromDate);
      }
      if (this.toDate != undefined) {
        request.toDate  = new Date(this.toDate);
      }
      if(this.key != undefined){
        request.key = this.key;
      }

      this.licenseService.getList(request).subscribe((x) => {
        if (x.isSuccess) {
          this.licenses = x.list;

          let uniquebulkOrderIds = [];
          for (let i = 0; i < this.licenses.length; i++) {
          const element = this.licenses[i];
            
            if(uniquebulkOrderIds.findIndex(x=>x == element.bulkOrderId)==-1){
              uniquebulkOrderIds.push(element.bulkOrderId);
            }
          }


          for (let index = 0; index < uniquebulkOrderIds.length; index++) {
            let current = uniquebulkOrderIds[index];
            
            let groupLicense = x.list.filter(x=>x.bulkOrderId == current);
            let groupPrice =0;
            for (let i = 0; i < groupLicense.length; i++) {
              const element = groupLicense[i];
              groupPrice += element.priceInOrderDate; 
            }
            const group = new licenseModel.LicenseGroupViewModel();
            group.groupcreatedAt = groupLicense[0].createdAt;
            group.groupPrice = groupPrice;
            group.groupCount = groupLicense.length;
            group.groupProductName =  groupLicense[0].productVariant.product.name+ ' ' + groupLicense[0].productVariant.variantName;
            group.groupUser = groupLicense[0].user.fullName;
            group.licenses = groupLicense;
            group.groupId = groupLicense[0].bulkOrderId;
            this.groupedLicenses.push(group);
            
          }
          

          this.totalItems = x.totalItems;
          this.currentPage = request == undefined ? 0 : request.pageIndex;
          this.pager = new Pager( x.totalItems, this.currentPage, this.pageSize);
          this.isLoading = false;
        }
      }, () => {
        this.isLoading = false;
      });
    }
    selectedLicenseGroup:licenseModel.LicenseViewModel[]=[];
    openLicenseGroup(groupId:string){
      this.selectedLicenseGroup = this.licenses.filter(x=>x.bulkOrderId === groupId);
      this.ngxSmartModalService.getModal('adminLicenseModal').open();
    }
    copyMessage(val: string) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
  }
