import { Component } from '@angular/core';
import { AppCorkButtonBaseComponent } from './base.component';

@Component({
  selector: 'ck-btn-success',
  templateUrl: './base.component.html'
})
export class AppCorkButtonSuccessComponent extends AppCorkButtonBaseComponent {
  className = 'btn-success';
}
