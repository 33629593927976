<div class="col-lg-12 col-12 idkSpacing">
    <div class="statbox widget box box-shadow">
      <div class="widget-header">
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
              <h4>لیست واریزها</h4>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
              <ck-btn-cancel class="back-button" routerLink="/admin"></ck-btn-cancel>
          </div>
        </div>
      </div>
      <div class="widget-content widget-content-area">
        <div>
          <form class="form-inline">
            <label class="sr-only" for="inlineFormInputName2">کلید</label>
            <input style="min-width: 300px;" type="text" [(ngModel)]="key" [ngModelOptions]="{standalone: true}" class="form-control mb-2 mr-sm-2" id="inlineFormInputName2"
              placeholder="کلید">
            <button (click)="getData()" class="btn btn-primary mb-2">جست و جو</button>
            <div class="ml-auto">
               <button  routerLink="/admin/variz/add" class="btn btn-primary mb-2" *ngIf="isAdmin">ایجاد واریز جدید</button>
            </div>
          </form>
          <div class="row" *ngIf="isAdmin">
            <div class="col-12">
              <div class="form-row" *ngIf="isAdmin" id="userContainer">
                <div class="form-group col-md-6">
                    <label for="userId">مشتری</label>

                              <!--Using ng-option and for loop-->
                <ng-select [(ngModel)]="userId" class="basic placeholder js-states select2">
                  <ng-option *ngFor="let row of customers; index as i;" [value]="row.id">{{ row.fullName }} {{row.mobile}} {{row.email}}</ng-option>
                </ng-select>

                </div>
            </div>
            </div>
          </div>
          <div>
            <label for="minPayDate">از تاریخ</label>
            <dp-date-picker 
            id="minPayDate"
            [ngModelOptions]="{standalone: true}"
            dir="rtl"
            [(ngModel)]="minPayDate"
            mode="daytime"
            placeholder="از تاریخ"
            theme="dp-material">
        </dp-date-picker>
        <small>{{ minPayDate | jalali }}</small>
          </div>
          <div>
        <label for="maxPayDate">تا تاریخ</label>
            <dp-date-picker 
            id="maxPayDate"
            [ngModelOptions]="{standalone: true}"
            dir="rtl"
            [(ngModel)]="maxPayDate"
            mode="daytime"
            placeholder="تا تاریخ"
            theme="dp-material">
        </dp-date-picker>
        <small>{{ maxPayDate | jalali }}</small>
          </div>
     

        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-hover table-striped table-checkable table-highlight-head mb-4">
            <thead>
              <tr>
                <th class="">کاربر</th>
                <th class="">مبلغ ریال</th>
                <th class="">تاریخ واریز</th>
                <th>تاریخ ایجاد</th>
                <th class="">توضیحات</th>
                <th class="">فیش ها</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of varizes; index as i;">
                <td>
                  <p class="mb-0">{{ row.user.fullName }}</p>
                </td>
                <td>{{row.price | toman }}</td>
                <td>{{row.payDate | jalali}}</td>
                <td>{{row.createdAt | jalali}}</td>
                <td>{{row.description }}</td>
                <td>
                    <span class="attachments" *ngIf="row.attachments">
                        <span *ngFor="let attachment of row.attachments; index as i;" >
                            <a 
                            [attr.href]="attachment.address"
                            download="download" class="btn btn-dark mb-2 mr-2 rounded-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                            </a>
                        </span>
                    </span>
                </td>
  
                <td class="text-center">
                  <ul class="table-controls">
                      <li>
                          <button class="btn btn-dark mb-2 mr-2 rounded-circle" 
                          routerLink="/admin/variz/edit/{{row.id}}" data-toggle="tooltip" data-placement="top" title="Edit">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                  class="feather feather-edit-2 text-success">
                                  <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                              </svg>
                          </button>
                     </li>
  
                    <li>
                      <button class="btn btn-dark mb-2 mr-2 rounded-circle" (click)="delete(row.id)" data-toggle="tooltip" data-placement="top" title="حذف">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-trash-2 text-danger">
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </button>
                    </li>
  
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <ng-pager [pager]="pager" (pageChange)="getData($event)"></ng-pager>

      </div>
    </div>
  </div>
  