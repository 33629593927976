<div class="col-lg-12 col-12 idkSpacing">
  <div class="statbox widget box box-shadow">
    <div class="widget-header">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
          <h4>{{ title() }}</h4>
        </div>

        <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
          <ck-btn-cancel
            class="back-button"
            routerLink="/admin"
          ></ck-btn-cancel>
        </div>
      </div>
    </div>
    <div class="widget-content widget-content-area">
      <div>
        <div class="form-inline">
          <label class="sr-only" for="inlineFormInputName2">کلید</label>
          <input
            style="min-width: 300px"
            [(ngModel)]="key"
            type="text"
            class="form-control mb-2 mr-sm-2"
            id="inlineFormInputName2"
            placeholder="کلید"
          />
          <button (click)="getData()" class="btn btn-primary mb-2">
            جست و جو
          </button>
          <div class="ml-auto">
            <button
              routerLink="/admin/licenses/bulk"
              class="btn btn-primary mb-2"
            >
              ایجاد لایسنس جدید
            </button>
            <!-- <button (click)="showLicenses()" class="btn btn-primary mb-2">نمایش فقط لایسنس ها</button> -->
          </div>
        </div>
        <div class="row" *ngIf="isAdmin">
          <div class="col-12">
            <div class="form-row" *ngIf="isAdmin" id="userContainer">
              <div class="form-group col-md-6">
                <label for="userId">مشتری</label>
                <!--Using ng-option and for loop-->
                <ng-select
                  [(ngModel)]="userId"
                  class="basic placeholder js-states select2"
                >
                  <ng-option
                    *ngFor="let row of customers; index as i"
                    [value]="row.id"
                    >{{ row.fullName }} {{ row.mobile }}
                    {{ row.email }}</ng-option
                  >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isAdmin">
          <div>
            <label for="fromDate">از تاریخ</label>
            <dp-date-picker
              id="fromDate"
              [ngModelOptions]="{ standalone: true }"
              dir="rtl"
              [(ngModel)]="fromDate"
              mode="daytime"
              placeholder="از تاریخ"
              theme="dp-material"
            >
            </dp-date-picker>
            <small>{{ fromDate | jalali }}</small>
          </div>
          <div>
            <label for="toDate">تا تاریخ</label>
            <dp-date-picker
              id="toDate"
              [ngModelOptions]="{ standalone: true }"
              dir="rtl"
              [(ngModel)]="toDate"
              mode="daytime"
              placeholder="تا تاریخ"
              theme="dp-material"
            >
            </dp-date-picker>
            <small>{{ toDate | jalali }}</small>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table
          class="table table-bordered table-hover table-striped table-checkable table-highlight-head mb-4"
        >
          <thead>
            <tr>
              <th>کاربر</th>
              <th>محصول و ریجن</th>
              <th>
                تاریخ <br />
                بارگذاری
              </th>
              <th>تعداد</th>
              <th>قیمت</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of groupedLicenses; index as i">
              <td>{{ row.groupUser }}</td>
              <td>{{ row.groupProductName }}</td>
              <td *ngIf="(row.groupcreatedAt == '0001-01-01T00:00:00')">
                --
              </td>
              <td *ngIf="(row.groupcreatedAt != '0001-01-01T00:00:00')">
                {{ row.groupcreatedAt | jalali }}
              </td>
              <td>{{ row.groupCount }}</td>
              <td>{{ row.groupPrice }}</td>
              <td class="text-center">
                <ul class="table-controls">
                  <li>
                    <button
                      class="btn btn-dark mb-2 mr-2 rounded-circle"
                      (click)="openLicenseGroup(row.groupId)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="نمایش کد"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye"
                      >
                        <path
                          d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                        ></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button
                      class="btn btn-dark mb-2 mr-2 rounded-circle"
                      routerLink="/admin/licenses/bulk/{{ row.groupId }}"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="ویرایش"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-edit-2 text-success"
                      >
                        <path
                          d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                        ></path>
                      </svg>
                    </button>
                  </li>

                  <li>
                    <button
                      class="btn btn-dark mb-2 mr-2 rounded-circle"
                      (click)="delete(row.id)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="حذف"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-trash-2 text-danger"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path
                          d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                        ></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </button>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ng-pager [pager]="pager" (pageChange)="getData($event)"></ng-pager>
    </div>
  </div>
</div>

<!-- Data modal -->
<ngx-smart-modal
  #adminLicenseModal
  identifier="adminLicenseModal"
  ariaLabel="Admin License modal"
>
  <br />
  <h6>لایسنس ها</h6>
  <br />
  <div>
    <input type="checkbox" [(ngModel)]="showStatus" />
    نمایش وضعیت
  </div>
  <br />
  <div
    *ngFor="let rowX of selectedLicenseGroup"
    style="display: flex; font-family: arial"
  >
    {{ rowX.code }}
    <span *ngIf="showStatus">{{ rowX.licenseStatus | licenseStatusFa }}</span>
  </div>
  <br />
  <button class="button -dark" (click)="adminLicenseModal.close()">باشه</button>
</ngx-smart-modal>
