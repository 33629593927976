import { FileModel } from '../FileModel';
import { UpdateRequest } from '../UpdateRequest';

export class UpdateVarizRequest extends UpdateRequest {
    description: string;
    userId: number;
    price: number;
    payDate: string | null;
    files: FileModel[];
}
