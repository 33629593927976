import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule , ToastContainerModule  } from 'ngx-toastr';


import { SharedModule } from '~utils/shared.module';

import { AppRoutingModule } from '~app/app.routes';

import { AdminGuard } from '~app/guards/admin.guard';
import { AuthGuard } from '~app/guards/auth.guard';
import { CustomerGuard } from '~app/guards/customer.guard';


import { AppComponent } from '~components/app/app.component';
import { ContactUsComponent } from '~components/contact-us/contact-us.component';
import { NotFoundComponent } from '~components/not-found/not-found.component';

import { AuthService } from '~services/auth.service';
import { UserService } from '~services/user.service';
import { UserAttachmentService } from '~app/services/userAttachment.service';

import { BankAccountService } from '~services/bankaccount.service';
import { VariantService } from '~services/variant.service';
import { ProductService } from '~services/product.service';
import { TicketService } from '~app/services/ticket.service';
import { TicketAttachmentService } from '~app/services/ticketAttachment.service';
import { LicenseService } from '~app/services/license.service';
import { GlobalService } from '~app/services/global.service';
import { VarizService } from '~app/services/variz.service';
import { VarizAttachmentService } from '~app/services/varizAttachment.service';

import { UtilitiesService } from '~app/services/utilities.service';
import { ValidatorService } from '~app/services/validator.service';
import { OrderService } from '~app/services/order.service';


import { UserModule } from '~modules/user/user.module';
import { AdminLayoutModule } from '~modules/admin-layout/admin-layout.module';
import { CustomerLayoutModule } from '~modules/customer-layout/customer-layout.module';
import { LoginLayoutModule } from '~modules/login-layout/login-layout.module';
import { RegisterLayoutModule } from '~modules/register-layout/register-layout.module';

import { PipesModule  } from './pipes/pipes.module';

import { ErrorInterceptor } from './interceptors/ErrorInterceptor';
import { JwtInterceptor } from './interceptors/JwtInterceptor';

import { NgPopupsModule } from 'ng-popups';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [
    AppComponent,
    ContactUsComponent,
    NotFoundComponent,
  ],
  imports: [
    FlexLayoutModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    ToastContainerModule,
    PipesModule,

    NgPopupsModule.forRoot(),
    NgxSmartModalModule.forRoot(),//https://maximelafarie.com/ngx-smart-modal/#/start

    AppRoutingModule,
    HttpClientModule,
    AdminLayoutModule,
    
    CustomerLayoutModule,
    LoginLayoutModule,
    RegisterLayoutModule,
    UserModule,
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },

    AuthGuard,
    CustomerGuard,
    AdminGuard,


    BankAccountService,
    VariantService,
    ProductService,
    LicenseService,
    VarizService,
    VarizAttachmentService,

    ValidatorService,
    UtilitiesService,

    AuthService,

    UserService,
    UserAttachmentService,

    TicketService,
    TicketAttachmentService,

    GlobalService,
    OrderService
  ],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
