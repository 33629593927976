import { NgModule } from '@angular/core';
import { SharedModule } from '~utils/shared.module';
import { RouterModule } from '@angular/router';
import { UserComponent } from './user.component';
import { FormComponent } from './form/form.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {path: '', component: UserComponent},
      {path: 'edit/:id', component: FormComponent},
  ]),
    SharedModule
  ],
  declarations: [
      UserComponent,
     FormComponent
  ],
  providers: [],
  entryComponents: [
     FormComponent
  ],
  exports: [
    RouterModule,
  ]
})
export class UserModule { }
