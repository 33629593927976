import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable } from 'rxjs';

import {
  UpdateResponse,
  CreateResponse,
  GetItemRequest,
  GetListResponse,
  GetItemResponse,
  DeleteResponse,
  DeleteRequest,
} from '~models/index';

import {
  CreateReplyRequest,
  CreateTicketRequest,
  GetListTicketRequest,
  UpdateTicketRequest,
  TicketViewModel,
} from '~models/ticket/index';

import { UtilitiesService } from './utilities.service';

@Injectable()
export class TicketService {
  loading = true;

  constructor(
    private http: HttpClient,

    private utilitiesService: UtilitiesService
  ) {}

  getList(
    request: GetListTicketRequest
  ): Observable<GetListResponse<TicketViewModel>> {
    let params = this.utilitiesService.listToParams(request);

    if (request != undefined) {
      if (request.customerId) {
        params = params.append('customerId', request.customerId?.toString());
      }

      if (request.subject) {
        params = params.append('subject', request.subject?.toString());
      }

      if (request.key) {
        params = params.append('key', request.key);
      }
    }

    return this.http.get<GetListResponse<TicketViewModel>>(
      CONSTANST.routes.ticket.list,
      {
        params: params,
      }
    );
  }

  getOne(
    request: GetItemRequest
  ): Observable<GetItemResponse<TicketViewModel>> {
    return this.http.get<GetItemResponse<TicketViewModel>>(
      CONSTANST.routes.ticket.get.replace(':id', request.id.toString())
    );
  }

  create(request: CreateTicketRequest): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      CONSTANST.routes.ticket.create,
      request
    );
  }

  reply(request: CreateReplyRequest): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      CONSTANST.routes.ticket.reply.replace(
        ':ticketId',
        request.ticketId.toString()
      ),
      request
    );
  }

  update(request: UpdateTicketRequest): Observable<UpdateResponse> {
    return this.http.patch<UpdateResponse>(
      CONSTANST.routes.ticket.update.replace(':id', request.id.toString()),
      request
    );
  }

  delete(request?: DeleteRequest): Observable<DeleteResponse> {
    const params = this.utilitiesService.deleteToParams(request);
    return this.http.delete<DeleteResponse>(CONSTANST.routes.user.delete, {
      params: params,
    });
  }
}
