import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';

import { AuthService } from '~app/services/auth.service';
import { UserService } from '~app/services/user.service';
import { Router } from '@angular/router';

import * as baseModel from '~models/index';
import * as userModel from '~models/user/index';
import { UserViewModel } from '~models/user/index';

import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~app/utils/messages';
import { DeleteRequest } from '~models/index';
import { Pager } from '~modules/ng-pager/pager.model';

@Component({
  selector: 'app-user.row',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements AfterViewInit, OnInit, AfterViewChecked {
  pager: Pager;
  disablePager = false;
  public pageSize = 10;
  public currentPage = 0;
  public page = 0;
  public totalItems = 0;


  public users: UserViewModel[] = [];
  public displayedColumns: string[];
  public pageSizeOptions: number[];
  public isLoading: boolean;
  key: string;

  constructor(
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService

  ) { }

  getData(request?: userModel.SearchUserRequest): void {
    this.isLoading = true;

    if(request == undefined){
      request = new userModel.SearchUserRequest();
      request.pageSize = this.pageSize;
    }
    if(this.key){
      request.key = this.key;
    }

    this.userService.getList(request).subscribe(x => {
      this.users = x.list;

      this.totalItems = x.totalItems;
      this.currentPage = request == undefined ? 0 : request.pageIndex;
      this.pager = new Pager(x.totalItems, this.currentPage, this.pageSize);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }

  delete(id): void {
    const request = new DeleteRequest();
    request.id = [];
    request.id.push(id);

    this.userService.delete(request).subscribe((data) => {
      this.toastr.success(MESSAGES.user.delete.success);

      const index = this.users.findIndex(x => x.id === id);
      this.users.splice(index, 1);
    },
      (err) => {
        this.toastr.error(MESSAGES.user.delete.error);
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getData();
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  changeLock(id: number) {
    const request = new baseModel.GetItemRequest();
    request.id = Number(id);

    this.userService.changeLock(request).subscribe((data) => {
      if (data.isSuccess) {

        const item = this.users.find(x => x.id === id);
        item.isLock = !item.isLock;

        this.toastr.success(MESSAGES.user.changelock.success);
      }
    },
      (err) => {
        this.toastr.error(MESSAGES.user.changelock.error);
      });
  }

  changeRole(request: userModel.SetByUserIdRequest) {
    this.userService.changeRole(request).subscribe((data) => {
      if (data.isSuccess) {
        this.toastr.success(MESSAGES.user.changerole.success);
        const item = this.users.find(x => x.id === request.id);
        item.role = item.role;
      }
    }, (err) => {
      this.toastr.error(MESSAGES.user.changerole.error);

    });
  }



}
