export const ValidationMessages = {
    mobile : {
        error : 'شماره موبایل وارد شده نا معتبر است',
        helper : 'شماره موبایل باید با صفر شروع شود : 09369874563'
    },
    email : {
        
    },
    required : {
        error : '',
        helper : '',
    }
}