import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CONSTANST } from '~utils/constanst';
import * as authModels from '~app/models/Auth';
import { Roles } from '~app/models/Roles';



@Injectable()
export class AuthService {
  public loggedIn = new BehaviorSubject<boolean>(this.hasToken());

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    public http: HttpClient,
  ) { }

  //#region  fakes
  fakeAdmin(): Observable<authModels.AuthenticateResponse> {
    return this.http.get<authModels.AuthenticateResponse>(
      CONSTANST.routes.authorization.fakeAdmin, {
    });
  }
  fakeSeller(): Observable<authModels.AuthenticateResponse> {
    return this.http.get<authModels.AuthenticateResponse>(
      CONSTANST.routes.authorization.fakeSeller, {
    });
  }
  fakeBuyer(): Observable<authModels.AuthenticateResponse> {
    return this.http.get<authModels.AuthenticateResponse>(
      CONSTANST.routes.authorization.fakeBuyer, {
    });
  }
  //#endregion

  login(request: authModels.LoginOTPRequest): Observable<authModels.AuthenticateResponse> {
    return this.http.post<authModels.AuthenticateResponse>(
      CONSTANST.routes.authorization.login, request);
  }
  verifyOtp(request: authModels.OTPVerifyRequest): Observable<authModels.OTPResponse> {
    return this.http.post<authModels.AuthenticateResponse>(
      CONSTANST.routes.authorization.verifyOtp, request);
  }
  register(request: authModels.RegisterRequest): Observable<authModels.AuthenticateResponse> {
    return this.http.post<authModels.AuthenticateResponse>(
      CONSTANST.routes.authorization.register,  request);
  }
  otp(request: authModels.OTPRequest):Observable<HttpResponse<authModels.OTPResponse>> {
    return this.http.post<authModels.AuthenticateResponse>(
      CONSTANST.routes.authorization.otp, request,{observe: 'response'});
  }


  hasToken(): boolean {
    return !!this.getToken();
  }

  setLogin(data: authModels.AuthenticateResponse): void {
    this.setToken(data.token);
    this.setRole(data.role);
    this.setId(data.id);
    this.setExpire(data.expiredIn);
  }

  isExpired(): boolean {
    const now = Date.now();
    const exp = this.getExpire();
    if (now > exp) {
      return true;
    } else {
      return false;
    }
  }
  getExpire(): number | null {
    const exp = localStorage.getItem('ExpireIn');
    if (exp != null || exp !== undefined) {
      return Number(exp);
    } else {
      return null;
    }
  }
  setExpire(exp: number): void {
    const t = new Date();
    const localExp = t.setSeconds(t.getSeconds() + exp);
    localStorage.setItem('ExpireIn', localExp.toString());
  }

  setFullName(str: string): void {
    localStorage.setItem('FullName', str);
  }
  getFullName(): string {
    return localStorage.getItem('FullName');

  }
  setToken(token: string): void {
    localStorage.setItem('Token', token);
  }
  setRole(role: string): void {
    localStorage.setItem('Role', role);
  }
  getToken(): string {
    return localStorage.getItem('Token');
  }

  getRole(): string {
    return localStorage.getItem('Role');
  }

  setId(id: number): void {
    localStorage.setItem('Id', id.toString());
  }
  getId(): number | null {
    const id = localStorage.getItem('Id');
    if (id === undefined || id == null) {
      return null;
    } else {
      return Number(id);
    }
  }



  destroy(): void {
    localStorage.removeItem('Token');
    localStorage.removeItem('Role');
    localStorage.removeItem('Id');
    localStorage.removeItem('ExpireIn');
  }

  isAdmin(): boolean {
    if (this.getRole() == Roles.Admin) {
      return true;
    }
    return false;
  }
  isCustomer(): boolean {
    if (this.getRole() != Roles.Admin) {
      return true;
    }
    return false;
  }

  isBuyer(): boolean {
    return (this.getRole() === Roles.Buyer);
  }
  isSeller(): boolean {
    return (this.getRole() === Roles.Seller);
  }

}
