  import {
    Pipe,
    PipeTransform
  } from '@angular/core';
const persian={0:'۰',1:'۱',2:'۲',3:'۳',4:'۴',5:'۵',6:'۶',7:'۷',8:'۸',9:'۹'};
  
  /*
   * Raise the value exponentially
   * Takes an exponent argument that defaults to 1.
   * Usage:
   *   value | exponentialStrength:exponent
   * Example:
   *   {{ 2 | exponentialStrength:10 }}
   *   formats to: 1024
   */
  @Pipe({
    name: 'faNumber'
  })
  export class FaNumberPipe implements PipeTransform {
  
    transform(str: string | null): string {
      if(str == undefined){
        return str;
      }
        let list=str.match(/[0-9]/g);
        if(list!=null && list.length!=0){
            for(var i=0;i<list.length;i++)
            str=str.replace(list[i],persian[list[i]]);
        }
        return str;
  }
}
  