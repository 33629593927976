<div class="signin-content animated fadeIn">
  
      <img src="assets/img/1.png" width="200px" onmousedown="return false;">
      <form [formGroup]="form" (ngSubmit)="login()">
        <!-- <mat-form-field class="full-width-input">
          <input matInput type="text" placeholder="User" formControlName="user_name" required>
          <mat-error *ngIf="isFieldInvalid('user_name')">
            Please enter a valid username or email
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width-input separate">
          <input matInput type="password" placeholder="Password" formControlName="password" required>
          <mat-error *ngIf="isFieldInvalid('password')">
            Please enter a valid password
          </mat-error>
        </mat-form-field> -->

        <button mat-raised-button color="primary" type="submit" style="width: 100%;" [disabled]="form.invalid || isLogin">
          <div *ngIf="isLogin; else loginText">Loading ...</div>
        </button>
      </form>

      <form (ngSubmit)="fakeAdminLogin()">
        <button mat-raised-button color="primary" type="submit" style="width: 100%;" [disabled]="isLogin">
          <div *ngIf="isLogin; else fakeAdminLoginText">Loading ...</div>
        </button>
      </form>
      
      <form (ngSubmit)="fakeBuyerLogin()">
        <button mat-raised-button color="primary" type="submit" style="width: 100%;" [disabled]="isLogin">
          <div *ngIf="isLogin; else fakeBuyerLoginText">Loading ...</div>
        </button>
      </form>
      
      <form (ngSubmit)="fakeSellerLogin()">
        <button mat-raised-button color="primary" type="submit" style="width: 100%;" [disabled]="isLogin">
          <div *ngIf="isLogin; else fakeSellerLoginText">Loading ...</div>
        </button>
      </form>

      <ng-template #loginText>
        Login
      </ng-template>

      <ng-template #fakeAdminLoginText>
        Fake Admin Login
      </ng-template>
      <ng-template #fakeSellerLoginText>
        Fake Seller Login
      </ng-template>
      <ng-template #fakeBuyerLoginText>
        Fake Buyer Login
      </ng-template>
   
</div>
