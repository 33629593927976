import { NgModule } from '@angular/core';
import { 
     ButtonModule , CardModule , TableModule,
    FormModule , DialogModule
  } from './index';


@NgModule({
    imports: [
      ButtonModule,
      CardModule,
      TableModule,
      FormModule,
      DialogModule
    ],
    exports: [
      ButtonModule,
      CardModule,
      TableModule,
      FormModule ,
      DialogModule
    ]
  })
  export class CorkModule { }
//https://stackoverflow.com/questions/43937387/if-selector-is-an-angular-component-then-verify-that-it-is-part-of-this-mod