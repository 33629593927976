import {
    Pipe,
    PipeTransform
  } from '@angular/core';
import { BankName } from '~app/models/bankAccount/BankName';
  
  @Pipe({
    name: 'bankNameFa'
  })
  export class BankNameFaPipe implements PipeTransform {
  
    transform(name: string | null): string {
      if(!name || name == undefined || name == null || name == ''){
        return '';
      }
      console.log(name);
      switch (name.toLowerCase()) {
        case BankName.Melat.toLowerCase():
          return 'ملت'
          case BankName.Meli.toLowerCase():
            return 'ملی'
        default:
            return 'تعریف نشده'
      }
    }
  }
  