import { Component } from '@angular/core';

@Component({
    selector: 'ck-card-body-title',
    template: `
    <h5 class="card-title"><ng-content></ng-content></h5>
    `,
  })
export class AppCorkCardBodyTitleComponent {
    
  }
