import { FileModel } from "../FileModel";

export class LicenseCreateRequest {
    file: FileModel[];
    code: string;
    userId: number;
    productVariantId: number;
    battleTag: string;
    description: string;
}
