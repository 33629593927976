<div class="col-lg-12 col-12 idkSpacing">
    <div class="statbox widget box box-shadow">
      <div class="widget-header">
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
            <h4>لیست سفارشات</h4>
            <br>
            <small style="color: red;">شماره سفارش وردپرس</small>
            <br>
            <small style="color: green;">شماره سفارش این سیستم</small>
          </div>
  
          <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
            <ck-btn-cancel routerLink="/admin"></ck-btn-cancel>
        </div>
  
        </div>
      </div>
      <div class="widget-content widget-content-area">
        <div>
          <div class="form-inline">
            <label class="sr-only" for="inlineFormInputName2">کلید</label>
            <input style="min-width: 300px;" type="text" [(ngModel)]="key" class="form-control mb-2 mr-sm-2" id="inlineFormInputName2"
              placeholder="کلید">
              <br>
            <label class="sr-only" for="inlineFormInputName2">کد لایسنس</label>
            <input style="min-width: 300px;" type="text" [(ngModel)]="code" class="form-control mb-2 mr-sm-2" id="inlineFormInputName2"
              placeholder="code">
            <button type="button" (click)="getData()" class="btn btn-primary mb-2">جست و جو</button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-hover table-striped table-checkable table-highlight-head mb-4">
            <thead>
              <tr>
                <th class="">#</th>
                <th>نام کاربر</th>
                <th>وضعیت</th>
                <th>تاریخ خرید</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of orders; index as i;">
                <td>
                  <p class="mb-0"><span style="color: green;">#{{ row.id }}</span> <br><span style="color: red;">#{{row.wp_orderId}}</span></p>
                </td>
                <td>{{ row.user.fullName }}</td>
                <td>{{ row.status | orderStatusFa }}</td>
                <td>{{ row.createdAt | jalali }}</td>
                <td>
                  <button type="button" (click)="detail(row)" class="btn btn-primary mb-2">نمایش</button>
                </td>
                <td>
                  <a href="/PubOrder/Index?uniqueid={{row.uniquesId}}" target="_blank" class="btn btn-primary mb-2">تایید سفارش</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <ng-pager [pager]="pager" (pageChange)="getData($event)"></ng-pager>

      </div>
    </div>
  </div>
  
          <!-- Data modal -->
          <ngx-smart-modal #adminOrderModal
          identifier="adminOrderModal"
          ariaLabel="Admin Order modal">
          <br />
          <h6>سفارش</h6>
          <br />
          <div *ngFor="let row of selectedOrder.items;" style="display: flex;font-family: arial;border:1px solid #333">
              تعداد : {{ row.wp_quantity }}
              <br>
              نام : {{row.wp_name}}
              <br>
              قیمت کالا در وردپرس : {{row.wp_price}}
              <br>
              کد : {{row.code}}
              <br>
              قیمت کالا در این سیستم : {{row.variant.price}}
              <br>
              نام کالا در این سیستم : {{row.variant.variantFullName}}
              <br>
              فروشنده لایسنس :
              <br>
              {{row.seller?.fullName}}
              <br>
              {{row.seller?.mobile}}
              <br>
              # {{row.seller?.id}}
              <br>
          </div>
          <br>
          <button class="button -dark"
          (click)="adminOrderModal.close()">باشه</button>
          </ngx-smart-modal>
        