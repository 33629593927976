import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '~services/auth.service';
import { DOCUMENT } from '@angular/common';
import * as authModels from '~app/models/Auth';
import { UserViewModel } from '~app/models/user/UserViewModel';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~app/utils/messages';
import { ValidatorService } from '~app/services/validator.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class RegisterComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private validatorService: ValidatorService
  ) {}
  
  public form: FormGroup;
  public isLogin = false;
  public isSignUp = false;

  user: UserViewModel = {};
  code: string;
  registerMode = false;
  isLoginOtpSent = false;
  timeLeft = 180;
  interval;
  ngOnDestroy(): void {
    
  }

  ngOnInit() {

    if (this.authService.isAdmin()) {
      window.location.replace("/#/admin");
    } else if (this.authService.isBuyer() || this.authService.isSeller()) {
      window.location.replace("/#/customer");
    }


    const temp = {} as any;
    this.user = temp;

    this.document.body.classList.add('registerLayout');
    this.document.body.setAttribute('dir', 'ltr');

    this.document.body.classList.remove('sidebar-noneoverflow', 'alt-menu');

    this.initRegisterForm();
  }
  OnDestroy() {
    this.document.body.classList.remove('registerLayout');
    this.document.body.setAttribute('dir', 'rtl');

    this.document.body.classList.add('sidebar-noneoverflow', 'alt-menu');
  }

  private initRegisterForm(): void {
    this.form = this.fb.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
      surname: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(12),
        ],
      ],
      email: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      mobile: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12),
        ],
      ],
    });
  }

  public isFieldInvalid(field: string) {
    if (this.form.get(field).touched) {
      return !this.form.get(field).valid;
    }
  }

  public verifyOtp() {
    const requestVerifyOtp = new authModels.OTPVerifyRequest();
    requestVerifyOtp.code = this.code;
    requestVerifyOtp.mobile = this.user.mobile;

    this.authService.verifyOtp(requestVerifyOtp).subscribe(
      (data) => {
        if (data.isSuccess) {
        }
      },
      (error) => {}
    );
  }

  public register() {
    const request = new authModels.RegisterRequest();
    const user = this.user;
    request.name = user.name;
    request.code = this.code;
    request.email = user.email;
    request.mobile = user.mobile;
    request.surname = user.surname;

    const requestVerifyOtp = new authModels.OTPVerifyRequest();
    requestVerifyOtp.code = this.code;
    requestVerifyOtp.mobile = this.user.mobile;

    this.authService.verifyOtp(requestVerifyOtp).subscribe(
      (data) => {
        if (data.isSuccess) {
          this.authService.register(request).subscribe(
            (data) => {
              if (data.isSuccess) {
                this.toastr.success('ثبت نام با موفقیت انجام شد');
                this.authService.setLogin(data);
                if (this.authService.isAdmin()) {
                  window.location.replace("/#/admin");
                } else if (this.authService.isCustomer()) {
                  window.location.replace("/#/customer");
                }
              }
            },
            (err) => {
              this.toastr.error('کاربری با این شماره تلفن ثبت شده است');
            }
          );
        }
      },
      (error) => {
        this.toastr.error('کد وارد شده نا معتبر است');
      }
    );
  }
  public sendCode(str) {
    const request = new authModels.OTPRequest();
    request.mobile = this.user.mobile;

    if(str=='register'){
      request.for = "register";
    }
    else{
      request.for = "login";
    }




//fake

// this.changeCol(true);
// this.pauseTimer();
// this.startTimer();

// if(str=='login'){
//   this.registerMode = false;
//   this.isLoginOtpSent = true;
// }
// if(str=="register"){
//   this.registerMode = true;
//   this.isLoginOtpSent = false;
// }

//fakeit





    this.authService.otp(request).subscribe(
      (data) => {
        if (data.body.isSuccess) {
          this.toastr.success('کد یکبار مصرف برای شما ارسال شد');
          this.changeCol(true);
          this.pauseTimer();
          this.startTimer();

          if(str=='login'){
            this.registerMode = false;
            this.isLoginOtpSent = true;
          }
          if(str=="register"){
            this.registerMode = true;
            this.isLoginOtpSent = false;
          }
      }
      },
      (err) => {
        if(str=='register'){
          this.toastr.error(
            'خطا در ارسال کد یکبار مصرف لطفا چند دقیقه دیگر مجددا تلاش کنید'
          );
        }
      }
    );
  }
  sendOtp(str: string) {
    if (str === 'register') {
      if(this.checkRegisterValidation()){
        this.sendCode('register');
      }
    } else {
      if(this.checkLoginValidation()){
        this.sendCode('login');
      }
    }
  }

  Login() {
   if(this.checkConfirmOtpValidate() == false){
     return;
   }

    const request = new authModels.LoginOTPRequest();
    request.code = this.code;
    request.mobile = this.user.mobile;

    const requestVerifyOtp = new authModels.OTPVerifyRequest();
    requestVerifyOtp.code = this.code;
    requestVerifyOtp.mobile = this.user.mobile;

    this.authService.verifyOtp(requestVerifyOtp).subscribe(
      (data) => {
        if (data.isSuccess) {
          this.authService.login(request).subscribe(
            (data) => {
              this.toastr.success('شما با موفقیت وارد شدید');
              // this.authService.loggedIn.next(true);
              this.authService.setLogin(data);
              if (this.authService.isAdmin()) {
                  window.location.href = '#/admin'
                  // this.router.navigate(['/admin']);
              } else if (this.authService.isCustomer()) {
                  window.location.href = '#/customer'
                  // this.router.navigate(['/customer']);
              }
            },
            (error) => {
              this.toastr.error('خطا در ورود');
            }
          );
        }
      },
      (error) => {
        this.toastr.error('کد وارد شده نا معتبر است');
      }
    );
  }

  changeCol(val: boolean) {
    this.isSignUp = val;
    this.pauseTimer();
  }

  sendRound=1;
  sendCode1='ارسال کد یکبار مصرف';
  sendCode2='ارسال مجدد';

  sendCodeText=this.sendCode1;
  timerTimeOut(){
    if(this.sendRound<=1){
      this.sendRound++;
      this.sendCodeText = this.sendCode2;
    }
    else{
      this.sendRound=1;
      this.toastr.warning('لطفا از صحت شماره تلفن خود اطمینان حاصل کنید');
    }
    
    this.isLoginOtpSent = false;
    this.registerMode = false;
  }

  startTimer() {
    this.timeLeft = 180;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
      else {
        this.timerTimeOut();
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer() {
    this.timeLeft = 0;
    clearInterval(this.interval);
  }

  checkConfirmOtpValidate(){
    let isValid= true;
    if(!this.checkMobile(this.user.mobile)){
      this.toastr.error('شماره موبایل باید با صفر شروع شود');
      isValid = false;
    }
    if(!this.checkCode(this.code)){
      this.toastr.error('کد تایید باید ۶ عدد انگلیسی باشد');
      isValid = false;
    }
    return isValid;
  }

  checkLoginValidation(){
    let isValid = true;
    if(!this.checkMobile(this.user.mobile)){
      this.toastr.error('شماره موبایل باید با صفر شروع شود');
      isValid = false;
    }
    return isValid;
  }

  checkRegisterValidation(){
    let isValid = true;
    if(!this.checkMobile(this.user.mobile)){
      this.toastr.error('شماره موبایل باید با صفر شروع شود');
      isValid = false;
    }
    if(!this.checkEmail(this.user.email)){
      this.toastr.error('ایمیل صحیح نیست');
      isValid = false;
    }
    if(!this.checkRequired(this.user.name)){
      this.toastr.error('نام خود را وارد کنید');
      isValid = false;
    }
    if(!this.checkRequired(this.user.surname)){
      this.toastr.error( 'نام خانوادگی خود را وارد کنید');
      isValid = false;
    }
    return isValid;
  }

  checkRequired(value:string){
    if(value != undefined){
      if(value.length>0){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }
  checkEmail(email){
    const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    return regex.test(email);
  }
  checkMobile(mobile){
    const regex = /^09\d{9}$/gm;
    return regex.test(mobile);
  }

  checkCode(code){
    const regex = /^\d{6}$/;
    return regex.test(code);
  }
}
