import { EventEmitter, Input, Output, ViewContainerRef, Directive } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'base-button-class',
    templateUrl: './base.component.html',
  })
export abstract class AppCorkButtonBaseComponent {
    @Input() disabled = false;
    @Input() text: string;
    @Input() type = 'button';
    @Output() click = new EventEmitter();
    public className: string;
    public rightIcon: string;
    public leftIcon: string;

      public onClick(event: Event) {
          if (!this.disabled) {
              this.click.emit();
              event.stopPropagation();
            } else {
              event.stopPropagation();
          }
      }
  }
