import { Component, AfterViewInit, ChangeDetectorRef, OnInit, AfterViewChecked } from '@angular/core';

import { Router } from '@angular/router';

import { TicketService } from '~services/ticket.service';
import { AuthService } from '~services/auth.service';


import * as ticketModels from '~models/ticket/index';
import { TicketViewModel } from '~models/ticket/index';
import { Pager } from '~modules/ng-pager/pager.model';

@Component({
  selector: 'app-client.row',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  providers: [TicketService]
})
export class TicketComponent implements AfterViewInit, OnInit, AfterViewChecked {
  key: string;
  pager: Pager;
  disablePager = false;
  public pageSize = 10;
  public currentPage = 0;
  public page = 0;
  public totalItems = 0;

  public isAdmin = false;
  public isLoading = false;
  public search = '';
  public customerId?: number;
  public tickets: TicketViewModel[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ticketService: TicketService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.key = '';
    this.isAdmin = this.authService.isAdmin();
  }

  ngAfterViewInit() {
    this.getData();
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  public onPaginateChange(event: any): void {
    this.page = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getData();
  }

  getData(request?: ticketModels.GetListTicketRequest): void {
    this.isLoading = true;

    if (!request) {
      request = new ticketModels.GetListTicketRequest();
      request.pageSize = this.pageSize;
    }

    request.key = this.key;

    this.ticketService.getList(request).subscribe((x) => {
      if (x.isSuccess) {
        this.tickets = x.list;
        this.totalItems = x.totalItems;
        this.currentPage = request == undefined ? 0 : request.pageIndex;
        this.pager = new Pager(x.totalItems, this.currentPage, this.pageSize);
        this.isLoading = false;
      }
    }, () => {
      this.isLoading = false;
    });
  }


  create(): void {
    this.router.navigate(['tickets/add']);
  }

  edit(id: number): void {
    this.router.navigate(['tickets/edit/' + id]);
  }




}
