import { NgModule } from '@angular/core';
import { AppCorkCardContainerComponent  , AppCorkCardHeaderComponent ,
  AppCorkCardBodyContentComponent, AppCorkCardBodyTitleComponent , AppCorkCardBodyWrapperComponent
} from '.';

  @NgModule({
    imports: [
      
    ],
    declarations: [
        AppCorkCardContainerComponent,
        AppCorkCardHeaderComponent,
        AppCorkCardBodyTitleComponent,
        AppCorkCardBodyWrapperComponent,
        AppCorkCardBodyContentComponent
    ],
    providers: [
    ],
    exports: [
        AppCorkCardContainerComponent,
        AppCorkCardHeaderComponent,
        AppCorkCardBodyTitleComponent,
        AppCorkCardBodyWrapperComponent,
        AppCorkCardBodyContentComponent

    ]
  })
  export class CardModule { }