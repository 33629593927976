import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable } from 'rxjs';

import {
  UpdateResponse, CreateResponse, GetItemRequest,
  DeleteRequest, DeleteResponse, GetListResponse, GetItemResponse, GetListRequest
} from '~models/index';



import { VariantViewModel, VariantUpdateRequest, VariantCreateRequest, GetVariantList } from '~models/productVariants';
import { UtilitiesService } from './utilities.service';


@Injectable()
export class VariantService {
  loading = true;

  constructor(
    private http: HttpClient,

    private utilitiesService: UtilitiesService
  ) { }



  getList(request?: GetVariantList):
    Observable<GetListResponse<VariantViewModel>> {
    let params = this.utilitiesService.listToParams(request);

    if (request != undefined) {
      if (request.productId) {
        params = params.append('productId', request.productId?.toString());
      }
      if(request.key){
        params= params.append('key', request.key?.toString());
      }
    }

    return this.http.get<GetListResponse<VariantViewModel>>(
      CONSTANST.routes.variant.list.replace(':productId', request.productId.toString()),
      {

        params: params
      }
    );
  }

  getOne(request: GetItemRequest): Observable<GetItemResponse<VariantViewModel>> {
    return this.http.get<GetItemResponse<VariantViewModel>>(
      CONSTANST.routes.variant.get.replace(':id', request.id.toString()),

    );
  }

  create(request: VariantCreateRequest): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      CONSTANST.routes.variant.create,
      request,

    );
  }

  update(request: VariantUpdateRequest): Observable<UpdateResponse> {
    return this.http.put<UpdateResponse>(
      CONSTANST.routes.variant.update.replace(':id', request.id.toString()),
      request,

    );
  }

  delete(request?: DeleteRequest):
    Observable<DeleteResponse> {
    const params = this.utilitiesService.deleteToParams(request);

    return this.http.delete<DeleteResponse>(
      CONSTANST.routes.variant.delete,
      {

        params: params
      }
    );
  }

}
