import { Component, OnInit } from '@angular/core';
import { EventEmitter, Input, Output, ViewContainerRef, Directive } from '@angular/core';


@Component({
    selector: 'ck-table-container',
    template: `
    <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped table-checkable table-highlight-head mb-4">
            <ng-content></ng-content>
        </table>
    </div>
    `,
  })

export class AppCorkTableContainerComponent { }
