import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ValidationMessages } from '~utils/ValidationMessages';
import { ValidatorModel } from '~app/models/ValidatorModel';

@Injectable()

export class ValidatorService {

    getMobileValidate(mobile: any): ValidatorModel {
        const response = new ValidatorModel();

        const pattern = /^09[0-9]{9}$/gm;
        const tester = new RegExp(pattern);
        if (tester.test(mobile)) {
          response.success = true;
        } else {
            response.success = false;
            response.error = ValidationMessages.mobile.error;
            response.helper = ValidationMessages.mobile.helper;
        }
        return response;
    }
    getRequired(input : any):ValidatorModel{
        const response = new ValidatorModel();

        if(input.length==0 || input == undefined){
            response.success = false;
            response.error = ValidationMessages.required.error;
            response.helper = ValidationMessages.required.helper;
        }
        else{
            response.success = true;
        }
        return response;
    }

}
