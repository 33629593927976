import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable } from 'rxjs';
import { GetItemResponse } from '~models/index';

import { StatsViewModel
 } from '~models/global';

@Injectable()
export class GlobalService {

    constructor(
        private http: HttpClient,
      ) { }



  getOne(): Observable<GetItemResponse<StatsViewModel>> {
    return this.http.get<GetItemResponse<StatsViewModel>>(
      CONSTANST.routes.global.stats,
    );
  }

  checkTelegramBotAlive():Observable<GetItemResponse<string>>{
    return this.http.get<GetItemResponse<string>>(CONSTANST.routes.global.telegramAlive);
  }
}
