import { Roles } from '~models/Roles';
import { GetListRequest } from '../GetListRequest';
import { UserStatus } from '../UserStatus';


export class SearchUserRequest extends GetListRequest{
    email?: string;
    emailConfirmed?: boolean;
    emailConfirmedAtMax?: Date;
    emailConfirmedAtMin?: Date;



    mobile?: string;
    mobileConfirmed?: boolean;
    mobileConfirmedAtMax?: Date;
    mobileConfirmedAtMin?: Date;

    phone?: string;
    isPhoneConfirmed?: boolean;
    phoneConfirmedAtMax?: Date;
    phoneConfirmedAtMin?: Date;

    createdAtMax?: Date;
    createdAtMin?: Date;

    modifiedAtMax?: Date;
    modifiedAtMin?: Date;

    name?: string;
    surname?: string;
    fatherName?: string;
    nationalCode?: string;

    role?: Roles;
    userStatus?: UserStatus;

    isLock?: boolean;
}
