import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable } from 'rxjs';

import {UpdateResponse , CreateResponse , GetItemRequest ,
     DeleteRequest , DeleteResponse, GetListResponse, GetItemResponse, GetListRequest } from '~models/index';

import { AuthService } from './auth.service';
import { UpdateRequest } from '~app/models/UpdateRequest';
import { BaseResponse } from '~app/models/BaseResponse';


import {  BankAccountCreateRequest , BankAccountGetListRequest,
   BankAccountTypes , BankAccountUpdateRequest , BankAccountViewModel , BankName } from '~models/bankAccount';
import { UtilitiesService } from './utilities.service';

@Injectable()
export class BankAccountService {
  loading = true;

  constructor(
    private http: HttpClient,
    private utilitiesService: UtilitiesService
  ) { }

  getList(request ?: BankAccountGetListRequest):
  Observable<GetListResponse<BankAccountViewModel>> {

    let params = this.utilitiesService.listToParams(request);
    if(request){
      if (request.customerId) {
        params = params.append('customerId', request.customerId?.toString());
      }

      if (request.key) {
        params = params.append('key', request.key?.toString());
      }
      
    }

    return this.http.get<GetListResponse<BankAccountViewModel>>(
      CONSTANST.routes.bankaccount.list,
      { 
        
         params: params
        }
    );
  }

  getOne(request: GetItemRequest): Observable<GetItemResponse<BankAccountViewModel>> {
    return this.http.get<GetItemResponse<BankAccountViewModel>>(
      CONSTANST.routes.bankaccount.get.replace(':id', request.id.toString()),
      
    );
  }

  create(request: BankAccountCreateRequest): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      CONSTANST.routes.bankaccount.create,
      request,
      
    );
  }

  update(request: BankAccountUpdateRequest): Observable<UpdateResponse> {
    return this.http.put<UpdateResponse>(
      CONSTANST.routes.bankaccount.update.replace(':id', request.id.toString()),
      request,
      
    );
    }

    delete(request: DeleteRequest): Observable<DeleteResponse> {
      const params = this.utilitiesService.deleteToParams(request);
      return this.http.delete<DeleteResponse>(
      CONSTANST.routes.bankaccount.delete,
      { 
         params : params }
    );
  }

  lock(request: UpdateRequest): Observable<UpdateResponse> {
        return this.http.get<UpdateResponse>(
         CONSTANST.routes.bankaccount.lock.replace(':id', request.id.toString()),
        
      );
    }

    unlock(request: UpdateRequest): Observable<UpdateResponse> {
      return this.http.get<UpdateResponse>(
        CONSTANST.routes.bankaccount.unlock.replace(':id', request.id.toString()),
      
    );
  }

  }
