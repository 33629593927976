import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '~services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if(err.status == 401){
          this.authService.destroy();
          window.location.replace('http://account.iran-warcraft.com');
        }
      if (err.error != undefined) {

        if(Array.isArray(err.error)){
          if(err.error.length == 1){
            this.toastr.error(err.error[0]);
          }
        }



          let  status;
          let  errors;
          if(err.error.status != undefined){
            status = err.error.status;
          }else if(err.status != undefined){
            status = err.status;
          }
        
          if(err.error.errors != undefined){
            errors = err.error.errors;
          }else if(err.errors != undefined){
            errors = err.errors;
          }

          let message: string;
          switch (status) {
            case 400:
              message = 'خطای مدل';
              break;
            case 401:
              message = 'خطا احراز هویت';
              this.authService.destroy();
              this.router.navigate(['/login']);
              break;
            case 403:
              message = 'خطا مجوز دسترسی';
              break;
            case 404:
              message = 'یافت نشد';
              break;
            case 422:
              message = 'خطای اعتبار سنجی';
              break;
            case 429:
              message = 'درخواست های بسیار زیاد است';
              break;
            case 500:
            default:
              message = 'خطای ناشناخته';
              break;
          }

          let messages = '';
          for (const [key, value] of Object.entries(errors)) {
            if (Array.isArray(value))
              for (let i = 0; i < value.length; i++) {
                const element = value[i];
                messages += element + '\n';
              }
          }
          this.toastr.error(messages, message);
          return throwError(messages);
        }
      })
    );
  }
}
