import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable } from 'rxjs';

import {
  UpdateResponse, CreateResponse, GetItemRequest,
  DeleteRequest, DeleteResponse, GetListResponse, GetItemResponse, GetListRequest
} from '~models/index';



import { GetProductListRequest, ProductCreateRequest, ProductUpdateRequest, ProductViewModel } from '~models/product';
import { UtilitiesService } from './utilities.service';


@Injectable()
export class ProductService {
  loading = true;

  constructor(
    private http: HttpClient,

    private utilitiesService: UtilitiesService
  ) { }



  getList(request?: GetProductListRequest):
    Observable<GetListResponse<ProductViewModel>> {
    let params = this.utilitiesService.listToParams(request);

    if(request){
      if(request.isActive){
        params = params.append('isActive', request.isActive?.toString());
      }
      if(request.key){
        params= params.append('key', request.key?.toString());
      }
    }

    return this.http.get<GetListResponse<ProductViewModel>>(
      CONSTANST.routes.product.list,
      {
        params: params
      }
    );
  }

  getOne(request: GetItemRequest): Observable<GetItemResponse<ProductViewModel>> {
    return this.http.get<GetItemResponse<ProductViewModel>>(
      CONSTANST.routes.product.get.replace(':id', request.id.toString()),
    );
  }

  create(request: ProductCreateRequest): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      CONSTANST.routes.product.create,
      request,
    );
  }

  update(request: ProductUpdateRequest): Observable<UpdateResponse> {
    return this.http.put<UpdateResponse>(
      CONSTANST.routes.product.update.replace(':id', request.id.toString()),
      request,
    );
  }

  delete(request?: DeleteRequest):
    Observable<DeleteResponse> {
    const params = this.utilitiesService.deleteToParams(request);

    return this.http.delete<DeleteResponse>(
      CONSTANST.routes.product.delete,
      {
        params: params
      }
    );
  }

}
