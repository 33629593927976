import {
    Pipe,
    PipeTransform
  } from '@angular/core';
import { BankAccountTypes } from '~app/models/bankAccount/BankAccountTypes';
  
  @Pipe({
    name: 'bankTypeFa'
  })
  export class BankTypeFaPipe implements PipeTransform {
  
    transform(name: string | null): string {
      if(!name){
        return '';
      }
      switch (name.toLowerCase()) {
        case BankAccountTypes.Sheba.toLowerCase():
          return 'شبا';
          case BankAccountTypes.Card.toLowerCase():
          return 'کارت'
            case BankAccountTypes.AccountNumber.toLowerCase():
          return 'حساب'
        default:
          return 'تعریف نشده'
      }
    }
  }