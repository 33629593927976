import { Component, OnInit } from '@angular/core';
import { EventEmitter, Input, Output, ViewContainerRef, Directive } from '@angular/core';


@Component({
    selector: 'ck-form-label',
    template: `
    <label for="for"><ng-content></ng-content></label>
    `,
  })
export class AppCorkFormLabelComponent implements OnInit {
    @Input() for = '';
    ngOnInit() {
        this.checkRequiredFields(this.for);
     }
     checkRequiredFields(input) {
        if(input === null) {
           throw new Error("Attribute 'for' is required");
        }
     }
 }
