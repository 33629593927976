<div class="col-lg-12 col-12 idkSpacing">
    <div class="statbox widget box box-shadow">
        <div class="widget-header">
            <div class="row mt-2">
                <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
                    <ck-btn-primary style="margin-right: 10px;" routerLink="/admin/licenses/add">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        لایسنس جدید
                        </ck-btn-primary>
                        <ck-btn-primary style="margin-right: 10px;" routerLink="/admin/licenses/bulk">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                           عملیات دسته ای
                            </ck-btn-primary>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
                    <ck-btn-cancel class="back-button" routerLink="/admin/licenses"></ck-btn-cancel>
                </div>
            </div>
        </div>
        <div class="widget-content widget-content-area">
            
            <form [formGroup]="frm" (ngSubmit)="onLicenseSubmit($event)" novalidate>

              
    
                
               <div class="form-row" *ngIf="isAdmin" id="userContainer">
                    <div class="form-group col-md-6">
                      <!--Using ng-option and for loop-->
                        <ng-select [(ngModel)]="selectedUserId" [ngModelOptions]="{standalone: true}"
                        placeholder="مشتری"  *ngIf="isAdmin"
                        class="basic placeholder js-states select2"
                        notFoundText="یافت نشد">
                            <ng-option *ngFor="let row of customers; index as i;" [value]="row.id">{{ row.fullName }} {{row.mobile}} {{row.email}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                
                
               <div class="form-row">
                    <div class="form-group col-md-6">

                          <!--Using ng-option and for loop-->
                          <ng-select [(ngModel)]="selectedProductVariantId" 
                          placeholder="محصول" [ngModelOptions]="{standalone: true}"
                          class="basic placeholder js-states select2"
                          notFoundText="یافت نشد">
                              <ng-option *ngFor="let row of variants; index as i;" 
                              [value]="row.id">{{ row.variantFullName}}</ng-option>
                          </ng-select>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="licenseStatus">وضعیت لایسنس</label>
                        <select class="placeholder js-states form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.licenseStatus.errors }"
                         name="licenseStatus" id="licenseStatus" formControlName="licenseStatus">
                            <option *ngFor="let row of licenseStatusOptions; index as i;" [value]="row" required>{{ row | licenseStatusFa }}</option>
                        </select>
                    </div>
                </div>
                

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="code">کد</label>
                        <input type="text" [ngClass]="{ 'is-invalid': submitted && f.code.errors }"
                         formControlName="code" class="form-control" name="code"
                         id="code" placeholder="کد">
                    </div>
                </div>

                <div class="form-group" *ngIf="isAdmin">
                    <div class="form-check pl-0">
                        <div class="custom-control custom-checkbox checkbox-info">
                            <input type="checkbox" class="custom-control-input"
                             name="isSold" formControlName="isSold" id="isSold">
                            <label class="custom-control-label" for="isSold">فروخته شده؟</label>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="isAdmin">
                    <div class="form-check pl-0">
                        <div class="custom-control custom-checkbox checkbox-info">
                            <input type="checkbox" class="custom-control-input"
                             name="isForSale" formControlName="isForSale" id="isForSale">
                            <label class="custom-control-label" for="isForSale">برای فروش</label>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="description">توضیحات</label>
                        <input type="text" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                         formControlName="description" class="form-control" name="description"
                         id="description" placeholder="توضیحات">
                    </div>
                </div>
            
    
                <ck-btn-save [disabled]="frm.pristine || frm.invalid && (frm.dirty || frm.touched)">ذخیره لایسنس</ck-btn-save>
            </form>


            
            <div class="table-responsive">
                <div>
                </div>
                <table class="table table-bordered table-hover table-striped table-checkable table-highlight-head">
                    <thead>
                        <tr>
                            <th class="checkbox-column">
                                <label class="new-control new-checkbox checkbox-primary" style="height: 18px; margin: 0 auto;">
                                    <input type="checkbox" class="new-control-input todochkbox" id="todoAll">
                                    <span class="new-control-indicator"></span>
                                </label>
                            </th>
                            <th class="">کد</th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>


            <tr *ngFor="let row of licenses; index as i;">
                <td class="checkbox-column">
                    <label class="new-control new-checkbox checkbox-primary" style="height: 18px; margin: 0 auto;">
                        <input type="checkbox" class="new-control-input todochkbox">
                        <span class="new-control-indicator"></span>
                    </label>
                </td>
                <td>
                    <p class="mb-0">{{row.code}}</p>
                </td>

                <td class="text-center">
                    <ul class="table-controls">
                        <li>
                            <button class="btn btn-dark mb-2 mr-2 rounded-circle" (click)="editLicense(row.id)" data-toggle="tooltip" data-placement="top" title="Edit">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-edit-2 text-success">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                </svg>
                            </button>
                        </li>
                        <li>
                            <button class="btn btn-dark mb-2 mr-2 rounded-circle" (click)="deleteLicense(row.id)" data-toggle="tooltip" data-placement="top" title="حذف">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                  class="feather feather-trash-2 text-danger">
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                        </li>
                    </ul>
                </td>
            </tr>


        </tbody>
    </table>
</div>

        </div>
    </div>
</div>