import { GetListRequest } from '~models/index';

export class LicenseSearchRequest extends GetListRequest {
    code: string | null;
    userId: number | null;
    isSold: boolean | null;
    forSell: boolean | null;
    fromDate: Date | null;
    toDate: Date | null;
    bulkOrderId: string | null;
}
