import { NgModule } from '@angular/core';
import {  AppCorkButtonPrimaryComponent , AppCorkButtonDangerComponent , AppCorkButtonInfoComponent 
     , AppCorkButtonSecondaryComponent , AppCorkButtonWarningComponent , AppCorkButtonSuccessComponent , 
      AppCorkButtonCancelComponent ,  AppCorkButtonSaveComponent ,  AppCorkButtonEditComponent ,  AppCorkButtonViewComponent
     
  } from '.';


@NgModule({
    imports: [
      
    ],
    declarations: [
        AppCorkButtonPrimaryComponent,
        AppCorkButtonDangerComponent,
        AppCorkButtonInfoComponent,
        AppCorkButtonSecondaryComponent,
        AppCorkButtonWarningComponent,
        AppCorkButtonSuccessComponent,

        AppCorkButtonCancelComponent,
        AppCorkButtonSaveComponent,

        AppCorkButtonEditComponent,
        AppCorkButtonViewComponent
    ],
    providers: [
    ],
    exports: [
        AppCorkButtonPrimaryComponent,
        AppCorkButtonDangerComponent,
        AppCorkButtonInfoComponent,
        AppCorkButtonSecondaryComponent,
        AppCorkButtonWarningComponent,
        AppCorkButtonSuccessComponent,

        AppCorkButtonCancelComponent,
        AppCorkButtonSaveComponent,

        AppCorkButtonEditComponent,
        AppCorkButtonViewComponent

    ]
  })
  export class ButtonModule { }
//https://stackoverflow.com/questions/43937387/if-selector-is-an-angular-component-then-verify-that-it-is-part-of-this-mod