<div class="col-lg-12 col-12 idkSpacing">
  <div class="statbox widget box box-shadow">
    <div class="widget-header">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
            <h4>لیست محصولات</h4>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
            <ck-btn-cancel class="back-button" routerLink="/admin"></ck-btn-cancel>
        </div>
      </div>
    </div>
    <div class="widget-content widget-content-area">
      <div>
        <div class="form-inline">
          <label class="sr-only" for="inlineFormInputName2">کلید</label>
            <input style="min-width: 300px;" type="text" class="form-control mb-2 mr-sm-2" [(ngModel)]="key" id="inlineFormInputName2"
              placeholder="کلید">
            <button type="button" (click)="getData()" class="btn btn-primary mb-2">جست و جو</button>
            <div class="ml-auto">
             <button routerLink="/admin/products/add" class="btn btn-primary mb-2">ایجاد محصول جدید</button>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped table-checkable table-highlight-head mb-4">
          <thead>
            <tr>
              <th class="">نام</th>
              <th class="">وضعیت</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of products; index as i;">
              <td>
                <p class="mb-0">{{row.name}}</p>
              </td>
              <td>{{row.isActive | isactive }}</td>

              <td class="text-center">
                <ul class="table-controls">
                    <li>
                        <button class="btn btn-dark mb-2 mr-2 rounded-circle" routerLink="/admin/products/edit/{{row.id}}" data-toggle="tooltip" data-placement="top" title="Edit">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-edit-2 text-success">
                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                            </svg>
                        </button>
                   </li>

                  <li>
                    <button class="btn btn-dark mb-2 mr-2 rounded-circle" (click)="delete(row.id)" data-toggle="tooltip" data-placement="top" title="حذف">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trash-2 text-danger">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </button>
                  </li>

                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ng-pager [pager]="pager" (pageChange)="getData($event)"></ng-pager>

    </div>
  </div>
</div>
