import { UpdateRequest } from '~models/index';
import { LicenseStatus } from './LicenseStatus';

export class LicenseUpdateRequest extends UpdateRequest {
    code: string;
    userId: number;
    productVariantId: number;
    isSold: boolean;
    licenseStatus: LicenseStatus;
    battleTag: string;
    description: string;
    isForSale :boolean;
    bulkOrderId:string;
}
