import { Component, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
// jQuery Sign $
declare let $: any;

@Component({
    selector: 'ck-btn-delete-dialog',
    templateUrl: './AppCordDeleteDialog.component.html'
  })
export class AppCorkDeleteDialogComponent {
    @Input() disabled = false;
    @Output() click = new EventEmitter();
    @Input() header: string;
    @ViewChild('myModal') myModal: ElementRef;
    @Output() onConfirmed: EventEmitter<any> = new EventEmitter();
    public onClick(event: Event) {
        if (!this.disabled) {
            this.click.emit();
            this.onConfirmedClicked(event);
            this.closeModal();

        } else {
            event.stopPropagation();
        }
    }

    onConfirmedClicked(clickedEntry: any): void {
       console.log(clickedEntry)
        this.onConfirmed.emit([clickedEntry]);
    }
    showModal() {
        // Show modal with jquery
        $(this.myModal.nativeElement).modal({'show':true,'backdrop':false});
    }
    closeModal() {
        // Close modal with jquery
        $(this.myModal.nativeElement).modal({'close':true});
    }
}
