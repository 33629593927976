import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// FILTER
import { AdminGuard } from '~app/guards/admin.guard';

// LAYOUTS
import { AdminLayoutComponent } from '~modules/admin-layout/admin-layout.component';
import { LoginLayoutComponent } from '~modules/login-layout/login-layout.component';

import {
  NotFoundComponent,
} from '~utils/index.pages';
import { CustomerGuard } from './guards/customer.guard';
import { CustomerLayoutComponent } from './modules/customer-layout/customer-layout.component';
import { RegisterLayoutComponent } from './modules/register-layout/register-layout.component';
import { RegisterComponent } from './modules/register-layout/register/register.component';

// ROUTES


const routes: Routes = [
  {
    path: '',
    component: RegisterComponent,
       children: [
      {
        path: 'login',
        loadChildren: '~modules/register-layout/register/register.module#RegisterModule',
      }
    ]
  },
  {
    path: '',
    component: CustomerLayoutComponent,
    canActivate: [CustomerGuard],
    children:[
      {
        path: 'customer',
        loadChildren: '~modules/customer-dashboard/customerdashboard.module#CustomerDashboardModule',
      },
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: 'admin',
        loadChildren: '~modules/dashboard/dashboard.module#DashboardModule',
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

