import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, AfterViewInit, ElementRef, ViewChild, QueryList, ViewEncapsulation  } from '@angular/core';
import { Observable  } from 'rxjs';

import { AuthService } from '~services/auth.service';
import { UserViewModel } from '~app/models/user/UserViewModel';
import { UserService } from '~app/services/user.service';
import { GlobalService } from '~app/services/global.service';

import { interval } from 'rxjs';
import { StatsViewModel } from '~app/models/global/StatsViewModel';
import { TicketService } from '~app/services/ticket.service';
import { GetListTicketRequest, TicketViewModel } from '~app/models/ticket';
import {isNumeric} from 'rxjs/util/isNumeric';
import { Router } from '@angular/router';
declare var $: any;
declare var App: any;

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [AuthService]
})

export class AdminLayoutComponent implements OnInit, AfterViewInit , AfterViewChecked {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private globalService: GlobalService,
    private ticketService: TicketService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
      ) {}
  user: UserViewModel;
  isLoggedIn$: Observable<boolean>;
  isBuyer: boolean;
  isSeller: boolean;
  isAdmin: boolean;
  isCustomer: boolean;
  stats: StatsViewModel;
  tickets: TicketViewModel[];
  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;

    this.isAdmin = this.authService.isAdmin();
    this.isBuyer = this.authService.isBuyer();
    this.isSeller = this.authService.isSeller();
    this.isCustomer = this.authService.isCustomer();

    const temp = {} as any;
    this.stats = temp;
    this.user = temp;
    this.tickets = temp;

    this.me();
    this.getStats();
    this.getTickets();
    this.initInterVals();
  }
  ngAfterViewInit() {
    $('body').addClass( 'alt-menu' );
    $('html').attr('dir','rtl');
    $('body').attr('dir','rtl');

    $('.bs-tooltip').tooltip();
    $('.bs-popover').popover();
    $('.t-dot').tooltip({
        template: '<div class="tooltip status rounded-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    });
    App.init();


    
}


  me() {
    this.userService.me().subscribe((data) => {
      if (data.isSuccess) {
        this.user = data.item;
      }
    });
  }

  initInterVals() {
    const second = 1000; // every 1 sec
    const minute = second * 60;
    const delay = minute * 1;


    const getStateInterval = interval(delay);
    getStateInterval.subscribe(x => { // will execute every 30 seconds
      this.getStats();
    });

  }

  getStats() {
    this.globalService.getOne().subscribe((data) => {
      if (data.isSuccess) {
        this.stats = data.item;
        if (isNumeric(this.stats.smsCharge)) {
          this.stats.smsCharge = Math.round(Number(this.stats.smsCharge));
        }
      }
    });
  }

  getTickets() {
    const request = new GetListTicketRequest();
    request.pageSize = 20;
    this.ticketService.getList(request).subscribe((data) => {
      if (data.isSuccess) {
        this.tickets = data.list;
      }
    });
  }

  logout() {
    this.authService.destroy();
    this.router.navigate(['/login']);
  }

  telegramBotAlive(){
    this.globalService.checkTelegramBotAlive().subscribe(x=>{

    },()=>{
      
    });
  }

  isSideBarClosed=true;
  toggleSideBar(){
    let sideElem = document.querySelector('.sidebar-wrapper');
    let container = document.querySelector('#container');
    if(sideElem){
      if(this.isSideBarClosed==true){
        sideElem.classList.add('sbar-open');
        container.classList.add('sbar-open');
        container.classList.remove('sidebar-closed');
        this.isSideBarClosed=false;
      }
      else{
        sideElem.classList.remove('sbar-open');
        container.classList.add('sidebar-closed');
        this.isSideBarClosed=true;
      }
    }
  }
}

