<button type="button" class="btn mb-2 btn-danger" (click)="showModal()">
    حذف
</button>
<div class="modal fade" #myModal id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{header}}</h5>
                <button type="button" (click)="closeModal()" class="close" data-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
                <p class="modal-text">
                    <ng-content></ng-content>
                </p>
            </div>
            <div class="modal-footer">
                <ck-btn-cancel data-dismiss="modal"></ck-btn-cancel>
                <ck-btn-danger (click)='onClick($event)' data-dismiss="modal">حذف</ck-btn-danger>
            </div>
        </div>
    </div>
</div>