import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable } from 'rxjs';


import * as attachment from '~models/user/attachments/index';
import { UtilitiesService } from './utilities.service';
import { CreateResponse, DeleteRequest, DeleteResponse, GetItemRequest, GetItemResponse } from '~models/index';


@Injectable()
export class UserAttachmentService {
  constructor(
    private http: HttpClient,
    
    private utilitiesService: UtilitiesService
  ) { }


  getOne(request: GetItemRequest): Observable<GetItemResponse<attachment.UserAttachmentViewModel>> {
    return this.http.get<GetItemResponse<attachment.UserAttachmentViewModel>>(
      CONSTANST.routes.user.attachment.get.replace(':id', request.id.toString()),
      
    );
  }

  create(request: attachment.CreateAttachmentRequest): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      CONSTANST.routes.user.attachment.create,
      request,
      
    );
  }

    delete(request ?: DeleteRequest):
    Observable<DeleteResponse> {
      const params = this.utilitiesService.deleteToParams(request);
      return this.http.delete<DeleteResponse>(
        CONSTANST.routes.user.attachment.delete,
        {
          
           params: params
          }
      );
    }

  }
