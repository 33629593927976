<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
    <div class="widget" style="height:100%">
        <div class="widget-heading">
            <h5 style="direction: ltr;
            text-align: right !important;">اطلاعات سیستم</h5>
        </div>

        <div class="widget-content">
            <p>اس ام اس باقیمانده : {{stats?.smsCharge}}</p>
            <p>تعدا کل تیکت ها : {{stats?.allTicketCount}}</p>
            <p>مبلغ کل واریزها : {{stats?.varizTotalPrice}}</p>
            <!-- <p>تعداد کل لایسنس ها : {{stats.totalLicenses}}</p> -->
            <p>تعداد کل لایسنس های فروخته شده : {{stats?.totalLicenesBoughFromUs}}</p>
            <p>تعداد کل لایسنس هایی که ما خریدیم : {{stats?.totalLicenesSoldToUs}}</p>
            <p>تعداد کل سفارشات : {{stats?.totalOrders}}</p>
            <p>تعداد کل سفارشات جدید : {{stats?.totalNewOrders}}</p>
        </div>
    </div>
</div>
   
   <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-table-three">

            <div class="widget-heading">
                <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12 col-12 widget-header">
                        <h6 class="pk-title">{{stats?.newTickets}} : تیکت های جدید</h6>
                        <button class="btn btn-outline-primary pk-button-title" routerLink="/admin/tickets">همه</button>
                    </div>                           
                </div>
            </div>

            <div class="widget-content">
                <div class="table-responsive mt-container table-scrool">
                    <table class="table">
                        <thead>
                            <tr>
                                <th><div class="th-content">#</div></th>
                                <th><div class="th-content th-heading">عنوان</div></th>
                                <th><div class="th-content th-heading">کاربر</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of stats?.tickets; index as i;" routerLink="/admin/tickets/edit/{{row.id}}">
                                <td><div class="td-content"><span class="pricing">#{{row.id}}</span></div></td>
                                <td><div class="td-content"><span class="pricing">{{row.subject}}</span></div></td>
                                <td><div class="td-content"><span class="discount-pricing">{{row.user.fullName}}</span></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="statbox widget box box-shadow widget-table-three">
            
            <div class="widget-heading">
                <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12 col-12 widget-header">
                        <h6 class="pk-title">لایسنس های آماده برای فروش</h6>
                        <button class="btn btn-outline-primary pk-button-title" [routerLink]="['/admin/licenses']" [queryParams]="{ forSell: 'true' , canSell: 'true' }">همه</button>
                    </div>                           
                </div>
            </div>

            <div class="widget-content widget-content-area">
                <div class="table-responsive mt-container table-scrool">
                    <table class="table">
                        <thead>
                            <tr>
                                <th><div class="th-content">#</div></th>
                                <th><div class="th-content th-heading">نام محصول</div></th>
                                <th><div class="th-content th-heading">تاریخ ثبت</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of totalLicenesReadyToSell; index as i;" routerLink="/admin/licenses/bulk/{{row.groupId}}">
                                <td><div class="td-content"><span class="pricing">#{{row.groupId}}</span></div></td>
                                <td><div class="td-content"><span class="pricing">{{row.groupProductName}}</span></div></td>
                                <td><div class="td-content"><span class="discount-pricing">{{row.groupcreatedAt | jalali }}</span></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="statbox widget box box-shadow widget-table-three">
            
            <div class="widget-heading">
                <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12 col-12 widget-header">
                        <h6 class="pk-title">لایسنس های جدید برای فروش</h6>
                        <button class="btn btn-outline-primary pk-button-title" [routerLink]="['/admin/licenses']" [queryParams]="{ forSell: 'true'}">همه</button>
                    </div>                           
                </div>
            </div>

            <div class="widget-content widget-content-area">
                <div class="table-responsive mt-container table-scrool">
                    <table class="table">
                        <thead>
                            <tr>
                                <th><div class="th-content">#</div></th>
                                <th><div class="th-content th-heading">نام محصول</div></th>
                                <th><div class="th-content th-heading">تاریخ ثبت</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of newLicenseSalesToUsGrouped; index as i;" routerLink="/admin/licenses/bulk/{{row.groupId}}">
                                <td><div class="td-content"><span class="pricing">#{{row.groupId}}</span></div></td>
                                <td><div class="td-content"><span class="pricing">{{row.groupProductName}}</span></div></td>
                                <td><div class="td-content"><span class="discount-pricing">{{row.groupcreatedAt | jalali }}</span></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-table-three">


            <div class="widget-heading">
                <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12 col-12 widget-header">
                        <h6 class="pk-title">لایسنس های جدید خریداری شده ازما</h6>
                        <button class="btn btn-outline-primary pk-button-title" [routerLink]="['/admin/licenses']" [queryParams]="{ isSold: 'true'}">همه</button>
                    </div>                           
                </div>
            </div>

            <div class="widget-content">
                <div class="table-responsive mt-container table-scrool">
                    <table class="table">
                        <thead>
                            <tr>
                                <th><div class="th-content">#</div></th>
                                <th><div class="th-content th-heading">نام محصول</div></th>
                                <th><div class="th-content th-heading">تاریخ ثبت</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of newLicenseBuyFromUsGrouped; index as i;" routerLink="/admin/licenses/bulk/{{row.groupId}}">
                                <td><div class="td-content"><span class="pricing">#{{row.groupId}}</span></div></td>
                                <td><div class="td-content"><span class="pricing">{{row.groupProductName}}</span></div></td>
                                <td><div class="td-content"><span class="discount-pricing">{{row.groupcreatedAt | jalali }}</span></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-table-three">

            <div class="widget-heading">
                <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12 col-12 widget-header">
                        <h6 class="pk-title">سفارش های جدید : {{stats?.totalNewOrders}}</h6>
                        <button class="btn btn-outline-primary pk-button-title" routerLink="/admin/orders" [queryParams]="{ new: 'true'}">همه</button>
                    </div>                           
                </div>
            </div>

            <div class="widget-content">
                <div class="table-responsive mt-container table-scrool">
                    <table class="table">
                        <thead>
                            <tr>
                                <th><div class="th-content">#</div></th>
                                <th><div class="th-content th-heading">نام محصول</div></th>
                                <th><div class="th-content th-heading">کاربر</div></th>
                                <th><div class="th-content th-heading">تاریخ ثبت</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of stats?.newOrders; index as i;" routerLink="/admin/orders/edit/{{row.id}}">
                                <td><div class="td-content"><span class="pricing">#{{row.id}}</span></div></td>
                                <td><div class="td-content"><span class="pricing">{{row.variant?.variantFullName}}</span></div></td>
                                <td><div class="td-content"><span class="pricing">{{row.user?.fullName}}</span></div></td>
                                <td><div class="td-content"><span class="discount-pricing">{{row.createdAt | jalali }}</span></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    