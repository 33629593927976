import { FileModel } from '../FileModel';


export class LicenseCreateByFileRequest {
    file: FileModel[];
    userId: number;
    productVariantId: number;
    battleTag: string;
    description: string;
    isForSale: boolean;
}
