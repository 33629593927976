<ng-container *ngIf="pager && pager.totalRecords > pager.pageSize">
    <ng-container *ngIf="paged">
        <div class="input-group">
            <div class="input-group-prepend">
                <button [disabled]="(!pager.hasPrevious || loading || disabled) ? true : null" class="btn btn-sm btn-info btn-nav btn-prev {{cssClasses.prevButton}}" [class.disabled]="!pager.hasPrevious"
                    type="button" (click)="changePage(pager.pageIndex - 1)">
                    <span>&laquo;</span>
                </button>
            </div>
            <input #page type="text" [value]="pager.currentPage" class="form-control input-sm weight-500 {{cssClasses.pageNumber}}"
                (focus)="page.select()"
                (blur)="page.value = pager.currentPage"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
                [disabled]="(loading || disabled) ? true : null">
            <div class="input-group-append">
                <button [disabled]="(!pager.hasNext || loading || disabled) ? true : null" class="btn btn-sm btn-info btn-nav btn-next {{cssClasses.nextButton}}" [class.disabled]="!pager.hasNext"
                    type="button" (click)="changePage(pager.pageIndex + 1)">
                    <span>&raquo;</span>
                </button>
            </div>
        </div>
        <div class="info {{cssClasses.infoText}}">
            نمایش : {{pager.records.from}} - {{pager.records.to}} از {{pager.totalRecords}}
        </div>
    </ng-container>
    <div *ngIf="!paged && pager.hasNext" class="load-more-wrapper text-center">
        <span *ngIf="loading" class="loading"></span>
        <a *ngIf="!loading" href="javascript:;" class="load-more" (click)="changePage(pager.pageIndex + 1)">
            <i class="arrow"></i>
            بیشتر
        </a>
    </div>
</ng-container>
