import { GetListRequest } from '../GetListRequest';

export class OrderGetListRequest extends GetListRequest {
  customerId: number | null;
  id: number | null;
  fromDate: Date | null;
  toDate: Date | null;
  variantId: number | null;
  code: string | null;
}
