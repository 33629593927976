import {
    LicenseStatus
  } from '~models/license/LicenseStatus';
  import {
    Pipe,
    PipeTransform
  } from '@angular/core';
  
  /*
   * Raise the value exponentially
   * Takes an exponent argument that defaults to 1.
   * Usage:
   *   value | exponentialStrength:exponent
   * Example:
   *   {{ 2 | exponentialStrength:10 }}
   *   formats to: 1024
   */
  @Pipe({
    name: 'licenseStatusFa'
  })
  export class LicenseStatusFaPipe implements PipeTransform {
  
    transform(status: string | null): string {
      if(!status){
        return '';
      }
      let name = '';
  
      switch (status.toLowerCase()) {
        case LicenseStatus.Approved.toLowerCase():
          name = 'تایید';
          break;
        case LicenseStatus.InProgress.toLowerCase():
          name = 'درحال پردازش';
          break;
        case LicenseStatus.PreInProgress.toLowerCase():
          name = 'دریافت شد';
          break;
        case LicenseStatus.Reject.toLowerCase():
          name = 'رد شد';
          break;
      }
      return name;
    }
  }
  