import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../utils/shared.module';

import { LicenseComponent } from './license.component';
import { FormComponent } from './form/form.component';
import { BulkComponent } from './bulk/bulk.component';

@NgModule({
    imports: [
      RouterModule.forChild([
        {path: '', component: LicenseComponent},
        {path: 'add', component: FormComponent},
        {path: 'edit/:id', component: FormComponent},
        {path: 'bulk', component: BulkComponent},
        {path: 'bulk/:groupId', component: BulkComponent},
    ]),
      SharedModule
    ],
    declarations: [
        LicenseComponent,
        FormComponent,
        BulkComponent
    ],
    providers: [],
    entryComponents: [
        FormComponent,
        BulkComponent
    ],
    exports: [
      RouterModule,
    ]
  })
  export class LicenseModule { }
