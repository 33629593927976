import { Component } from '@angular/core';

@Component({
    selector: 'ck-table-body',
    template: `
    <tbody>
            <tr>
                <ng-content></ng-content>
            </tr>
    </tbody>
    `,
  })
export class AppCorkTableBodyComponent { }
