<div class="col-lg-12 col-12 idkSpacing">
    <div class="statbox widget box box-shadow">
        <div class="widget-header">
            <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
                        <h4 *ngIf="isAddMode">ثبت کاربر جدید</h4>
                        <h4 *ngIf="!isAddMode">ویرایش کاربر</h4>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
                        <ck-btn-cancel class="back-button" routerLink="/admin/users"></ck-btn-cancel>
                    </div>
            </div>
        </div>
        <div class="widget-content widget-content-area">
            

            <div class="row">
                <div class="col-6">

                    <form [formGroup]="frmUser" (ngSubmit)="onUserSubmit($event)" novalidate>
              
                        <div>
        
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="email">ایمیل</label>
                                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                 formControlName="email" class="form-control" name="email"
                                 id="email" placeholder="ایمیل">
                            </div>
                        </div>
        
                        <div class="form-group">
                            <div class="form-check pl-0">
                                <div class="custom-control custom-checkbox checkbox-info">
                                    <input type="checkbox" class="custom-control-input"
                                     name="isEmailConfirmed"formControlName="isEmailConfirmed" id="isEmailConfirmed">
                                    <label class="custom-control-label" for="isEmailConfirmed">تایید ایمیل</label>
                                </div>
                            </div>
                        </div>
        
                    </div>
        
        
        
                    <div>
                        
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="mobile">موبایل</label>
                                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
                                 formControlName="mobile" class="form-control" name="mobile"
                                 id="mobile" placeholder="موبایل">
                            </div>
                        </div>
        
                        <div class="form-group">
                            <div class="form-check pl-0">
                                <div class="custom-control custom-checkbox checkbox-info">
                                    <input type="checkbox" class="custom-control-input"
                                     name="isMobileConfirmed"formControlName="isMobileConfirmed" id="isMobileConfirmed">
                                    <label class="custom-control-label" for="isMobileConfirmed">تایید موبایل</label>
                                </div>
                            </div>
                        </div>
        
                    </div>
        
        
        
        
                    <div>
                        
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="phone">تلفن</label>
                                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                                 formControlName="phone" class="form-control" name="phone"
                                 id="phone" placeholder="تلفن">
                            </div>
                        </div>
        
                        <div class="form-group">
                            <div class="form-check pl-0">
                                <div class="custom-control custom-checkbox checkbox-info">
                                    <input type="checkbox" class="custom-control-input"
                                     name="isPhoneConfirmed"formControlName="isPhoneConfirmed" id="isPhoneConfirmed">
                                    <label class="custom-control-label" for="isPhoneConfirmed">تایید تلفن</label>
                                </div>
                            </div>
                        </div>
        
                    </div>
        
        
        
                    <div>
                        
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="name">نام</label>
                                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                 formControlName="name" class="form-control" name="name"
                                 id="name" placeholder="نام">
                            </div>
                        </div>
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="surname">نام خانوادگی</label>
                                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.surname.errors }"
                                 formControlName="surname" class="form-control" name="surname"
                                 id="surname" placeholder="نام خانوادگی">
                            </div>
                        </div>
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="fatherName">نام پدر</label>
                                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.fatherName.errors }"
                                 formControlName="fatherName" class="form-control" name="fatherName"
                                 id="fatherName" placeholder="نام پدر">
                            </div>
                        </div>
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="nationalCode">کد ملی</label>
                                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.nationalCode.errors }"
                                 formControlName="nationalCode" class="form-control" name="nationalCode"
                                 id="nationalCode" placeholder="کد ملی">
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <div class="form-check pl-0">
                                <div class="custom-control custom-checkbox checkbox-info">
                                    <input type="checkbox" class="custom-control-input"
                                     name="isNationalCardVerified"formControlName="isNationalCardVerified" id="isNationalCardVerified">
                                    <label class="custom-control-label" for="isNationalCardVerified">تایید کدملی</label>
                                </div>
                            </div>
                        </div>
        
        
                        <div class="form-group">
                            <div class="form-check pl-0">
                                <div class="custom-control custom-checkbox checkbox-info">
                                    <input type="checkbox" class="custom-control-input"
                                     name="isLock"formControlName="isLock" id="isLock">
                                    <label class="custom-control-label" for="isLock">غیر قابل ویرایش</label>
                                </div>
                            </div>
                        </div>
        
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="role">نقش</label>
                                <select class="placeholder js-states form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.role.errors }"
                                 name="role" id="role" formControlName="role">
                                    <option *ngFor="let row of roleOptions; index as i;" [value]="row" required>{{ row | rolename }}</option>
                                </select>
                                <div *ngIf="f.role.invalid && (f.role.dirty || f.role.touched)" class="invalid-feedback">
                                    <div *ngIf="f.role.errors.required">
                                    Name is required.
                                  </div>
                                </div>
                            </div>
                        </div>
        
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="userStatus">وضعیت</label>
                                <select class="placeholder js-states form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.userStatus.errors }"
                                 name="userStatus" id="userStatus" formControlName="userStatus">
                                    <option *ngFor="let row of userStatusOptions; index as i;" [value]="row" required>{{ row | userStatusFa }}</option>
                                </select>
                                <div *ngIf="f.userStatus.invalid && (f.userStatus.dirty || f.userStatus.touched)" class="invalid-feedback">
                                    <div *ngIf="f.userStatus.errors.required">
                                    Status is required.
                                  </div>
                                </div>
                            </div>
                        </div>
        
        
                        <div class="form-row mb-4">
                            <div class="form-group col-md-12">
                                <label for="userLevel">سطح</label>
                                <select class="placeholder js-states form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.userLevel.errors }"
                                 name="userLevel" id="userLevel" formControlName="userLevel">
                                    <option *ngFor="let row of userLevelOptions; index as i;" [value]="row" required>{{ row | userLevelFa }}</option>
                                </select>
                                <div *ngIf="f.userLevel.invalid && (f.userLevel.dirty || f.userLevel.touched)" class="invalid-feedback">
                                    <div *ngIf="f.userLevel.errors.required">
                                      UserLevel is required.
                                  </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="d-flex">
                            <div class="relative mr-2" *ngFor="let attachment of user.attachments; index as i;" >
                                <span *ngIf="isAdmin" (click)="deleteAttachment(attachment.id)" class="deleteAttachment">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                                </span>
                                <a 
                                [attr.href]="attachment.address"
                                download="download" class="btn btn-dark mb-2 mr-2 rounded-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                </a>
                            </div>
                        </div>
        
        
        
                    </div>
        
        
        
                    <ck-btn-save [disabled]="frmUser.pristine || frmUser.invalid && (frmUser.dirty || frmUser.touched)">ذخیره کاربر</ck-btn-save>
        
                    </form>
        
                    
                        
                    <div class="form-group file-upload" [ngClass]="{'edit-mode':!isAddMode}">
                        <input type="file" class="form-control form-control-rounded" alife-file-to-base64 [(fileModel)]="meliCard" />
                    </div>
                    <div class="ml-auto">
                        <button (click)="submitMeliCard()" [disabled]="meliCard==null"
                         class="btn btn-primary mb-2" *ngIf="isAdmin">ارسال کارت ملی</button>
                     </div>
        
        

                </div>
                <div class="col-6">
                    <div>
                        <h2>اطلاعات بانکی</h2>
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped table-checkable table-highlight-head mb-4">
                              <thead>
                                <tr>
                                  <th class="checkbox-column">
                                    <label class="new-control new-checkbox checkbox-primary" style="height: 18px; margin: 0 auto;">
                                      <input type="checkbox" class="new-control-input todochkbox">
                                      <span class="new-control-indicator"></span>
                                    </label>
                                  </th>
                                  <th class="">نوع حساب</th>
                                  <th class="">نام بانک</th>
                                  <th class="">شماره</th>
                                  <th *ngIf="isAdmin" class="">نام کاربر</th>
                                  <th *ngIf="isAdmin" class="">قفل</th>
                                  <th class="text-center"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let row of bankaccounts; index as i;">
                                  <td class="checkbox-column">
                                    <label class="new-control new-checkbox checkbox-primary" style="height: 18px; margin: 0 auto;">
                                      <input type="checkbox" class="new-control-input todochkbox">
                                      <span class="new-control-indicator"></span>
                                    </label>
                                  </td>
                                  <td>
                                    <p class="mb-0">{{row.type | bankTypeFa }}</p>
                                  </td>
                                  <td>{{row.bankName | bankNameFa }}</td>
                                  <td>{{ row.code }}</td>
                                  <td *ngIf="isAdmin">{{row.user.fullName}}</td>
                                  <td *ngIf="isAdmin">{{row.isEditable | yesNo }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                    
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</div>