import { NgModule } from '@angular/core';
import { AppCorkTableBodyComponent , AppCorkTableContainerComponent , AppCorkTableHeaderComponent 
} from './index';

  @NgModule({
    imports: [
      
    ],
    declarations: [
        AppCorkTableBodyComponent,
        AppCorkTableContainerComponent,
        AppCorkTableHeaderComponent
    ],
    providers: [
    ],
    exports: [
        AppCorkTableBodyComponent,
        AppCorkTableContainerComponent,
        AppCorkTableHeaderComponent
    ]
  })
  export class TableModule { }