import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { interval } from 'rxjs';

import { UserViewModel } from '~app/models/user/UserViewModel';
import { UserService } from '~app/services/user.service';
import { GlobalService } from '~app/services/global.service';
import { StatsViewModel } from '~app/models/global/StatsViewModel';
import { TicketService } from '~app/services/ticket.service';
import {isNumeric} from 'rxjs/util/isNumeric';
import { GetListTicketRequest, TicketViewModel } from '~app/models/ticket';
import { LicenseGroupViewModel, LicenseViewModel } from '~app/models/license';
import * as licenseModel from '~app/models/license';
import { UtilitiesService } from '~app/services/utilities.service';

@Component({
  selector: 'app-dashboard.row',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {

  constructor(    
    private authService: AuthService,
    private userService: UserService,
    private globalService: GlobalService,
    private ticketService: TicketService,
    private changeDetectorRef: ChangeDetectorRef,
    private utilitiesService:UtilitiesService
    
  ) { }
  stats: StatsViewModel;
  tickets: TicketViewModel[];
  ngOnInit() {   
    this.getStats();
    this.getTickets();
    this.initInterVals();
  }

  getTickets() {
    const request = new GetListTicketRequest();
    request.pageSize = 20;
    this.ticketService.getList(request).subscribe((data) => {
      if (data.isSuccess) {
        this.tickets = data.list;
      }
    });
  }

  newLicenseSalesToUsGrouped : LicenseGroupViewModel[] = [];
  newLicenseBuyFromUsGrouped : LicenseGroupViewModel[] = [];
  totalLicenesReadyToSell : LicenseGroupViewModel[] = [];
  
  getStats() {
    this.globalService.getOne().subscribe((data) => {
      if (data.isSuccess) {
        this.newLicenseBuyFromUsGrouped = [];
        this.newLicenseSalesToUsGrouped = [];

        this.stats = data.item;
        if (isNumeric(this.stats.smsCharge)) {
          this.stats.smsCharge = Math.round(Number(this.stats.smsCharge));
        }

        this.newLicenseSalesToUsGrouped = this.utilitiesService.groupLicenses(this.stats.newLicenseSalesToUs);
        this.newLicenseBuyFromUsGrouped = this.utilitiesService.groupLicenses(this.stats.newLicenseBuyFromUs);
        this.totalLicenesReadyToSell  = this.utilitiesService.groupLicenses(this.stats.totalLicenesReadyToSell);
      }
    });
  }

  initInterVals() {
    const second = 1000; // every 1 sec
    const minute = second * 60;
    const delay = minute * 1;


    const getStateInterval = interval(delay);
    getStateInterval.subscribe(x => { // will execute every 30 seconds
      this.getStats();
    });


    if( window.localStorage )
    {
      if( !localStorage.getItem('firstLoad') )
      {
        localStorage['firstLoad'] = true;
        window.location.reload();
      }  
      else
        localStorage.removeItem('firstLoad');
    }
  }




  
}
