import { NgModule } from '@angular/core';
import {
  RoleNamePipe,
  Safe,
  JalaliPipe,
  LicenseStatusFaPipe,
  TicketStatusFaPipe,
  TomanPipe,
  OrderStatusFaPipe,
  UserLevelFaPipe,
  UserStatusFaPipe,
  IsActivePipe,
  BankNameFaPipe,
  BankTypeFaPipe,
  YesNoPipe,
  FaNumberPipe
} from './index';

@NgModule({
  imports: [],
  declarations: [
    FaNumberPipe,
    RoleNamePipe,
    Safe,
    IsActivePipe,
    BankNameFaPipe,
    BankTypeFaPipe,
    UserStatusFaPipe,
    YesNoPipe,
    LicenseStatusFaPipe,
    TicketStatusFaPipe,
    JalaliPipe,
    UserLevelFaPipe,
    TomanPipe,
    OrderStatusFaPipe,
  ],
  providers: [],
  exports: [
    FaNumberPipe,
    RoleNamePipe,
    Safe,
    IsActivePipe,
    BankNameFaPipe,
    BankTypeFaPipe,
    UserStatusFaPipe,
    YesNoPipe,
    LicenseStatusFaPipe,
    TicketStatusFaPipe,
    JalaliPipe,
    UserLevelFaPipe,
    TomanPipe,
    OrderStatusFaPipe,
  ],
})
export class PipesModule {}
