
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '~services/auth.service';

import { Observable } from 'rxjs';

@Injectable()
export class CustomerGuard implements CanActivate , CanActivateChild  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
  {
    console.log('customer gaurd')
    if(this.authService.isCustomer()){
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
}

canActivateChild(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    console.log('customer gaurd child')

  if (this.authService.isCustomer()) {
    this.router.navigate([''], { queryParams: { returnUrl: state.url } });
    return true;
  }

  return false;
}
}
