import { Component } from '@angular/core';
import { EventEmitter, Input, Output, ViewContainerRef, Directive } from '@angular/core';

@Component({
    selector: 'ck-form-group',
    template: `
    <div class="form-group"
    [class]="{
        'col-lg-12' : col=='12' ,
        'col-lg-6' : col == '6',
        'col-lg-4' : col == '4'
    }"
    >
           <ng-content></ng-content>
    </div>
    `,
  })
export class AppCorkFormGroupComponent {
    @Input() col = 'col-lg-12';
 }
