import {
    Pipe,
    PipeTransform
  } from '@angular/core';
  
  @Pipe({
    name: 'userLevelFa'
  })
  export class UserLevelFaPipe implements PipeTransform {
  
    transform(name: string | null | number): string {
      if(name == undefined){
        return
      }
      name = name.toString();
      if(name == '' || name == undefined){
          return '';
      }
      else{
        switch (name.toLocaleLowerCase()) {
            case 'bronze':
                return 'برنزی';

            case 'silver':
                return 'نقره ای';

            case 'gold':
                return 'طلایی';
        
            default:
                return 'تعریف نشده';
        }
      }
    }
  }
  