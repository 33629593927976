<div class="col-lg-12 col-12 idkSpacing">
    <div class="statbox widget box box-shadow">
        <div class="widget-header">
            <div class="row mt-2">
                <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
                    
                </div>
                <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
                    <ck-btn-cancel class="back-button" routerLink="/admin/licenses"></ck-btn-cancel>
                </div>
            </div>
        </div>
        <div class="widget-content widget-content-area">

            <div class="form-row" *ngIf="!isAddMode">
                <div class="form-group col-md-6">
                     <!--Using ng-option and for loop-->
                     <ng-select [(ngModel)]="grouped.groupUser" [ngModelOptions]="{standalone: true}"
                     placeholder="مشتری"  *ngIf="isAdmin"
                     class="basic placeholder js-states select2"
                     notFoundText="یافت نشد">
                         <ng-option *ngFor="let row of customers; index as i;" [value]="row.id">{{ row.fullName }} {{row.mobile}} {{row.email}}</ng-option>
                     </ng-select>
                </div>
            </div>

            <div class="form-row" *ngIf="isAddMode">
                <div class="form-group col-md-6">
                     <!--Using ng-option and for loop-->
                     <ng-select [(ngModel)]="grouped.groupUserId" [ngModelOptions]="{standalone: true}"
                     placeholder="مشتری"  *ngIf="isAdmin"
                     class="basic placeholder js-states select2"
                     notFoundText="یافت نشد">
                         <ng-option *ngFor="let row of customers; index as i;" [value]="row.id">{{ row.fullName }} {{row.mobile}} {{row.email}}</ng-option>
                     </ng-select>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <!--Using ng-option and for loop-->
                    <ng-select [(ngModel)]="grouped.groupProductVariantId" 
                    placeholder="محصول" [ngModelOptions]="{standalone: true}"
                    class="basic placeholder js-states select2"
                    notFoundText="یافت نشد">
                        <ng-option *ngFor="let row of variants; index as i;" 
                        [value]="row.id">{{ row.variantFullName }}</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="groupStatus">وضعیت لایسنس</label>

                    <ng-select [(ngModel)]="grouped.groupStatus" 
                    placeholder="محصول" [ngModelOptions]="{standalone: true}"
                    class="basic placeholder js-states select2"
                    notFoundText="یافت نشد">
                        <ng-option *ngFor="let row of licenseStatusOptions; index as i;" 
                        [value]="row">{{ row | licenseStatusFa }}</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <div class="custom-control custom-checkbox checkbox-info">
                        <input type="checkbox" class="custom-control-input"
                         name="groupIsSold"  [(ngModel)]="grouped.groupIsSold" id="groupIsSold">
                        <label class="custom-control-label" for="groupIsSold">فروخته شده؟</label>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <div class="custom-control custom-checkbox checkbox-info">
                        <input type="checkbox" class="custom-control-input"
                         name="groupIsForSale"  [(ngModel)]="grouped.groupIsForSale" id="groupIsForSale">
                        <label class="custom-control-label" for="groupIsForSale">برای فروش؟</label>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="groupDescription">توضیحات</label>
                    <input type="text"
                    [(ngModel)]="grouped.groupDescription" class="form-control" name="groupDescription"
                     id="groupDescription" placeholder="توضیحات">
                </div>
            </div>

            <div class="col-12 col-md-6">

                <div class="form-row">
                    <label for="">کد ها</label>
                    <ngx-codemirror 
                    style="text-align:left;direction:ltr;width: 100%;"
                    [(ngModel)]="codes"
                    [options]="codeMirrorOptions"
                  ></ngx-codemirror>

                </div>
                
            </div>

            <div class="col-6" *ngIf="invalidCodes.length>0">

                <div class="form-row">

                    <label for="">کد های غیر معتبر</label>
                    <ngx-codemirror 
                    style="text-align:left;direction:ltr;width: 100%;"
                    [(ngModel)]="invalidCodesText"
                    [options]="codeMirrorOptions"
                  ></ngx-codemirror>
                   
                </div>

            </div>
           

            <ck-btn-save (click)="saveLicenses()" *ngIf="isAddMode" [disabled]="isSubmitting">ذخیره لایسنس ها</ck-btn-save>
            <ck-btn-save (click)="updateLicenses()" *ngIf="!isAddMode" [disabled]="isSubmitting">آپدیت لایسنس ها</ck-btn-save>

        </div>
    </div>
</div>