import {
    TicketStatus
  } from '~models/ticket/TicketStatus';
  import {
    Pipe,
    PipeTransform
  } from '@angular/core';

  /*
   * Raise the value exponentially
   * Takes an exponent argument that defaults to 1.
   * Usage:
   *   value | exponentialStrength:exponent
   * Example:
   *   {{ 2 | exponentialStrength:10 }}
   *   formats to: 1024
   */
  @Pipe({
    name: 'ticketStatusFa'
  })
  export class TicketStatusFaPipe implements PipeTransform {

    transform(status: string | null): string {
      if (!status) {
        return '';
      }
      let name = '';

      switch (status.toLowerCase()) {
        case TicketStatus.Closed.toLowerCase():
          name = 'بسته';
          break;
          case TicketStatus.PendingCustomerReply.toLowerCase():
          name = 'در انتظار پاسخ';
          break;
          case TicketStatus.OnHold.toLowerCase():
            name = 'در دست بررسی';
            break
          case TicketStatus.Open.toLowerCase():
            name = 'باز';
            break;
        case TicketStatus.InProgress.toLowerCase():
          name = 'درحال انجام';
          break;
      }
      return name;
    }
  }
