import { UpdateRequest } from '../UpdateRequest';

export class BankAccountUpdateRequest extends UpdateRequest {
    bankName: string;
    hesab: string;
    cart: string;
    sheba: string;
    sahebHesab: string;
    userId: number;
    isEditable: boolean;
}