import { Component } from '@angular/core';
import { AppCorkButtonBaseComponent } from './base.component';

@Component({
  selector: 'ck-btn-primary',
  templateUrl: './base.component.html'
})
export class AppCorkButtonPrimaryComponent extends AppCorkButtonBaseComponent {
  className = 'btn-primary';
}
