import {
  Roles
} from '~models/Roles';
import {
  Pipe,
  PipeTransform
} from '@angular/core';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({
  name: 'rolename'
})
export class RoleNamePipe implements PipeTransform {

  transform(role: string | null): string {
    if(!role){
      return '';
    }
    let name = '';

    switch (role.toLowerCase()) {
      case Roles.Admin.toLowerCase():
        name = 'مدیر';
        break;
      case Roles.Buyer.toLowerCase():
        name = 'خریدار';
        break;
      case Roles.Seller.toLowerCase():
        name = 'فروشنده';
        break;
      default:
      case Roles.Guest.toLowerCase():
        name = 'مهمان';
        break;
    }
    return name;
  }
}
