import { Component } from '@angular/core';
import { AppCorkButtonBaseComponent } from './base.component';

@Component({
  selector: 'ck-btn-secondary',
  templateUrl: './base.component.html'
})
export class AppCorkButtonSecondaryComponent extends AppCorkButtonBaseComponent {
  className = 'btn-secondary';
}
