import { Component, Input } from '@angular/core';
import { AppCorkButtonPrimaryComponent } from './primary.component';

@Component({
  selector: 'ck-btn-save',
  template: `
  <button (click)='onClick($event)' [disabled]='disabled'
  class="btn mb-2 btn-primary">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
  stroke-linejoin="round" class="feather feather-check">
  <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
  ذخیره
  </button>
  `
})
export class AppCorkButtonSaveComponent extends AppCorkButtonPrimaryComponent {
  className = this.className + ' mr-2';
  type = 'submit';
  @Input() disabled;
}
