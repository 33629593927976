import {
    Pipe,
    PipeTransform
  } from '@angular/core';
import { UserStatus } from '~app/models/UserStatus';
  
  @Pipe({
    name: 'userStatusFa'
  })
  export class UserStatusFaPipe implements PipeTransform {
  
    transform(name: string | null): string {
      if(!name){
        return '';
      }
      switch (name.toLowerCase()) {
        case UserStatus.Deactive.toLowerCase():
          return 'غیرفعال';
          case UserStatus.Active.toLowerCase():
          return 'فعال'
        default:
          return 'تعریف نشده'
      }
    }
  }