import { NgModule } from '@angular/core';
import {  AppCorkDeleteDialogComponent 
} from './index';
import { ButtonModule } from '../buttons/button.module';

  @NgModule({
    imports: [
      ButtonModule
    ],
    declarations: [
      AppCorkDeleteDialogComponent,
    ],
    providers: [
    ],
    exports: [
      AppCorkDeleteDialogComponent,
    ]
  })
  export class DialogModule { }