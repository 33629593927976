<div class="col-lg-12 col-12 idkSpacing">
    <div class="statbox widget box box-shadow">
        <div class="widget-header">
            <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
                        <h4 *ngIf="isAddMode">ثبت حساب بانکی جدید</h4>
                        <h4 *ngIf="!isAddMode">ویرایش حساب بانکی</h4>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
                        <ck-btn-cancel routerLink="/admin/bankaccounts"></ck-btn-cancel>
                    </div>
            </div>
        </div>
        <div class="widget-content widget-content-area">
            

            <form [formGroup]="frmBank" (ngSubmit)="onBankSubmit($event)" novalidate>
            
                
                
               <div class="form-row" *ngIf="isAdmin" id="userContainer">
                    <div class="form-group col-md-6">
                          <!--Using ng-option and for loop-->
                          <ng-select [(ngModel)]="selectedUserId" [ngModelOptions]="{standalone: true}"
                          placeholder="مشتری"  *ngIf="isAdmin"
                          class="basic placeholder js-states select2"
                          notFoundText="یافت نشد">
                              <ng-option *ngFor="let row of customers; index as i;" [value]="row.id">{{ row.fullName }} {{row.mobile}} {{row.email}}</ng-option>
                          </ng-select>
                    </div>
                </div>

               <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="bankName">نام بانک</label>
                        <input type="text" class="form-control" 
                        formControlName="bankName" name="bankName" id="bankName" placeholder="نام بانک">
                    </div>
                </div>

                <div class="form-row">
                     <div class="form-group col-md-6">
                         <label for="hesab">حساب</label>
                         <input type="text" class="form-control" 
                         formControlName="hesab" name="hesab" id="hesab" placeholder="حساب">
                     </div>
                 </div>

                 <div class="form-row">
                      <div class="form-group col-md-6">
                          <label for="cart">کارت</label>
                          <input type="text" class="form-control" 
                          formControlName="cart" name="cart" id="cart" placeholder="کارت">
                      </div>
                  </div>

                  <div class="form-row">
                       <div class="form-group col-md-6">
                           <label for="sheba">شبا</label>
                           <input type="text" class="form-control" 
                           formControlName="sheba" name="sheba" id="sheba" placeholder="شبا">
                       </div>
                   </div>

                   <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="sahebHesab">صاحب حساب</label>
                            <input type="text" class="form-control" 
                            formControlName="sahebHesab" name="sahebHesab" id="sahebHesab" placeholder="صاحب حساب">
                        </div>
                    </div>
                
                
                <div class="form-group col-md-6" *ngIf="isAdmin">
                    <div class="form-check pl-0">
                        <div class="custom-control custom-checkbox checkbox-info">
                            <input type="checkbox" class="custom-control-input"
                             name="isEditable"formControlName="isEditable" id="isEditable">
                            <label class="custom-control-label" for="isEditable">قابل ویرایش؟</label>
                        </div>
                    </div>
                </div>

    
                <ck-btn-save [disabled]="frmBank.pristine || frmBank.invalid && (frmBank.dirty || frmBank.touched)">ذخیره حساب بانکی</ck-btn-save>
            </form>

        </div>
    </div>
</div>