import {
    Pipe,
    PipeTransform
  } from '@angular/core';

  @Pipe({
    name: 'yesNo'
  })
  export class YesNoPipe implements PipeTransform {

    transform(yesNo: boolean | null): string {
      if (yesNo == undefined) {
        return '';
      }
       if (yesNo == true) {
        return 'بله';
       }
       return 'خیر';
    }
  }
