import { Component, AfterViewInit, ViewChild, ChangeDetectorRef, OnInit, AfterViewChecked, Input } from '@angular/core';

import { Router } from '@angular/router';

import { AuthService } from '~services/auth.service';
import { BankAccountService } from '~app/services/bankaccount.service';

import * as baseModel from '~app/models';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~utils/messages';
import { BankAccountGetListRequest, BankAccountViewModel } from '~app/models/bankAccount';


@Component({
    selector: 'app-bank-list.row , app-bank-list',
    templateUrl: './bankaccount.component.html',
    styleUrls: ['./bankaccount.component.scss'],
    providers: [BankAccountService]
  })
  export class BankAccountComponent implements AfterViewInit, OnInit, AfterViewChecked {
    public isAdmin = false;
    public pageSize = 20;
    public resultsLength = 0;
    public page = 0 ;
    public isLoading = false;
    public isTotalReached = false;
    public totalItems = 0;
    public key = '';
    public customerId = null;
    public bankaccounts: BankAccountViewModel[] = [];
    constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private bankAccountService: BankAccountService,
      private authService: AuthService,
      private router: Router,
      private toastr: ToastrService
    ) { }

    ngOnInit() {
      this.isAdmin = this.authService.isAdmin();
    }

    ngAfterViewInit() {
      this.getData();
    }

    ngAfterViewChecked() {
      this.changeDetectorRef.detectChanges();
    }
    getData(request ?: BankAccountGetListRequest): void {
      if(request == undefined || request == null){
        request = new BankAccountGetListRequest();
        request.pageSize = this.pageSize;

        if(this.key){
          request.key = this.key;
        }
      }
      this.bankAccountService.getList(request).subscribe((x) => {
        if (x.isSuccess) {
          this.bankaccounts = x.list;
          this.totalItems = x.totalItems;
          this.page = x.currentPage;
          this.isLoading = true; 
        }
      }, (err) => {
        this.isLoading = true;
      });
    }


    create(): void {
      this.router.navigate(['bankaccounts/add']);
    }

    edit(id: number): void {
      this.router.navigate(['bankaccounts/edit/' + id]);
    }

    lock(id: number): void {
        const request = new baseModel.UpdateRequest();
        request.id = id;

        this.bankAccountService.lock(request).subscribe((data) => {
            if (data.isSuccess) {
                this.toastr.success(MESSAGES.backaccount.lock.success);
            }
        }, (err) => {
            this.toastr.error(MESSAGES.backaccount.lock.error);
        });
    }
    unlock(id: number): void {
        const request = new baseModel.UpdateRequest();
        request.id = id;

        this.bankAccountService.unlock(request).subscribe((data) => {
            if (data.isSuccess) {
                this.toastr.success(MESSAGES.backaccount.unlock.success);
            }
        }, (err) => {
            this.toastr.error(MESSAGES.backaccount.unlock.error);
        });
    }

    changeLock(id: number , isEditable: boolean) {
      if (isEditable) {
        this.lock(id);
      } else {
        this.unlock(id);
      }
    }

  public delete(id: number[]) {
    const request = new baseModel.DeleteRequest();
    request.id = id;

    console.log(request);

    this.bankAccountService.delete(request).subscribe((data) => {

      if (data.isSuccess) {
        this.toastr.success(MESSAGES.backaccount.delete.success);

        request.id.forEach(u => {
          const founded = this.bankaccounts.findIndex(x => x.id === u);
          this.bankaccounts.splice(founded, 1);
        });

      }
    }, (err) => {
      this.toastr.error(MESSAGES.backaccount.delete.error);
    });
  }

  isEditDisabled(row: BankAccountViewModel) {
    if (this.isAdmin) {
      return false;
    } else if (!row.isEditable) {
      return true;
    }
  }

  }
