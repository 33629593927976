import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterViewChecked, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { BankAccountService } from '~services/bankaccount.service';
import * as bankAccountModels from '~app/models/bankaccount';
import { AuthService } from '~app/services/auth.service';
import { UserService } from '~app/services/user.service';
import * as userModels from '~app/models/user/index';
import { BankAccountViewModel,
   BANK_ACCOUNT_TYPES , BANK_NAMES} from '~app/models/bankAccount/index';
import * as baseModels from '~app/models';
import { UserViewModel } from '~app/models/user/index';


import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~utils/messages';


@Component({
  selector: 'app-bank-forms.row',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements AfterViewInit, OnInit , AfterViewChecked, OnDestroy {
    frmBank: FormGroup;
    submitted = false;
    public id ?: number;
    public isAddMode = false;
    public isLoading = false;
    public isCustomerLoading = false;
    public isAdmin = false;
    public bankAccount: BankAccountViewModel = {};
    public customers: UserViewModel[] = [];
    public bankTypesOptions: string[];
    public bankNamesOptions: string[];
    selectedUserId:number;
    constructor(
        private formBuilder: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,

        private authService: AuthService,
        private bankAccountService: BankAccountService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService

      ) { }
      ngOnDestroy(): void {
      }

      ngOnInit() {

        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;

        this.isAdmin = this.authService.isAdmin();
        
        this.initializeForm();


        this.bankNamesOptions = BANK_NAMES.map(x => x.value);
        this.bankTypesOptions = BANK_ACCOUNT_TYPES.map(x => x.value);

        if (this.isAddMode) {
          this.bankAccount.isEditable = true;
        }


      }
      get f() { return this.frmBank.controls; }
      initializeForm() {
        const IS_EDITING = !this.isAddMode;
        console.log(IS_EDITING);
        this.frmBank = this.formBuilder.group({
          id: new FormControl(IS_EDITING ? this.bankAccount.id : null),
          userId: new FormControl(IS_EDITING ? this.bankAccount.userId : null),
          isEditable: new FormControl(IS_EDITING ? this.bankAccount.isEditable : null),
          bankName:  new FormControl(IS_EDITING ? this.bankAccount.bankName : null),
          cart:  new FormControl(IS_EDITING ? this.bankAccount.cart : null),
          hesab:  new FormControl(IS_EDITING ? this.bankAccount.hesab : null),
          sahebHesab:  new FormControl(IS_EDITING ? this.bankAccount.sahebHesab : null),
          sheba:  new FormControl(IS_EDITING ? this.bankAccount.sheba : null),
        });
      }

      ngAfterViewInit() {
        if (this.isAdmin) {
          this.getCustomers();
        }


         if (!this.isAddMode) {
          this.getBankAccountItem();
        }
      }

      getBankAccountItem() {
        this.isLoading = true;
        const request = new baseModels.GetItemRequest();
        request.id = this.id;

        this.bankAccountService.getOne(request).subscribe((data) => {
          if (data.isSuccess) {
            this.bankAccount = data.item;
            this.isLoading = false;
            this.initializeForm();
            this.selectedUserId = data.item.userId;
          }
        });
      }

      ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
      }

      getCustomers(): void {
        this.isCustomerLoading = true;
        const request = new userModels.SearchUserRequest();
        request.pageSize = 999999;
        this.userService.getList(request).subscribe((data) => {
          if (data.isSuccess) {
            this.customers = data.list;
            this.isCustomerLoading = false;
            this.initializeForm();
          }
        }, () => {

        });
      }

      lock() {
        const request = new baseModels.UpdateRequest();
        request.id = this.id;
        this.bankAccountService.lock(request).subscribe((data) => {

          if (data.isSuccess) {
              this.toastr.success(MESSAGES.backaccount.lock.success);
          }
        }, () => {
          this.toastr.error(MESSAGES.backaccount.lock.error);
        });
      }
      unlock() {
        const request = new baseModels.UpdateRequest();
        request.id = this.id;
        this.bankAccountService.unlock(request).subscribe((data) => {
          if (data.isSuccess) {
            this.toastr.success(MESSAGES.backaccount.unlock.success);
          }
        }, () => {
          this.toastr.error(MESSAGES.backaccount.unlock.error);

        });
      }

      changeLock() {
        if (this.isAdmin) {
          if (this.bankAccount.isEditable) {
            this.lock();
          } else {
            this.unlock();
          }
        } else {
          this.toastr.error(MESSAGES.backaccount.lockPermission.error);
        }
      }

  public create(value: bankAccountModels.BankAccountCreateRequest) {
    const request = {...value};
    request.userId = Number(request.userId);
    this.bankAccountService.create(request).subscribe((data) => {

      if (data.isSuccess) {
        this.toastr.success(MESSAGES.backaccount.create.success);
        this.router.navigate(['/admin/bankaccounts']);
      }
    }, () => {
      this.toastr.error(MESSAGES.backaccount.create.error);
    });
  }

  public update(value: bankAccountModels.BankAccountUpdateRequest) {
    const request = {...value};
    request.id = Number(this.id);
    request.userId = Number(request.userId);
    this.bankAccountService.update(request).subscribe((data) => {
      if (data.isSuccess) {
        this.toastr.success(MESSAGES.backaccount.update.success);
        this.router.navigate(['admin/bankaccounts']);
      }
    }, () => {
      this.toastr.error(MESSAGES.backaccount.update.error);
    });
  }

  public delete() {
    const request = new baseModels.DeleteRequest();
    request.id.push(this.id);

    this.bankAccountService.delete(request).subscribe((data) => {

      if (data.isSuccess) {
        this.toastr.success(MESSAGES.backaccount.delete.success);
        this.router.navigate(['admin/bankaccounts']);
      }
    }, () => {
      this.toastr.error(MESSAGES.backaccount.delete.error);
    });
  }


  onBankSubmit(event: any) {
    this.frmBank.controls['userId'].setValue(Number(this.selectedUserId));
    event.preventDefault();
    this.submitted = true;

    if (this.frmBank.valid) {
      if (this.isAddMode) {
        this.create(this.frmBank.value);
      } else {
        this.update(this.frmBank.value);
      }
    }

  }
}
