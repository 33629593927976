import { Pager } from '~modules/ng-pager/pager.model';

export class GetListRequest extends Pager {
    /**
     *
     */
    key?:string;
    constructor(currentPage?:number,pageSize?:number,key?:string) {
        super();

        if(currentPage==undefined){
            currentPage = 0;
        }
        if(pageSize==undefined){
            pageSize = 10;
        }

        this.pageIndex = currentPage;
        this.pageSize = pageSize;
        this.key = key;
    }
}
