import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable } from 'rxjs';

import {UpdateResponse , CreateResponse , GetItemRequest ,
     DeleteRequest , DeleteResponse, GetListResponse, GetItemResponse, GetListRequest } from '~models/index';

import { AuthService } from './auth.service';
import { UpdateRequest } from '~app/models/UpdateRequest';
import { BaseResponse } from '~app/models/BaseResponse';


import {  OrderGetListRequest,
    OrderViewModel } from '~models/orders';
import { UtilitiesService } from './utilities.service';

@Injectable()
export class OrderService {
  loading = true;

  constructor(
    private http: HttpClient,
    private utilitiesService: UtilitiesService
  ) { }

  getList(request ?: OrderGetListRequest):
  Observable<GetListResponse<OrderViewModel>> {

    let params = this.utilitiesService.listToParams(request);
    if(request){
      if (request.customerId) {
        params = params.append('customerId', request.customerId?.toString());
      }

      if (request.key) {
        params = params.append('key', request.key?.toString());
      }
      if (request.fromDate) {
        params = params.append('fromDate', request.fromDate?.toString());
      }
      if (request.toDate) {
        params = params.append('toDate', request.toDate?.toString());
      }
      if (request.variantId) {
        params = params.append('variantId', request.variantId?.toString());
      }
      if (request.code) {
        params = params.append('code', request.code?.toString());
      }
      
    }

    return this.http.get<GetListResponse<OrderViewModel>>(
      CONSTANST.routes.orders.list,
      { 
         params: params
        }
    );
  }
  
  getOne(request: GetItemRequest): Observable<GetItemResponse<OrderViewModel>> {
    return this.http.get<GetItemResponse<OrderViewModel>>(
      CONSTANST.routes.orders.get.replace(':id', request.id.toString()),
      
    );
  }
}
