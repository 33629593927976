import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../utils/shared.module';

import { ProductComponent } from './product.component';
import { FormComponent } from './form/form.component';
import { PipesModule } from '~app/pipes/pipes.module';

@NgModule({
    imports: [
      RouterModule.forChild([
        {path: '', component: ProductComponent},
        {path: 'add', component: FormComponent},
        {path: 'edit/:id', component: FormComponent}
    ]),
      SharedModule
    ],
    declarations: [
        ProductComponent,
        FormComponent
    ],
    providers: [],
    entryComponents: [
        FormComponent
    ],
    exports: [
      RouterModule,
    ]
  })
  export class ProductModule { }
