import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, AfterViewChecked, SimpleChanges, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { LicenseService } from '~services/license.service';
import { AuthService } from '~app/services/auth.service';

import * as licenseModel from '~app/models/license';
import * as baseModel from '~app/models';
import * as userModels from '~app/models/user/index';


import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~app/utils/messages';
import { UserViewModel } from '~app/models/user/UserViewModel';
import { UserService } from '~app/services/user.service';
import { ProductViewModel } from '~app/models/product';
import { ProductService } from '~app/services/product.service';
import { VariantViewModel } from '~app/models/productVariants/VariantViewModel';
import { LicenseViewModel } from '~app/models/license';

@Component({
  selector: 'app-forms.row',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements AfterViewInit, OnInit , AfterViewChecked {

  public frm: FormGroup;
  submitted = false;
  isLicenseInEdit = false;
  closeDialog = true;
  licenses: licenseModel.LicenseViewModel[] = [];
  inEditLicense: licenseModel.LicenseViewModel;
  public isActive: true;
    public id ?: number;
    public isAddMode = false;
    public isDetailAddMode = false;
    public isLoading = false;
    public isCustomerLoading = false;
    public isProductsLoading = false;
    public isAdmin = false;
    public license: licenseModel.LicenseViewModel;
    public customers: UserViewModel[] = [];
    public licenseStatusOptions: string[];
    public prooducts: ProductViewModel[] = [];
    public variants: VariantViewModel[] = [];

    selectedUserId: number;
    selectedProductVariantId: number;
    detailId: number;


    constructor(
        private changeDetectorRef: ChangeDetectorRef,

        private authService: AuthService,
        private licenseService: LicenseService,
        private router: Router,
        private route: ActivatedRoute,
        private _location: Location,
        private userService: UserService,
        private toastr: ToastrService,
        private productService: ProductService,
        private elementRef: ElementRef,
        private formBuilder: FormBuilder

      ) { }

      ngOnInit() {
        const temp = {} as any;
        this.license = temp;

        this.id = this.route.snapshot.params['id'];
        console.log(this.id);
        this.isAddMode = !this.id;

        this.isAdmin = this.authService.isAdmin();
        this.licenseStatusOptions = licenseModel.LICENSE_STATUS_OPTIONS.map(x => x.value);
        this.initializeForm();
      }

      ngAfterViewInit() {
        if (this.isAdmin) {
          this.getCustomers();
        }
         if (!this.isAddMode) {
          this.getLicenseItem();
        }
        this.getProducts();
      }

      getCustomers(): void {
        this.isCustomerLoading = true;
        const request = new userModels.SearchUserRequest();
        request.pageSize = 999999;
        this.userService.getList(request).subscribe((data) => {
          if (data.isSuccess) {
            this.customers = data.list;
            this.isCustomerLoading = false;
            this.initializeForm();
          }
        }, (err) => {

        });
      }


      getProducts(): void {
        this.isProductsLoading = true;
        this.productService.getList().subscribe((x) => {
          if (x.isSuccess) {
            this.prooducts = x.list;
            this.isProductsLoading = false;

            for (let index = 0; index < x.list.length; index++) {
              const p = x.list[index];

              for (let j = 0; j < p.variants.length; j++) {
                const v = p.variants[j];

                v.variantFullName = p.name + ' ' + v.variantName;

                this.variants.push(v);
              }
            }


            this.initializeForm();

          }
        }, (err) => {

        });
      }

      getLicenseItem(id?: number) {
        this.isLoading = true;
        const request = new baseModel.GetItemRequest();

        if (id) {
          request.id = id;
        } else {
          request.id = this.id;
        }

        this.licenseService.getOne(request).subscribe((data) => {
          if (data.isSuccess) {
            this.license = data.item;
            this.isLoading = false;
            this.initializeForm();
          }
        });
      }

      ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
      }

      private initializeForm() {
        const IS_LICENSE_EDITING = !this.isAddMode;
        this.frm = this.formBuilder.group({
        battleTag: new FormControl(IS_LICENSE_EDITING ? this.license.battleTag : null),
        description: new FormControl(IS_LICENSE_EDITING ? this.license.description : null),
        userId: new FormControl(IS_LICENSE_EDITING ? this.license.userId : null),
        code: new FormControl(IS_LICENSE_EDITING ? this.license.code : null),
        productVariantId:  new FormControl(IS_LICENSE_EDITING ? this.license.productVariantId : null),
        isSold:  new FormControl(IS_LICENSE_EDITING ? this.license.isSold : false),
        licenseStatus:  new FormControl(IS_LICENSE_EDITING ? this.license.licenseStatus : null),
        id: new FormControl(IS_LICENSE_EDITING ? this.license.id : null),
        isForSale: new FormControl(IS_LICENSE_EDITING ? this.license.isForSale : null),
      });

      this.selectedProductVariantId = this.license.productVariantId;
      this.selectedUserId = this.license.userId;
      }



      public createLicense(value: licenseModel.LicenseCreateRequest): void {
        const request = {...value};
        request.userId = Number(this.selectedUserId);
        request.productVariantId = Number(this.selectedProductVariantId);

        this.licenseService.create(request)
        .subscribe((data) => {
          if (data.body.isSuccess) {
            const vm: LicenseViewModel = {...request};
            vm.id = data.body.id;
            if (!this.licenses) {
              this.licenses = [];
            }
            this.licenses.push(vm);

            this.isAddMode = false;
            this.toastr.success(MESSAGES.license.create.success);
          }
        }, (data) => {
            console.error(data);
            this.toastr.error(MESSAGES.license.create.error);
        })
        ;
      }
      public closeVariant() {
        this.closeDialog = true;
      }

      public updateLicense(value: licenseModel.LicenseUpdateRequest): void {
        const request = {...value};
        request.userId = Number(request.userId);
        request.productVariantId = Number(request.productVariantId);

        this.licenseService.update(request).subscribe((data) => {
          if (data.isSuccess) {
            this.toastr.success(MESSAGES.license.update.success);
            let item = this.licenses.find(x => x.id == request.id);
            item = request;
          }
        }, (err) => {
          this.toastr.error(MESSAGES.license.update.error);
        });
      }

    onLicenseSubmit(event: any) {
      this.frm.controls['productVariantId'].setValue(Number(this.selectedProductVariantId));
      this.frm.controls['userId'].setValue(Number(this.selectedUserId));

    event.preventDefault();
    this.submitted = true;
      if (this.frm.valid) {
        if (!this.isLicenseInEdit && this.isAddMode) {
          this.createLicense(this.frm.value);
        } else {
          this.updateLicense(this.frm.value);
        }
        this.clearLicenseForm();
        this.closeVariant();
      }
    }

    get f() { return this.frm.controls; }


  public getNameErrorMessage() {
      return this.frm.controls.name.hasError('required') ? 'عنوان الزامی است' :
      this.frm.controls.name.hasError('minlength') ? 'حداقل ۲ کاراکتر بنویسید' : '';
  }

  clearLicenseForm(): void {
    this.isLicenseInEdit = false;
    const data = {} as any;
    this.inEditLicense = data;
  }
  public editLicense(id: number) {
    const request = new baseModel.GetItemRequest();
    request.id = id;
    this.inEditLicense = this.licenses.find(x => x.id == id);
    this.isLicenseInEdit = true;
    this.closeDialog = false;
  }
  public deleteLicense(id ?: number) {
    const request = new baseModel.DeleteRequest();
    request.id = [];
    request.id.push(Number(id));

    this.licenseService.delete(request).subscribe((data) => {

      if (data.isSuccess) {
        this.toastr.success(MESSAGES.license.delete.success);

        const index = this.licenses.findIndex(x => x.id == id);
        this.licenses.splice(index, 1);
      }
    }, () => {
      this.toastr.error(MESSAGES.license.delete.error);
    });
  }



  addNewLicense(): void {
    this.isLicenseInEdit = false;
    this.clearLicenseForm();
    this.closeDialog = false;
  }

  groupLicenseAction():void{
    this.router.navigate(['/licenses/bulk']);
  }

}
