import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GetListRequest, DeleteRequest } from '~models/index';
import { LicenseGroupViewModel, LicenseViewModel } from '~app/models/license';


@Injectable()
export class UtilitiesService {
    constructor(
      ) { }

      listToParams(request?: GetListRequest): HttpParams {
        let params = new HttpParams();
        if(!request){
          return params;
        }
        if (request.currentPage) {
          params = params.append('currentPage', request.currentPage?.toString());
        }
        if (request.pageSize) {
          params = params.append('pageSize', request.pageSize?.toString());
        }

        return params;
      }
      deleteToParams(request: DeleteRequest): HttpParams {
        let params = new HttpParams();

        for (let index = 0; index < request.id.length; index++) {
          const element = request.id[index];
          params = params.append('id', element.toString());
        }

        return params;
      }

      longToDate(date:any) : string{
        return new Date(new Date(new Date(date)).toString().split('GMT')[0] + ' UTC').toISOString();
      }





      
  groupLicenses(list:LicenseViewModel[]):LicenseGroupViewModel[]{
    let groupedLicenses:LicenseGroupViewModel[] = [];
    if(list.length==0){
      return groupedLicenses;
    }

    let uniqBulkIdArray = new Array<string>();
    let unOrdered = list.map(x=>x.bulkOrderId);
    for (let j = 0; j < unOrdered.length; j++) {
      const id = unOrdered[j];
      
      if(uniqBulkIdArray.findIndex(x=>x == id)==-1){
        uniqBulkIdArray.push(id);
      }
    }

    
    for (let i = 0; i < uniqBulkIdArray.length; i++) {
      const uniqGroupId = uniqBulkIdArray[i];
      let uniqGroup = list.filter(x=>x.bulkOrderId==uniqGroupId);

      let grouped = new LicenseGroupViewModel();
      grouped={};
      grouped={licenses:[]}
      for (let j = 0; j < uniqGroup.length; j++) {
        const element = uniqGroup[j];
        grouped.licenses.push(element);


        const item = grouped.licenses[0];
  
        let groupPrice =0;
        for (let i = 0; i < grouped.licenses.length; i++) {
          const element = grouped.licenses[i];
          groupPrice += element.priceInOrderDate; 
        }
    
        grouped.groupcreatedAt = item.createdAt;
        grouped.groupPrice = groupPrice;
        grouped.groupCount =  grouped.licenses.length;
        grouped.groupProductName =  item.productVariant.product.name+ ' ' + item.productVariant.variantName;
        grouped.groupUser = item.user.fullName;
        grouped.groupId = item.bulkOrderId;
        grouped.groupUserId = item.userId;
        grouped.groupProduct = item.product;
        grouped.groupIsForSale = item.isForSale;
        grouped.groupIsSold = item.isSold;
        grouped.groupPriceInOrderDate = item.priceInOrderDate;
        grouped.groupProductVariantId = item.productVariantId;
        grouped.groupDescription = item.description;
        grouped.groupStatus = item.licenseStatus;


      }

      groupedLicenses.push(grouped);
    }
    return groupedLicenses;
  }

}
