<!--main content-->
<div class="aupContent">
  <div class="card mb-4 border-0">
    <div class="card-header py-3">
      <h6 class="m-0">پنل کاربری</h6>
    </div>
    <div class="card-body">
      <!--Start-- content of each menus goes here-->

      <div class="row">
        <div class="col-6">
          <p>
            خوش آمدید <strong>{{ user.name }}</strong>
          </p>
        </div>

        <div class="col-6" *ngIf="isSeller">
          <div class="row">
            <div class="col-6" style="text-align: right;">
              سطح کاربری : {{ user.userLevel | userLevelFa }}
            </div>
            <div class="col-6" style="text-align: left;">
              <button class="pointer" (click)="userLevelInfo.open()">
                ارتقاء سطح کاربری
              </button>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6" style="display: block">
          <div class="wupp-order-count-v1">
            <div class="title-conut-v1">
              <a routerLink="/customer/orders"
                ><h4 style="letter-spacing: -4px; font-size: 32px !important">
                  {{ stats.customerTotalOrders }}
                </h4>
                <span>کل مبلغ سفارشات</span></a
              >
            </div>
            <div class="icon-conut-v1">
              <i class="fa fa-money"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6" style="display: block">
          <div class="wupp-ticket-count-v1">
            <div class="title-conut-v1">
              <a routerLink="/customer/tickets"
                ><h4>{{ stats.customerTotaTickets }}</h4>
                <span>تیکت های پشتیبانی</span></a
              >
            </div>
            <div class="icon-conut-v1">
              <i class="fa fa-support"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isSeller">
        <div class="col-lg-6 col-md-6" style="display: block">
          <div class="wupp-order-count-v1" style="background-color: #5137bb">
            <div class="title-conut-v1">
              <a routerLink="/customer/tickets/licenses"
                ><h4 style="letter-spacing: -4px; font-size: 32px !important">
                  {{ stats.totalLicenesSoldToUs }}
                </h4>
                <span>کل کدهای فروخته شده به ما</span></a
              >
            </div>
            <div class="icon-conut-v1">
              <i class="fa fa-money"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6" style="display: block">
          <div class="wupp-ticket-count-v1" style="background-color: #bcb236">
            <div class="title-conut-v1">
              <a routerLink="/customer/tickets/variz"
                ><h4>{{ stats.varizTotalPrice }}</h4>
                <span>مجموع واریزی ها</span></a
              >
            </div>
            <div class="icon-conut-v1">
              <i class="fa fa-support"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Big content modal -->
<ngx-smart-modal
  #userLevelInfo
  identifier="userLevelInfo"
  ariaLabel="User Level Info"
>
 
<div style="text-align: justify; direction: rtl">
  <h2>سیاست سطح کاربری</h2>
  <!-- <img class="modal-image"
       src="https://placeimg.com/627/200/tech"
       alt="Lorem picsum"> -->
       <br />
  <p>
    <b>سطح برنزی :</b>
    <br />
    اولین سطح فروشندگی در ایران وارکرفت ؛ سطح برنزی میباشد شما بعد از ثبت
    درخواست فروشندگی در پنل کاربری ؛ بعد از تایید به این سطح وارد میشوید.
  </p>

  <p>
    <b>سطح نقره ای :</b>
    <br />
    بعد از تایید و دسترسی به سطح برنزی ؛ با احراز هویت اکانت خود میتوانید به
    سطح نقره ای برسید. در این مرحله شما باید موارد زیر را از قسمت حساب کاربری
    تایید نمایید :
    <br />
    <b>ایمیل</b>
    <br />
    <b>شماره موبایل</b>
    <br />
    <b>شماره ثابت</b>
    <br />
    <b>ارسال عکس کارت ملی</b>
  </p>
  <p>
    پس از ارسال موارد فوق و تایید آنها شما میتوانید محصولات خود را در سایت
    ایران وارکرفت بارگذاری نمایید در سطح نقره ای واریزی های شما از سمت ایران
    وارکرفت به فاصله 3 روز کاری انجام میشود.
  </p>

  <p>
    <b>سطح طلایی :</b>
    <br />
    در این سطح تمامی واریزی ها از سمت ایران وارکرفت و به هر مقدار طی 1 روز کاری برای شما ارسال میشود. برای دسترسی به سطح طلایی ؛ مجموع کد های آپلود شده از سمت شما باید به یکی از 2 صورت زیر باشد : 
    <br />
    1- ارسال کد برای ایران وارکرفت به تعداد مجموعا 50 عدد ( شما میتوانید این تعداد را یکجا ارسال نمایید )
    <br />
    2- شما میتوانید با 3 بار ارسال کد به ایران وارکرفت ( حداقل 5 کد در هر بار ) به سطح طلایی برسید.
  </p>
</div>


  <button class="button -dark" (click)="userLevelInfo.close()">بستن</button>
</ngx-smart-modal>
