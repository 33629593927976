import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '~services/auth.service';
import { AuthenticateResponse } from '~app/models/Auth/AuthenticateResponse';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: []
})

export class LoginComponent implements OnInit {
  public form: FormGroup;
  public isLogin = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.authService.getToken()) {
      this.router.navigate(['/']);
    }

    this.initLoginForm();
  }

  private initLoginForm(): void {
    this.form = this.fb.group({
      user_name: [
        null,
        [
          // Validators.required,
          // Validators.minLength(3),
          // Validators.maxLength(20)
        ]
      ],
      password: [
        null,
        [
          // Validators.required,
          // Validators.minLength(6),
          // Validators.maxLength(12)
        ]
      ]
    });
  }

  public isFieldInvalid(field: string) {
    if (this.form.get(field).touched) {
      return !this.form.get(field).valid;
    }
  }

  public login() {
    if (this.form.valid) {
      this.isLogin = true;
      this.authService.login(this.form.value).subscribe(
        (data: any) => {
          this.isLogin = false;
          if (data.success) {
            this.authService.loggedIn.next(true);
            localStorage.setItem('Token', data.token);
            this.router.navigate(['/']);
          } else {
           
          }
        },
        (error) => {
          console.log(error);
          this.isLogin = false;
        }
      );
    }
  }

  public fakeAdminLogin() {
      this.isLogin = true;
      this.authService.fakeAdmin().subscribe(
        (data: AuthenticateResponse) => {
          this.isLogin = false;
          if (data.isSuccess) {
            this.authService.loggedIn.next(true);
            this.authService.setLogin(data);
            this.router.navigate(['/admin']);
          } else {
            
          }
        },
        (error) => {
          console.log(error);
          this.isLogin = false;
        }
      );
  }
  public fakeBuyerLogin() {
    this.isLogin = true;
    this.authService.fakeBuyer().subscribe(
      (data: AuthenticateResponse) => {
        this.isLogin = false;
        if (data.isSuccess) {
          this.authService.loggedIn.next(true);
          this.authService.setLogin(data);
          this.router.navigate(['/customer']);
        } else {
          
        }
      },
      (error) => {
        console.log(error);
        this.isLogin = false;
      }
    );
}
public fakeSellerLogin() {
  this.isLogin = true;
  this.authService.fakeSeller().subscribe(
    (data: AuthenticateResponse) => {
      this.isLogin = false;
      if (data.isSuccess) {
        this.authService.loggedIn.next(true);
        this.authService.setLogin(data);
        this.router.navigate(['/customer']);
      } else {
       
      }
    },
    (error) => {
      console.log(error);
      this.isLogin = false;
    }
  );
}


}
