import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, AfterViewChecked, SimpleChanges, NgZone, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import * as varizModel from '~models/variz/index';

import { AuthService } from '~app/services/auth.service';
import { UserService } from '~app/services/user.service';
import { DeleteRequest } from '~app/models';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~app/utils/messages';
import { VarizService } from '~app/services/variz.service';
import { UserViewModel } from '~app/models/user/UserViewModel';
import { Pager } from '~modules/ng-pager/pager.model';


@Component({
  selector: 'app-forms.row',
  templateUrl: './variz.component.html'
})
export class VarizComponent implements AfterViewInit, OnInit, AfterViewChecked {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private varizService: VarizService,
    private userService: UserService,
    private toastr: ToastrService,

  ) { }
  pager: Pager;
  public pageSize = 10;
  public currentPage = 0;
  public page = 0;
  public totalItems = 0;

  isSubmitted = false;
  public userId?: number;
  public key?: string;
  public isAddMode = false;
  public isLoading = false;
  public isAdmin = false;
  customerCtrl = new FormControl();
  public customers: UserViewModel[] = [];
  varizes: varizModel.VarizViewModel[] = [];
  isCustomerLoading = false;
  minPayDate: string | null;
  maxPayDate: string | null;

  ngOnInit() {
    this.isAdmin = this.authService.isAdmin();
  }
  ngAfterViewInit() {
    if (this.isAdmin) {
      this.getCustomers();

    }
    this.getData();
  }
  getData(request?: varizModel.VarizSearchRequest) {

    if (request == undefined) {
      request = new varizModel.VarizSearchRequest();
      request.pageSize = this.pageSize;
    }

    if (this.maxPayDate != undefined) {
      request.maxPayDate = new Date(this.maxPayDate);
    }
    if (this.minPayDate != undefined) {
      request.minPayDate = new Date(this.minPayDate);
    }

    if (this.userId != undefined) {
      request.userId = Number(this.userId);
    }
    if(this.key!= undefined){
      request.key = this.key;
    }

    this.isLoading = true;
    this.varizService.getList(request).subscribe((x) => {
      if (x.isSuccess) {
        this.varizes = x.list;
        this.isLoading = false;

        this.totalItems = x.totalItems;
        this.currentPage = request == undefined ? 0 : request.pageIndex;
        this.pager = new Pager(x.totalItems, this.currentPage, this.pageSize);
        this.isLoading = false;
      }
    }, (err) => {
      this.isLoading = false;
    });
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  getCustomers(): void {
    this.isCustomerLoading = true;
    this.userService.getList().subscribe((x) => {
      if (x.isSuccess) {
        this.customers = x.list;
        this.isCustomerLoading = false;

        this.totalItems = x.totalItems;
        this.page = x.currentPage;
      }
    });
  }

  public onPaginateChange(event: any): void {
    this.page = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getData();
  }

  delete(id): void {
    const request = new DeleteRequest();
    request.id = [];
    request.id.push(id);

    this.varizService.delete(request).subscribe((data) => {
      this.toastr.success(MESSAGES.variz.delete.success);

      const index = this.varizes.findIndex(x => x.id === id);
      this.varizes.splice(index, 1);
    },
      (err) => {
        this.toastr.error(MESSAGES.variz.delete.error);
      });
  }
}
