import { EventEmitter, Input, Output, ViewContainerRef, Directive } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'ck-card-contianer',
    templateUrl: './card-container.component.html',
  })
export class AppCorkCardContainerComponent {
    @Input() isFirst = 'true';
  }
