<div class="AdvancedUserPanel">
  <nav class="aup-header navbar navbar-expand navbar-light bg-white">
    <div class="container-fluid">
      <div class="collapse navbar-collapse">
        <!-- support part -->
        <ul
          class="navbar-nav align-items-center ml-auto ml-md-2 d-none d-md-block supportPart"
        >
          <button
            type="button"
            data-toggle="collapse"
            data-target="#supportText"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            سوال مهم دارم
          </button>
          <div class="collapse" id="supportText" style="top: 4.375rem">
            <span></span>
            <div class="card card-body"><bdo dir="rtl">شماره پشتیبانی : </bdo><a href="tel:02177951928"><bdo dir="ltr">021-77951928</bdo></a></div>
          </div>
        </ul>
        <div class="aupMenu-mobile d-block d-md-none">
          <div id="wupp-close-overlay" class=""></div>
          <div class="dropdown show">
            <a
              routerLink="/customer"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-ellipsis-v"></i>
            </a>

            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuLink"
              id="mobiledropdown"
            >
              <div class="menu-user-panel-pro-container">
                <ul id="menu-user-panel-pro" class="menu">
                  <li
                    id="menu-item-186"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-186"
                  >
                    <a target="_blank" href="//iran-warcraft.com/تماس-با-ما/">تماس با ما</a>
                  </li>
                  <li
                    id="menu-item-184"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-184"
                  >
                    <a target="_blank" href="//iran-warcraft.com">صفحه نخست</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <span class="notice-bell"
          ><a
            routerLink="/customer"
            class="notice-bell-link"
            (click)="logout()"
          >
            <img
              src="/assets/img/logout-svgrepo-com.svg"
              alt="notification"
              width="30"
              height="35"
          /></a>
        </span>
        <!-- Navbar menus -->
        <div class="d-none d-md-block ml-md-auto">
          <div class="navbar-nav align-items-center aup-menu">
            <div class="menu-user-panel-pro-container">
              <ul id="menu-user-panel-pro-1" class="menu">
                <li
                  class="menu-item menu-item-type-custom menu-item-object-custom menu-item-186"
                >
                  <a target="_blank" href="//iran-warcraft.com/تماس-با-ما/">تماس با ما</a>
                </li>
                <li
                  class="menu-item menu-item-type-custom menu-item-object-custom menu-item-184"
                >
                  <a  target="_blank" href="//iran-warcraft.com">صفحه نخست</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="navbar-nav align-items-center ml-auto ml-md-5">
          <a class="d-none d-md-block" routerLink="/customer">
            <img src="/assets/img/logo.png" style="width: 100px" alt="ایران وارکرفت" />
          </a>
          <div class="d-block d-lg-none">
            <div class="menu-mobile-toggle item-button navbar-toggle-wrapper">
              <button class="navbar-toggle">
                <div class="bars">
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="container-fluid">
    <div class="row">
      <router-outlet class="d-none"> </router-outlet>

      <!--side bar contetn-->
      <div class="col-xl-3 col-lg-3 pr-0">
        <div class="navbar-expand-xs aupSidebar">
          <div class="aupCard userInfo mb-5">
            <!-- Card body -->
            <div class="card-body">

              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="menu-mobile-toggle svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" style="
                width: 20px;
                left: 20px;
                position: absolute;
                top: 15px;
                cursor: pointer;
            "><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
              

              <div
                class="user-image rounded-circle img-center img-fluid float-right shadow shadow-lg--hover"
                style="width: 85px; height: 85px"
              >
                <a routerLink="/customer/profile">
                  <img
                    alt=""
                    src="/assets/img/boy.png"
                    srcset="/assets/img/boy.png"
                    class="avatar avatar-85 photo"
                    width="85"
                    height="85"
                  />
                </a>
              </div>
              <div class="text-center float-right pt-3">
                <h6 class="title text-right">
                  <span class="d-block font-weight-bold mb-1">{{
                    user.name
                  }}</span>
                  <small class="font-weight-light text-black">
                    {{ user.createdAt | jalali }}
                    <br />
                    <a class="pointer" *ngIf="isSeller" (click)="userLevelInfo.open()">
                      : سطح کاربری
                      <br />
                      <b>{{ user.userLevel | userLevelFa }}</b>
                    </a>
                  </small>
                </h6>
              </div>
            </div>
            <!--        <a routerLink="/customer" class="userIcons rounded-circle"><i class="fa fa-cog"></i></a>-->
            <a routerLink="/customer" class="userIcons pointer rounded-circle"
              ><i class="fa fa-home"></i
            ></a>
            <a
              (click)="logout()"
              class="userIcons pointer rounded-circle ml-md-5 secound-bottom-logout"
              ><i class="fas fa-sign-out-alt"></i
            ></a>
          </div>
          <!--side bar menu part-->
          <div class="sidbarMenus">
            <ul class="navbar-nav" id="aupmenu">
              <li class="nav-item">
                <a class="nav-link" routerLink="/customer" title="داشبورد">
                  <i class="fa fa-tachometer"></i>

                  <span class="nav-link-text">داشبورد</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLink="/customer/orders"
                  title="سفارشات"
                >
                  <i class="fa fa-file-text-o"></i>

                  <span class="nav-link-text">سفارشات</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="isSeller">
                <a
                  class="nav-link"
                  routerLink="/customer/banks"
                  title="حساب بانکی"
                >
                  <i class="fa fa-file-text-o"></i>

                  <span class="nav-link-text">حساب بانکی</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="isSeller">
                <a
                  class="nav-link"
                  routerLink="/customer/variz"
                  title="واریزی ها"
                >
                  <i class="fa fa-file-text-o"></i>

                  <span class="nav-link-text">واریزی ها</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLink="/customer/profile"
                  title="حساب کاربری"
                >
                  <i class="fa fa-file-text-o"></i>

                  <span class="nav-link-text">حساب کاربری</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLink="/customer/licenses"
                  title="فروش به ایران وارکرافت"
                >
                  <span class="nav-link-text">فروش به ایران وارکرافت</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLink="/customer/tickets"
                  title="تیکت ها"
                >
                  <span class="nav-link-text">تیکت ها</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-menu-sidebar-overlay"></div>
</div>
<footer class="sticky-footer bg-white aupFooter">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-lg-5">
        <div class="aup-footersocial">
          <ul class="align-items-center ml-md-auto float-left"></ul>
        </div>
      </div>
      <div class="col-lg-4 d-none d-md-block">
        <span class="copyright">Copyright © Iran-Warcraft.com 2020 </span>
      </div>
      <div class="col-lg-3 d-none d-md-block"></div>
    </div>
  </div>
</footer>
<a class="back-button" routerLink="/customer/">
  <div class="arrow-wrap">
    <span class="arrow-part-1"></span>
    <span class="arrow-part-2"></span>
    <span class="arrow-part-3"></span>
  </div>
</a>
<div class="md-overlay"></div>

<!-- Big content modal -->
<ngx-smart-modal
  #userLevelInfo
  identifier="userLevelInfo"
  ariaLabel="User Level Info"
>
  <div style="text-align: justify; direction: rtl">
    <h2>سیاست سطح کاربری</h2>
    <!-- <img class="modal-image"
         src="https://placeimg.com/627/200/tech"
         alt="Lorem picsum"> -->
         <br />
    <p>
      <b>سطح برنزی :</b>
      <br />
      اولین سطح فروشندگی در ایران وارکرفت ؛ سطح برنزی میباشد شما بعد از ثبت
      درخواست فروشندگی در پنل کاربری ؛ بعد از تایید به این سطح وارد میشوید.
    </p>

    <p>
      <b>سطح نقره ای :</b>
      <br />
      بعد از تایید و دسترسی به سطح برنزی ؛ با احراز هویت اکانت خود میتوانید به
      سطح نقره ای برسید. در این مرحله شما باید موارد زیر را از قسمت حساب کاربری
      تایید نمایید :
      <br />
      <b>ایمیل</b>
      <br />
      <b>شماره موبایل</b>
      <br />
      <b>شماره ثابت</b>
      <br />
      <b>ارسال عکس کارت ملی</b>
    </p>
    <p>
      پس از ارسال موارد فوق و تایید آنها شما میتوانید محصولات خود را در سایت
      ایران وارکرفت بارگذاری نمایید در سطح نقره ای واریزی های شما از سمت ایران
      وارکرفت به فاصله 3 روز کاری انجام میشود.
    </p>

    <p>
      <b>سطح طلایی :</b>
      <br />
    در این سطح تمامی واریزی ها از سمت ایران وارکرفت و به هر مقدار طی 1 روز کاری برای شما ارسال میشود. برای دسترسی به سطح طلایی ؛ مجموع کد های آپلود شده از سمت شما باید به یکی از 2 صورت زیر باشد : 
    <br />
      1- ارسال کد برای ایران وارکرفت به تعداد مجموعا 50 عدد ( شما میتوانید این تعداد را یکجا ارسال نمایید )
      <br />
      2- شما میتوانید با 3 بار ارسال کد به ایران وارکرفت ( حداقل 5 کد در هر بار ) به سطح طلایی برسید.
    </p>
  </div>

  <button class="button -dark" (click)="userLevelInfo.close()">بستن</button>
</ngx-smart-modal>
