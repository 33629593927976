import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable } from 'rxjs';

import * as varizModel from '~models/variz';
import { UtilitiesService } from './utilities.service';
import { CreateResponse, DeleteRequest, DeleteResponse, GetItemRequest, GetItemResponse, GetListResponse, UpdateResponse } from '~models/index';


@Injectable()
export class VarizService {
  loading = true;

  constructor(
    private http: HttpClient,
    private utilitiesService: UtilitiesService
  ) { }

  getList(request ?: varizModel.VarizSearchRequest):
  Observable<GetListResponse<varizModel.VarizViewModel>> {
    let params = this.utilitiesService.listToParams(request);
    if (request?.userId > 0) {
      params = params.append('userId', request?.userId?.toString());
    }
    if (request?.maxPayDate) {
      params = params.append('maxPayDate', request?.maxPayDate?.toISOString());
    }
    if (request?.minPayDate) {
      params = params.append('minPayDate', request?.minPayDate?.toISOString());
    }
    if (request?.description) {
      params = params.append('description', request?.description);
    }
    if (request?.minPrice) {
      params = params.append('minPrice', request?.minPrice?.toString());
    }
    if (request?.maxPrice) {
      params = params.append('maxPrice', request?.maxPrice?.toString());
    }
    if(request.key){
      params= params.append('key', request.key?.toString());
    }
    
    return this.http.get<GetListResponse<varizModel.VarizViewModel>>(
      CONSTANST.routes.variz.list,
      { 
         params: params
        }
    );
  }

  getOne(request: GetItemRequest): Observable<GetItemResponse<varizModel.VarizViewModel>> {
    return this.http.get<GetItemResponse<varizModel.VarizViewModel>>(
      CONSTANST.routes.variz.get.replace(':id', request.id.toString()),
      
    );
  }

  create(request: varizModel.CreateVarizRequest): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      CONSTANST.routes.variz.create,
      request,
      
    );
  }

  update(request: varizModel.UpdateVarizRequest): Observable<UpdateResponse> {
    return this.http.put<UpdateResponse>(
      CONSTANST.routes.variz.update.replace(':id', request.id.toString()),
      request,
      
    );
    }

    delete(request ?: DeleteRequest):
    Observable<DeleteResponse> {
      const params = this.utilitiesService.deleteToParams(request);
      return this.http.delete<DeleteResponse>(
        CONSTANST.routes.variz.delete,
        { 
           params: params
          }
      );
    }

  }
