import { NgModule } from '@angular/core';
import {  AppCorkFormGroupComponent , AppCorkFormLabelComponent, AppCorkFormRowComponent
} from '.';

  @NgModule({
    imports: [
      
    ],
    declarations: [
        AppCorkFormGroupComponent , AppCorkFormLabelComponent, AppCorkFormRowComponent
    ],
    providers: [
    ],
    exports: [
        AppCorkFormGroupComponent , AppCorkFormLabelComponent, AppCorkFormRowComponent
    ]
  })
  export class FormModule { }