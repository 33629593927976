import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, AfterViewChecked, SimpleChanges, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { LicenseService } from '~services/license.service';
import { AuthService } from '~app/services/auth.service';

import * as licenseModel from '~app/models/license';
import * as baseModel from '~app/models';
import * as userModels from '~app/models/user/index';


import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~app/utils/messages';
import { UserViewModel } from '~app/models/user/UserViewModel';
import { UserService } from '~app/services/user.service';
import { GetProductListRequest, ProductViewModel } from '~app/models/product';
import { ProductService } from '~app/services/product.service';
import { VariantViewModel } from '~app/models/productVariants/VariantViewModel';
import { LicenseGroupViewModel, LicenseViewModel } from '~app/models/license';


@Component({
  selector: 'app-bulk-forms.row',
  templateUrl: './bulk.component.html'
})
export class BulkComponent implements AfterViewInit, OnInit , AfterViewChecked {
    constructor(
        private changeDetectorRef: ChangeDetectorRef,

        private authService: AuthService,
        private licenseService: LicenseService,
        private route: ActivatedRoute,
        private _location: Location,
        private userService: UserService,
        private toastr: ToastrService,
        private productService: ProductService,

      ) { }
      
  public frm: FormGroup;
  submitted = false;
  isLicenseInEdit = false;
  closeDialog = true;
  grouped: licenseModel.LicenseGroupViewModel = {};
  codes: string;
  public isActive: true;
    public bulkOrderId ?: string;
    public isAddMode = false;
    public isLoading = false;
    public isCustomerLoading = false;
    public isProductsLoading = false;
    public isAdmin = false;
    public license: licenseModel.LicenseViewModel;
    public customers: UserViewModel[] = [];
    public licenseStatusOptions: string[];
    public prooducts: ProductViewModel[] = [];
    public variants: VariantViewModel[] = [];
    invalidCodes : string[] = [];
    validCodes : string[] = [];
    detailId: number;
  isSubmitting = false;
  invalidCodesText:string;
  codeMirrorOptions: any = {
    theme: 'idea',
    mode: 'application/ld+json',
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    gutters: ['CodeMirror-linenumbers'],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true
  };

      ngOnInit() {

        const temp = {} as any;
        this.license = temp;
        this.bulkOrderId = this.route.snapshot.params['groupId'];
        this.isAddMode = !this.bulkOrderId;

        this.isAdmin = this.authService.isAdmin();
        this.licenseStatusOptions = licenseModel.LICENSE_STATUS_OPTIONS.map(x => x.value);
      }

      ngAfterViewInit() {
        if (this.isAdmin) {
          this.getCustomers();
        }
         if (!this.isAddMode) {
          this.getLicenseList(this.bulkOrderId);
        }
        this.getProducts();
      }
      getLicenseList(groupId:string){
        this.grouped={};
        this.grouped={licenses:[]}
        let request = new licenseModel.LicenseSearchRequest();
        request.bulkOrderId = groupId;
        request.pageSize=100000;
        let codes = '';
        this.licenseService.getList(request).subscribe((x)=>{
          for (let i = 0; i < x.list.length; i++) {
            const element = x.list[i];
            this.grouped.licenses.push(element);
            this.makeGroup(this.grouped);
            if(i != x.list.length -1){
              codes += element.code + '\n';
            }
            else{
              codes += element.code
            }
            this.codes = codes;
          }
        },(err)=>{

        })
      }
      makeGroup(group:LicenseGroupViewModel){
        const item = group.licenses[0];

        let groupPrice =0;
        for (let i = 0; i < group.licenses.length; i++) {
          const element = group.licenses[i];
          groupPrice += element.priceInOrderDate; 
        }

        group.groupcreatedAt = item.createdAt;
        group.groupPrice = groupPrice;
        group.groupCount =  group.licenses.length;
        group.groupProductName =  item.productVariant.product.name+ ' ' + item.productVariant.variantName;
        group.groupUser = item.user.fullName;
        group.groupId = item.bulkOrderId;
        group.groupUserId = item.userId;
        group.groupProduct = item.product;
        group.groupIsForSale = item.isForSale;
        group.groupIsSold = item.isSold;
        group.groupPriceInOrderDate = item.priceInOrderDate;
        group.groupProductVariantId = item.productVariantId;
        group.groupDescription = item.description;
        group.groupStatus = item.licenseStatus;
      }

      getCustomers(): void {
        this.isCustomerLoading = true;
        const request = new userModels.SearchUserRequest();
        request.pageSize = 999999;

        this.userService.getList(request).subscribe((data) => {
          if (data.isSuccess) {
            this.customers = data.list;
            this.isCustomerLoading = false;
          }
        }, (err) => {

        });
      }


      getProducts(): void {
        this.isProductsLoading = true;
        let request = new GetProductListRequest();
        request.pageSize = 9999;
        this.productService.getList().subscribe((x) => {
          if (x.isSuccess) {
            this.prooducts = x.list;
            this.isProductsLoading = false;

            for (let index = 0; index < x.list.length; index++) {
              const p = x.list[index];

              for (let j = 0; j < p.variants.length; j++) {
                const v = p.variants[j];

                v.variantFullName = p.name + ' ' + v.variantName;

                this.variants.push(v);
              }
            }



          }
        }, (err) => {

        });
      }

      ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
      }

      // public createLicense(value: licenseModel.LicenseCreateRequest): void {
      //   const request = {...value};
      //   request.userId = Number(this.selectedUserId);
      //   request.productVariantId = Number(this.selectedProductVariantId);

      //   this.licenseService.create(request)
      //   .subscribe((data) => {
      //     if (data.body.isSuccess) {
      //       const vm: LicenseViewModel = {...request};
      //       vm.id = data.body.id;
      //       if (!this.grouped.licenses) {
      //         this.grouped.licenses = [];
      //       }
      //       this.grouped.licenses.push(vm);

      //       this.isAddMode = false;
      //       this.toastr.success(MESSAGES.license.create.success);
      //     }
      //   }, (data) => {
      //       console.error(data);
      //       this.toastr.error(MESSAGES.license.create.error);
      //   })
      //   ;
      // }
      public closeVariant() {
        this.closeDialog = true;
      }

      // public updateLicense(value: licenseModel.LicenseUpdateRequest): void {
      //   const request = {...value};
      //   request.userId = Number(request.userId);
      //   request.productVariantId = Number(request.productVariantId);


      //   this.licenseService.update(request).subscribe((data) => {
      //     if (data.isSuccess) {
      //       this.toastr.success(MESSAGES.license.update.success);
      //       let item = this.grouped.licenses.find(x => x.id == request.id);
      //       item = request;
      //     }
      //   }, (err) => {
      //     this.toastr.error(MESSAGES.license.update.error);
      //   });
      // }

  public deleteLicense(id ?: number) {
    const request = new baseModel.DeleteRequest();
    request.id = [];
    request.id.push(Number(id));

    this.licenseService.delete(request).subscribe((data) => {

      if (data.isSuccess) {
        this.toastr.success(MESSAGES.license.delete.success);

        const index = this.grouped.licenses.findIndex(x => x.id == id);
        this.grouped.licenses.splice(index, 1);
      }
    }, () => {
      this.toastr.error(MESSAGES.license.delete.error);
    });
  }
  saveLicenses():void{
    console.log('saveLicenses')
    if(this.isSubmitting){
      return;
    }
    this.isSubmitting = true;
     
    const listRequest = new Array<licenseModel.LicenseCreateRequest>();
    const array = this.codes.split(/\r?\n/);
    for (let index = 0; index < array.length; index++) {
        const code = array[index];
        const item = new licenseModel.LicenseCreateRequest();
        item.description = this.grouped.groupDescription;
        item.productVariantId = Number(this.grouped.groupProductVariantId);
        item.userId = Number(this.grouped.groupUserId);
        item.code = code;
        listRequest.push(item);
    }
    
    this.licenseService.createRange(listRequest).subscribe(x=>{
      console.log(x);
      if(x.body.isSuccess){

          const resp = x.body.list;
          for (let index = 0; index < listRequest.length; index++) {
            const element = listRequest[index];
            if(resp.findIndex(x=>x.code == element.code)>-1){
              this.validCodes.push(element.code);
            }
            else{
              if(this.invalidCodes == undefined || this.invalidCodes == null){
                this.invalidCodes = [];
              }
              this.invalidCodes.push(element.code);
            }
            
          }
          this.invalidCodesText = this.invalidCodes.join('\r\n');
          this.codes = this.validCodes.join('\r\n');
        
        

        
          this.toastr.success(MESSAGES.license.create.success);

    this.isSubmitting =false;
  }
  },err=>{
    console.log(err);
          this.toastr.error(MESSAGES.license.create.error);
          this.isSubmitting =false;
  });

   
  }
  updateLicenses():void{
    if(this.isSubmitting){
      return;
    }

    this.isSubmitting = true;
    const listRequest = new Array<licenseModel.LicenseUpdateRequest>();
    const array = this.codes.split(/\r?\n/);
    for (let index = 0; index < array.length; index++) {
        const code = array[index];
        const item = new licenseModel.LicenseUpdateRequest();
        item.description = this.grouped.groupDescription;
        item.productVariantId = Number(this.grouped.groupProductVariantId);
        item.userId = Number(this.grouped.groupUserId);
        item.bulkOrderId = this.bulkOrderId;
        item.isSold = this.grouped.groupIsSold;
        item.isForSale = this.grouped.groupIsForSale;
        item.licenseStatus = this.grouped.groupStatus;
        item.code = code;
        item.licenseStatus = this.grouped.groupStatus;

        listRequest.push(item);
    }
    
    this.licenseService.updateRange(listRequest).subscribe(x=>{
      if(x.isSuccess){

        this.isSubmitting =false;
        this.toastr.success(MESSAGES.license.update.success);
      }
      },err=>{
        console.log(err);
        this.isSubmitting =false;
        this.toastr.error(MESSAGES.license.update.error);
      });
  }

  clearValidator(){
    this.invalidCodesText = '';
    this.validCodes=[];
    this.invalidCodes=[];
  }
}
