import { UpdateRequest } from '../UpdateRequest';



export class UpdateUserCustomerRequest extends UpdateRequest {
    email: string;
    name: string;
    surname: string;
    fatherName: string;
    nationalCode: string;
}
