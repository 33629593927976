import { GetListRequest } from '../GetListRequest';


export class VarizSearchRequest extends GetListRequest {
    description: string;
    minPrice: number | null;
    maxPrice: number | null;
    userId: number | null;
    minPayDate: Date | null;
    maxPayDate: Date | null;
}
