import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable } from 'rxjs';



import * as attachmentModel from '~models/ticket/ticketAttachment/index';
import { UtilitiesService } from './utilities.service';
import { CreateResponse, DeleteRequest, DeleteResponse, GetItemRequest, GetItemResponse, GetListResponse, UpdateResponse } from '~models/index';


@Injectable()
export class TicketAttachmentService {
  constructor(
    private http: HttpClient,
    
    private utilitiesService: UtilitiesService
  ) { }

  

  getOne(request: GetItemRequest): Observable<GetItemResponse<attachmentModel.TicketReplyAttachmentViewModel>> {
    return this.http.get<GetItemResponse<attachmentModel.TicketReplyAttachmentViewModel>>(
      CONSTANST.routes.ticket.attachment.getOne.replace(':id', request.id.toString()),
      
    );
  }

  create(request: attachmentModel.CreateAttachmentRequest): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      CONSTANST.routes.ticket.attachment.create,
      request,
      
    );
  }

    delete(request ?: DeleteRequest):
    Observable<DeleteResponse> {
      const params = this.utilitiesService.deleteToParams(request);
      return this.http.delete<DeleteResponse>(
        CONSTANST.routes.ticket.attachment.delete,
        {
          
           params: params
          }
      );
    }

  }
