import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '~utils/shared.module';
import { AdminLayoutComponent } from './admin-layout.component';
import { DashboardModule } from '~modules/dashboard/dashboard.module';
import { TicketModule } from '~modules/ticket/ticket.module';
import { ProductModule } from '~modules/product/product.module';
import { BankAccountModule } from '~modules/bankaccount/bankaccount.module';
import { LicenseModule } from '~modules/license/license.module';
import { VarizModule } from '~modules/variz/variz.module';
import { OrderModule } from '~modules/orders/order.module';
import { PipesModule } from '~app/pipes/pipes.module';


@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    DashboardModule,
    TicketModule,
    ProductModule,
    BankAccountModule,
    LicenseModule,
    VarizModule,
    OrderModule,
    PipesModule
  ],
  declarations: [
    AdminLayoutComponent
  ],
  providers: [],
  exports: []
})
export class AdminLayoutModule {
}
