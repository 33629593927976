import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerDashboardComponent } from './customerDashboard.component';
import { SharedModule } from '~utils/shared.module';

import { CustomerTicketModule } from '~modules/customer-tickets/customer-ticket.module';
import { CustomerLicenseModule } from '~modules/customer-licenses/customer-license.module';
import { CustomerBankAccountModule } from '~app/modules/customer-banks/customer-bankaccount.module';

import { CustomerVarizModule } from '~app/modules/customer-variz/customer-variz.module';
import { CustomerProfileModule } from '~app/modules/customer-profile/customer-profile.module';
import { CustomerOrderModule } from '~modules/customer-orders/customer-order.module';



const routes: Routes = [
  {path: '',
  component: CustomerDashboardComponent},
  {
    path: 'tickets',
    loadChildren: '~modules/customer-tickets/customer-ticket.module#CustomerTicketModule',
  }, {
    path: 'licenses',
    loadChildren: '~modules/customer-licenses/customer-license.module#CustomerLicenseModule',
  }
  , {
    path: 'banks',
    loadChildren: '~modules/customer-banks/customer-bankaccount.module#CustomerBankAccountModule',
  }, {
    path: 'variz',
    loadChildren: '~modules/customer-variz/customer-variz.module#CustomerVarizModule',
  }, {
    path: 'profile',
    loadChildren: '~modules/customer-profile/customer-profile.module#CustomerProfileModule',
  },{
    path: 'orders',
    loadChildren: '~modules/customer-orders/customer-order.module#CustomerOrderModule',
  },
];

@NgModule({
  declarations: [
    CustomerDashboardComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule,
  ]
})
export class CustomerDashboardModule {
}
