import { Component } from '@angular/core';
import { AppCorkButtonSecondaryComponent } from './secondary.component';

@Component({
  selector: 'ck-btn-cancel',
  template: `
  <button (click)='onClick($event)'
  class="btn mb-2 btn-outline-secondary">
  بازگشت
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
  stroke="currentColor" stroke-width="2" stroke-linecap="round"
  stroke-linejoin="round" class="feather feather-corner-down-left">
  <polyline points="9 10 4 15 9 20"></polyline><path d="M20 4v7a4 4 0 0 1-4 4H4"></path>
  </svg>
  </button>`
})
export class AppCorkButtonCancelComponent extends AppCorkButtonSecondaryComponent {
  className = this.className + ' ml-2';
}
