import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../utils/shared.module';

import { OrderComponent } from './order.component';

@NgModule({
    imports: [
      RouterModule.forChild([
        {path: '', component: OrderComponent},
    ]),
      SharedModule
    ],
    declarations: [
        OrderComponent,
    ],
    providers: [],
    entryComponents: [
    ],
    exports: [
      RouterModule,
    ]
  })
  export class OrderModule { }