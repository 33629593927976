import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../utils/shared.module';

import { BankAccountComponent } from './bankaccount.component';
import { FormComponent } from './form/form.component';

@NgModule({
    imports: [
      RouterModule.forChild([
        {path: '', component: BankAccountComponent},
        {path: 'add', component: FormComponent},
        {path: 'edit/:id', component: FormComponent}
    ]),
      SharedModule
    ],
    declarations: [
        BankAccountComponent,
        FormComponent
    ],
    providers: [],
    entryComponents: [
        FormComponent
    ],
    exports: [
      RouterModule,
    ]
  })
  export class BankAccountModule { }