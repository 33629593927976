import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '~utils/shared.module';
import { RegisterModule } from './register/register.module';
import { RegisterLayoutComponent } from './register-layout.component';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    RegisterModule,
  ],
  declarations: [
    RegisterLayoutComponent,
  ],
  providers: [],
  exports: []
})
export class RegisterLayoutModule {
}
