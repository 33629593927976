import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable , throwError} from 'rxjs';

import {UpdateResponse , CreateResponse , GetItemRequest ,
     DeleteRequest , DeleteResponse, GetListResponse, GetItemResponse } from '~models/index';



import { LicenseSearchRequest , LicenseUpdateRequest,
   LicenseViewModel, LicenseCreateRequest, LicenseSoldRequest,
    LicenseCheckExistRequest, LicenseBulkCreateResponse, LicenseCreateByFileRequest,
 } from '~models/license';
import { UtilitiesService } from './utilities.service';
import { BaseResponse } from '~app/models/BaseResponse';


@Injectable()
export class LicenseService {

  constructor(
    private http: HttpClient,
    
    private utilitiesService: UtilitiesService
  ) { }

  

  getList(request ?: LicenseSearchRequest):
  Observable<GetListResponse<LicenseViewModel>> {
    let params = this.utilitiesService.listToParams(request);
    
    if(request){
      if(request.userId){
        params = params.append('userId', request.userId?.toString());
      }
      if(request.code){
        params = params.append('code', request.code?.toString());
      }
      if(request.isSold){
        params = params.append('isSold',request.isSold?.toString());
      }
      if(request.forSell){
        params = params.append('forSell',request.forSell?.toString());
      }
      if (request?.fromDate) {
        params = params.append('fromDate', request?.fromDate?.toISOString());
      }
      if (request?.toDate) {
        params = params.append('toDate', request?.toDate?.toISOString());
      }
      if(request?.bulkOrderId) {
        params = params.append('bulkOrderId', request?.bulkOrderId?.toString());
      }
      if(request.key){
        params= params.append('key', request.key?.toString());
      }
    }

    return this.http.get<GetListResponse<LicenseViewModel>>(
      CONSTANST.routes.license.list,
      {
        
         params: params
        }
    );
  }

  getOne(request: GetItemRequest): Observable<GetItemResponse<LicenseViewModel>> {
    return this.http.get<GetItemResponse<LicenseViewModel>>(
      CONSTANST.routes.license.get.replace(':id', request.id.toString()),
      
    );
  }

  create(request: LicenseCreateRequest): Observable<HttpResponse<CreateResponse>> {
    return this.http.post<CreateResponse>(
      CONSTANST.routes.license.create,
      request,
      {
         observe:'response' }
    );
  }
  createRange(request: LicenseCreateRequest[]): Observable<HttpResponse<LicenseBulkCreateResponse>> {
    return this.http.post<LicenseBulkCreateResponse>(
      CONSTANST.routes.license.createRange,
      request,
      { 
         observe:'response' }
    );
  }
  createRangeWithFile(request: LicenseCreateByFileRequest): Observable<HttpResponse<LicenseBulkCreateResponse>> {
    return this.http.post<LicenseBulkCreateResponse>(
      CONSTANST.routes.license.createRangeWithFile,
      request,
      { 
         observe:'response' }
    );
  }
  checkRange(request: LicenseCreateRequest[]): Observable<HttpResponse<LicenseBulkCreateResponse>> {
    return this.http.post<LicenseBulkCreateResponse>(
      CONSTANST.routes.license.checkRange,
      request,
      { 
         observe:'response' }
    );
  }
  
  checkRangeWithFile(request: LicenseCreateByFileRequest): Observable<HttpResponse<LicenseBulkCreateResponse>> {
    return this.http.post<LicenseBulkCreateResponse>(
      CONSTANST.routes.license.checkRangeWithFile,
      request,
      { 
         observe:'response' }
    );
  }

  update(request: LicenseUpdateRequest): Observable<UpdateResponse> {
    return this.http.put<UpdateResponse>(
      CONSTANST.routes.license.update.replace(':id', request.id.toString()),
      request,
    );
    }

    updateRange(request: LicenseUpdateRequest[]): Observable<UpdateResponse> {
      return this.http.put<UpdateResponse>(
        CONSTANST.routes.license.updateRange,
        request,
        
      )
      }

    delete(request ?: DeleteRequest):
    Observable<DeleteResponse> {
      const params = this.utilitiesService.deleteToParams(request);

      return this.http.get<DeleteResponse>(
        CONSTANST.routes.license.delete,
        { 
          
          params: params
          }
      );
    }

  sold(request: LicenseSoldRequest): Observable<UpdateResponse> {
    return this.http.post<UpdateResponse>(
      CONSTANST.routes.license.sold.replace(':code', request.code.toString()),
      request,
      
    );
  }

  checkExist(request: LicenseCheckExistRequest): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(
      CONSTANST.routes.license.checkExist.replace(':code', request.code),
      
    );
  }


  }
