  <!--  BEGIN NAVBAR  -->
  <div class="header-container fixed-top">
    <header class="header navbar navbar-expand-sm expand-header">
      <a id="sideMenu" (click)="toggleSideBar()" class="sidebarCollapse" data-placement="bottom"><svg
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-menu">
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg></a>

      <ul class="navbar-item flex-row ml-auto">

        <li class="nav-item align-self-center search-animated">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-search toggle-search">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          <form class="form-inline search-full form-inline search" role="search">
            <div class="search-bar">
              <input type="text" class="form-control search-form-control  ml-lg-auto" placeholder="Search...">
            </div>
          </form>
        </li>

        <li class="nav-item dropdown message-dropdown">
          <a href="javascript:void(0);" class="nav-link dropdown-toggle" id="messageDropdown" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-mail">
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg><span *ngIf="stats.newTickets>0" class="badge badge-primary">{{stats.newTickets}}</span>
          </a>
          <div class="dropdown-menu position-absolute e-animated e-fadeInUp" aria-labelledby="messageDropdown">
            <div class="">
              <a *ngFor="let ticket of stats.tickets; index as i;"
               class="dropdown-item">
                <div class="">
                  <div class="media notification-new">
                    <div class="notification-icon">
                      <div class="icon-svg mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-message-square">
                          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="meta-title mr-3">{{ ticket.replies[ticket.replies.length-1].user.fullName }}</p>
                      <p class="message-text">{{ ticket.replies[ticket.replies.length-1].message }}</p>
                      <p class="meta-time align-self-center mb-0">
                        {{ ticket.replies[ticket.replies.length-1].createdAt | jalali}}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </li>

        <li class="nav-item dropdown notification-dropdown">
          <a href="javascript:void(0);" class="nav-link dropdown-toggle" id="notificationDropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-bell">
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg><span class="badge badge-success"></span>
          </a>
          <div class="dropdown-menu position-absolute e-animated e-fadeInUp" aria-labelledby="notificationDropdown">
            <div class="notification-scroll">

              <div class="dropdown-item">
                <div class="media" *ngIf="isAdmin">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-message-square">
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                  </svg>
                  <div class="media-body">
                    <div class="notification-para">
                      : تعداد پیامک باقیمانده
                      <br />
                      {{stats.smsCharge}} 
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </li>

        <li class="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
          <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-user-check">
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="8.5" cy="7" r="4"></circle>
              <polyline points="17 11 19 13 23 9"></polyline>
            </svg>
          </a>
          <div class="dropdown-menu position-absolute e-animated e-fadeInUp" aria-labelledby="userProfileDropdown">
            <div class="user-profile-section">
              <div class="media mx-auto">
                <img src="assets/img/profile-7.jpg" class="img-fluid mr-2" alt="avatar">
                <div class="media-body">
                  <h5>{{user.fullName}}</h5>
                  <p>{{user.role | rolename }}</p>
                </div>
              </div>
            </div>
            <div class="dropdown-item">
              <a href="user_profile.html">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-user">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg> <span>حساب کاربری</span>
              </a>
            </div>
            <div class="dropdown-item">
              <a (click)="logout()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-log-out">
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg> <span>خروج</span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </header>
  </div>
  <!--  END NAVBAR  -->

  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container sidebar-closed sbar-open" id="container">

    <div class="overlay-admin"></div>
    <div class="cs-overlay"></div>
    <div class="search-overlay"></div>


    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme" style="overflow-y: auto;">

      <nav id="sidebar" aria-expanded="true">

        <ul class="navbar-nav theme-brand flex-row  text-center">
          <li class="nav-item theme-logo">
            <a routerLink="/admin/">
              <img src="assets/img/logo.svg" class="navbar-logo" alt="logo">
            </a>
          </li>
          <li class="nav-item theme-text">
            <a routerLink="/admin/" class="nav-link"> IranWarcraft </a>
            <a id="sideMenu" style="position: absolute;
            top: 60px;
            left: 0;
            color: black !important;
            z-index: 9999;
            cursor: pointer;" (click)="toggleSideBar()" class="sidebarCollapse" data-placement="bottom">
            <svg width="24px" height="24px" viewBox="0 0 24 24" id="fa26cff6-f100-48f4-a81c-6e0cb8b1ea4a" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg"><title>prime</title><g id="880b5b52-5578-436c-a64a-10a281e56ea6" data-name="Arrow Left"><path d="M7.77,23.58l-2.24-2a0.5,0.5,0,0,1,0-.71L13.43,12,5.5,3.13a0.5,0.5,0,0,1,0-.71l2.24-2a0.5,0.5,0,0,1,.71,0L18.8,12,8.48,23.54A0.5,0.5,0,0,1,7.77,23.58Z"/></g></svg>
          </a>
          <a id="sideMenu" style="
              position: absolute;
              bottom: 20px;
              left: 0;
              color: black !important;
              z-index: 9999;
              cursor: pointer;
          " (click)="toggleSideBar()" class="sidebarCollapse" data-placement="bottom">
            <svg width="24px" height="24px" viewBox="0 0 24 24" id="fa26cff6-f100-48f4-a81c-6e0cb8b1ea4a" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg"><title>prime</title><g id="880b5b52-5578-436c-a64a-10a281e56ea6" data-name="Arrow Left"><path d="M7.77,23.58l-2.24-2a0.5,0.5,0,0,1,0-.71L13.43,12,5.5,3.13a0.5,0.5,0,0,1,0-.71l2.24-2a0.5,0.5,0,0,1,.71,0L18.8,12,8.48,23.54A0.5,0.5,0,0,1,7.77,23.58Z"/></g></svg>
          </a>
          </li>
        </ul>
        
        <ul class="list-unstyled menu-categories" id="accordionExample">
          <li class="menu">
            <a routerLink="/admin/" aria-expanded="false" class="dropdown-toggle">
              <div class="">
                <span>داشبورد</span>
              </div>
            </a>
          </li>
          <li class="menu">
            <a routerLink="/admin/tickets" aria-expanded="false" class="dropdown-toggle">
              <div class="">
                <span>تیکت ها</span>
              </div>
            </a>
          </li>

          <li class="menu">
            <a routerLink="/admin/products" aria-expanded="false" class="dropdown-toggle">
              <div class="">
                <span>محصول ها</span>
              </div>
            </a>
          </li>

          <li class="menu">
            <a routerLink="/admin/users" aria-expanded="false" class="dropdown-toggle">
              <div class="">
                <span>کاربر ها</span>
              </div>
            </a>
          </li>


          <li class="menu">
            <a routerLink="/admin/bankaccounts" aria-expanded="false" class="dropdown-toggle">
              <div class="">
                <span>حساب بانکی ها</span>
              </div>
            </a>
          </li>

          <li class="menu">
            <a routerLink="/admin/variz" aria-expanded="false" class="dropdown-toggle">
              <div class="">
                <span>واریز ها</span>
              </div>
            </a>
          </li>
          
          <li class="menu">
            <a routerLink="/admin/orders" aria-expanded="false" class="dropdown-toggle">
              <div class="">
                <span>سفارش ها</span>
              </div>
            </a>
          </li>

          <li class="menu">
            <a href="#licenses" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                <div class="">
                    <span>لایسنس ها</span>
                </div>
                <div>
                </div>
            </a>
            <ul class="collapse submenu list-unstyled" id="licenses" data-parent="#accordionExample">
                <li>
                    <a [routerLink]="['/admin/licenses']"> همه لایسنس ها </a>
                </li>
                <li>
                  <a [routerLink]="['/admin/licenses']" [queryParams]="{ isSold: 'false'}"> فروخته شده به ما </a>
              </li>

              <li>
                <a [routerLink]="['/admin/licenses']" [queryParams]="{ isSold: 'true'}"> لایسنس خریداری شده از ما </a>
            </li>

            <li>
              <a [routerLink]="['/admin/licenses']" [queryParams]="{ forSell: 'true'}"> لایسنس های آماده فروش </a>
          </li>

            </ul>
          </li>

          <li class="menu">
            <a (click)="telegramBotAlive()" aria-expanded="false" class="dropdown-toggle">
              <div class="">
                <span>تست ربات</span>
              </div>
            </a>
          </li>

          <li class="menu">
            <a (click)="logout()" aria-expanded="false" class="dropdown-toggle">
              <div class="">
                <span>خروج اضطراری</span>
              </div>
            </a>
          </li>
          


        </ul>

      </nav>

    </div>
    <!--  END SIDEBAR  -->

    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="container-fluid">
            <div class="container-fluid">


            <router-outlet class="container-fluid">
            </router-outlet>


    </div>

      </div>

    </div>
    <!--  END CONTENT AREA  -->

  </div>
  <!-- END MAIN CONTAINER -->
