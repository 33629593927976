import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CorkModule } from '../components/cork/cork.module';
import { PipesModule } from '~app/pipes/pipes.module';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import {DpDatePickerModule} from 'ng2-jalali-date-picker';
import { NgPagerModule } from '../modules/ng-pager/ng-pager.module';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        CorkModule,
        PipesModule,
        NgSelectModule,
        AlifeFileToBase64Module,
        DpDatePickerModule,
        NgPagerModule,
        CodemirrorModule,
        NgxSmartModalModule.forChild(),
        NgxSpinnerModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        CorkModule,
        PipesModule,
        NgSelectModule,//https://github.com/ng-select/ng-select
        AlifeFileToBase64Module,//https://github.com/msg2santoshsagar/alife-file-to-base64,
        DpDatePickerModule,//https://github.com/fingerpich/jalali-angular-datepicker#readme
        NgPagerModule,//https://github.com/shihabahmed/NgPagerLibrary
        CodemirrorModule,
        NgxSmartModalModule,
        NgxSpinnerModule
    ]
})
export class SharedModule { }
