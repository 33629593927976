import { Component } from '@angular/core';

@Component({
    selector: 'ck-table-header',
    template: `
    <thead>
            <tr>
                <ng-content></ng-content>
            </tr>
    </thead>
    `,
  })
export class AppCorkTableHeaderComponent { }
