import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '~utils/shared.module';

const routes: Routes = [
  {path: '', component: DashboardComponent},
  {
    path: 'users',
    loadChildren: '~modules/user/user.module#UserModule',
  },
  {
    path: 'tickets',
    loadChildren: '~modules/ticket/ticket.module#TicketModule',
  },
  {
    path: 'products',
    loadChildren: '~modules/product/product.module#ProductModule',
  },
  {
    path: 'bankaccounts',
    loadChildren: '~modules/bankaccount/bankaccount.module#BankAccountModule',
  },
  {
    path: 'licenses',
    loadChildren: '~modules/license/license.module#LicenseModule',
  },
  {
    path: 'variz',
    loadChildren: '~modules/variz/variz.module#VarizModule',
  },
  {
    path: 'orders',
    loadChildren: '~modules/orders/order.module#OrderModule',
  },
]

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class DashboardModule {
}
