import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { CONSTANST } from '~utils/constanst';
import { Observable, of } from 'rxjs';

import {
  UpdateResponse,
  CreateResponse,
  GetItemRequest,
  DeleteRequest,
  DeleteResponse,
  GetListResponse,
  GetItemResponse,
} from '~models/index';

import * as models from '~models/user/index';
import { AuthService } from './auth.service';
import {
  MailVerifyConfirmationRequest,
  UserViewModel,
} from '~models/user/index';
import { UpdateRequest } from '~app/models/UpdateRequest';
import { BaseResponse } from '~app/models/BaseResponse';

import { UtilitiesService } from './utilities.service';
import { OTPVerifyRequest } from '~app/models/Auth/OTPVerifyRequest';
import { catchError, map, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class UserService {
  constructor(
    private http: HttpClient,

    private utilitiesService: UtilitiesService
  ) {}

  getList(
    request?: models.SearchUserRequest
  ): Observable<GetListResponse<UserViewModel>> {
    let params = this.utilitiesService.listToParams(request);

    if (request != undefined) {
      if (request.email) {
        params = params.append('email', request.email?.toString());
      }
      if (request.emailConfirmed) {
        params = params.append(
          'emailConfirmed',
          request.emailConfirmed?.toString()
        );
      }
      if (request.mobile) {
        params = params.append('mobile', request.mobile?.toString());
      }
      if (request.mobileConfirmed) {
        params = params.append(
          'mobileConfirmed',
          request.mobileConfirmed?.toString()
        );
      }
      if (request.phone) {
        params = params.append('phone', request.phone?.toString());
      }
      if (request.isPhoneConfirmed) {
        params = params.append(
          'isPhoneConfirmed',
          request.isPhoneConfirmed?.toString()
        );
      }
      if (request.name) {
        params = params.append('name', request.name?.toString());
      }
      if (request.nationalCode) {
        params = params.append(
          'nationalCode',
          request.nationalCode?.toString()
        );
      }
      if (request.surname) {
        params = params.append('surname', request.surname?.toString());
      }
      if (request.role) {
        params = params.append('role', request.role?.toString());
      }
      if (request.userStatus) {
        params = params.append('userStatus', request.userStatus?.toString());
      }
      if (request.isLock) {
        params = params.append('isLock', request.isLock?.toString());
      }

      if (request.createdAtMax) {
        params = params.append(
          'createdAtMax',
          request.createdAtMax?.toString()
        );
      }
      if (request.createdAtMin) {
        params = params.append(
          'createdAtMin',
          request.createdAtMin?.toString()
        );
      }

      if (request.modifiedAtMax) {
        params = params.append(
          'modifiedAtMax',
          request.modifiedAtMax?.toString()
        );
      }
      if (request.modifiedAtMin) {
        params = params.append(
          'modifiedAtMin',
          request.modifiedAtMin?.toString()
        );
      }

      if (request.emailConfirmedAtMax) {
        params = params.append(
          'emailConfirmedAtMax',
          request.emailConfirmedAtMax?.toString()
        );
      }
      if (request.emailConfirmedAtMin) {
        params = params.append(
          'emailConfirmedAtMin',
          request.emailConfirmedAtMin?.toString()
        );
      }

      if (request.mobileConfirmedAtMax) {
        params = params.append(
          'mobileConfirmedAtMax',
          request.mobileConfirmedAtMax?.toString()
        );
      }
      if (request.mobileConfirmedAtMin) {
        params = params.append(
          'mobileConfirmedAtMin',
          request.mobileConfirmedAtMin?.toString()
        );
      }

      if (request.phoneConfirmedAtMax) {
        params = params.append(
          'phoneConfirmedAtMax',
          request.phoneConfirmedAtMax?.toString()
        );
      }
      if (request.phoneConfirmedAtMin) {
        params = params.append(
          'phoneConfirmedAtMin',
          request.phoneConfirmedAtMin?.toString()
        );
      }
      if (request.key) {
        params = params.append('key', request.key);
      }
    }

    return this.http.get<GetListResponse<UserViewModel>>(
      CONSTANST.routes.user.list,
      {
        params: params,
      }
    );
  }

  me() {
    return this.http.get<GetItemResponse<UserViewModel>>(
      CONSTANST.routes.user.me
    );
  }

  getOne(request: GetItemRequest): Observable<GetItemResponse<UserViewModel>> {
    return this.http.get<GetItemResponse<UserViewModel>>(
      CONSTANST.routes.user.get.replace(':id', request.id.toString())
    );
  }

  create(request: models.CreateUserRequest): Observable<CreateResponse> {
    return this.http
      .post<CreateResponse>(CONSTANST.routes.user.create, request)
      
  }

  update(request: models.UpdateUserRequest): Observable<UpdateResponse> {
    return this.http
      .put<UpdateResponse>(
        CONSTANST.routes.user.update.replace(':id', request.id.toString()),
        request
      )
  }

  updateProfile(
    request: models.UpdateUserCustomerRequest
  ): Observable<UpdateResponse> {
    return this.http
      .put<UpdateResponse>(
        CONSTANST.routes.user.updateCustomer.replace(
          ':id',
          request.id.toString()
        ),
        request
      )
      
  }

  delete(request?: DeleteRequest): Observable<DeleteResponse> {
    const params = this.utilitiesService.deleteToParams(request);
    return this.http
      .delete<DeleteResponse>(CONSTANST.routes.user.delete, {
        params: params,
      })
      
  }

  changeRole(request: models.SetByUserIdRequest): Observable<UpdateResponse> {
    return this.http
      .put<UpdateResponse>(
        CONSTANST.routes.user.changerole.replace(':id', request.id.toString()),
        request
      )
      
  }

  changeLock(request: UpdateRequest): Observable<UpdateResponse> {
    return this.http
      .put<UpdateResponse>(
        CONSTANST.routes.user.changelock.replace(':id', request.id.toString()),
        request
      )
      
  }

  sendConfirmationMail(request: GetItemRequest): Observable<BaseResponse> {
    return this.http
      .get<BaseResponse>(
        CONSTANST.routes.user.sendconfirmationmail.replace(
          ':id',
          request.id.toString()
        )
      )
      
  }

  sendMobileConfirmation(request: GetItemRequest): Observable<BaseResponse> {
    return this.http
      .get<BaseResponse>(
        CONSTANST.routes.user.sendmobileconfirmation.replace(
          ':id',
          request.id.toString()
        )
      )
      
  }

  VerifyConfirmationMobile(
    request: OTPVerifyRequest
  ): Observable<BaseResponse> {
    return this.http
      .patch<BaseResponse>(
        CONSTANST.routes.user.verifyconfirmationmobile,
        request
      )
  }

  verifyConfirmationMail(
    request: MailVerifyConfirmationRequest
  ): Observable<BaseResponse> {
    return this.http
      .patch<BaseResponse>(
        CONSTANST.routes.user.verifyconfirmationmail,
        request
      )
  }

  wannaBeSeller(): Observable<BaseResponse> {
    return this.http
      .get<BaseResponse>(
        CONSTANST.routes.user.wannaBeSeller)
  }

}
