<div class="col-lg-12 col-12 idkSpacing">
  <div class="statbox widget box box-shadow">
    <div class="widget-header">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
            <h4 *ngIf="!isAddMode">{{ticket.subject}}</h4>
            <h4 *ngIf="isAddMode">تیکت جدید</h4>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
            <ng-select [(ngModel)]="ticketStatusSelected" style="    width: 200px;
            margin-top: 14px;
            margin-left: 20px;"
             (change)="changeStatus()" class="basic placeholder js-states select2">
                <ng-option *ngFor="let row of ticketStatuses; index as i;" [value]="row">{{ row | ticketStatusFa }}</ng-option>
              </ng-select>

            <ck-btn-cancel class="back-button" routerLink="/admin/tickets"></ck-btn-cancel>
        </div>
      </div>
    </div>
    <div class="widget-content widget-content-area">




<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container padding-bottom-3x mb-2">
    <div class="row">
        <div class="col-lg-12" id="select2Container">
            <div class="padding-top-2x mt-2 hidden-lg-up"></div>
            <div class="table-responsive margin-bottom-2x" *ngIf="!isAddMode">
                <table class="table margin-bottom-none">
                    <thead>
                        <tr>
                            <th>تاریخ ایجاد</th>
                            <th>آخرین بروزرسانی</th>
                            <th>وضعیت</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ticket.createdAt | jalali }}</td>
                            <td>{{modifiedAt | jalali }}</td>
                            <td><span class="text-primary">{{ticket.ticketStatus | ticketStatusFa}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Messages-->
            <div class="comment" *ngFor="let row of ticket.replies; index as i;">
                <div class="comment-author-ava">
                    <img *ngIf="row.user.role=='Admin'" src="assets\img\profile-3.jpg" alt="Avatar">
                    <img *ngIf="row.user.role!='Admin'" src="assets\img\profile-19.jpg" alt="Avatar">
                </div>
                <div class="comment-body">
                    <p class="comment-text">
                      {{row.message}}
                    </p>
                    <div class="comment-footer">
                        <span class="comment-meta">
                            <span class="name">{{row.user.fullName}}</span>
                            <span class="date">{{row.createdAt | jalali }}</span>
                            <span class="attachments" *ngIf="row.attachments">
                                <span *ngFor="let attachment of row.attachments; index as i;" >
                                    <a 
                                    [attr.href]="attachment.address"
                                    download="download" class="btn btn-dark mb-2 mr-2 rounded-circle">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                    </a>
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <!-- Reply Form-->
            <h5 class="mb-30 padding-top-1x">ارسال پیام</h5>
            <form [formGroup]="frm" (ngSubmit)="onSubmit($event)" novalidate>

                
                <div class="form-group" *ngIf="isAddMode && isAdmin">
                    <label for="customerId">مشتری</label>

                           <!--Using ng-option and for loop-->
                <ng-select [(ngModel)]="customerId" 
                    [ngModelOptions]="{standalone: true}" name="customerId" id="customerId"
                    class="basic placeholder js-states select2">
                    <ng-option *ngFor="let row of customers; index as i;" 
                    [value]="row.id">{{ row.fullName }} {{row.mobile}} {{row.email}}</ng-option>
                  </ng-select>

                  <!-- <input type="hidden" formControlName="customerId"  name="customerId" id="customerId"> -->
                  

                </div>

                <div class="form-group" *ngIf="isAddMode">
                    <input type="text" class="form-control form-control-rounded"
                    formControlName="subject" class="form-control" name="subject" id="subject"
                    placeholder="عنوان تیکت را اینجا بنویسید..." required="">
                </div>
                <div class="form-group">
                    <textarea class="form-control form-control-rounded" 
                    formControlName="message" class="form-control" name="message" id="message"
                    rows="8" placeholder="پیام خود را اینجا بنویسید..." required=""></textarea>
                </div>

                <div class="form-group file-upload" [ngClass]="{'edit-mode':!isAddMode}">
                    <input type="file" class="form-control form-control-rounded" alife-file-to-base64 multiple (onFileChanged)="onFileChanges($event)" [(fileModel)]="files" [(rawFileModel)]="rawFiles" />
                </div>

                <div class="text-right">
                    <button class="btn btn-outline-primary" type="submit">ارسال پیام</button>
                </div>
            </form>
        </div>
    </div>
</div>

</div>
</div>
</div>
