import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, AfterViewChecked, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '~services/user.service';

import * as userModel from '~app/models/user/index';
import * as bankModel from '~app/models/bankAccount/index';

import { AuthService } from '~app/services/auth.service';

import { Location } from '@angular/common';
import { GetItemRequest } from '~app/models';

import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~app/utils/messages';
import { ROLE_NAMES, UserViewModel , USER_STATUS_NAMES , USER_LEVEL_OPTIONS } from '~app/models/user/index';
import { FileModel } from '~app/models/FileModel';
import { UserAttachmentService } from '~app/services/userAttachment.service';

import * as baseModels from '~app/models';
import { BankAccountViewModel } from '~app/models/bankAccount/index';
import { BankAccountService } from '~app/services/bankaccount.service';

@Component({
  selector: 'app-user-forms.row',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements AfterViewInit, OnInit , AfterViewChecked {

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,

        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private _location: Location,
        private toastr: ToastrService,
        private attachmentService: UserAttachmentService,
        private bankService: BankAccountService

      ) { }
  get f() { return this.frmUser.controls; }
      meliCard: FileModel[];
      frmUser: FormGroup;
    submitted = false;
    public isActive: true;
    public id ?: number;
    public isAddMode = false;
    public isLoading = false;
    public isAdmin = false;
    public user: UserViewModel;

      public closeDialog = true;
      public roleOptions: string[];
      public userStatusOptions: string[];
      public userLevelOptions: string[];

      bankaccounts: BankAccountViewModel[];

  isBankLoading = false;

      ngOnInit() {
        if (!this.authService.loggedIn.getValue()) {
          this.router.navigate(['/login']);
          return;
        }

        const data = {} as any;
        this.user = data;

        this.id = this.route.snapshot.params['id'];

        this.isAdmin = this.authService.isAdmin();
        this.initializeForm();

        this.roleOptions = ROLE_NAMES.map(x => x.value);
        this.userStatusOptions = USER_STATUS_NAMES.map(x => x.value);
        this.userLevelOptions = USER_LEVEL_OPTIONS.map(x => x.value);
      }

      ngAfterViewInit() {
          this.getUserItem();
      }

      getUserItem(id?: number) {
        this.isLoading = true;
        const request = new GetItemRequest();

        if (id) {
          request.id = Number(id);
        } else {
          request.id = Number(this.id);
        }

        this.userService.getOne(request).subscribe((data) => {
          if (data.isSuccess) {
            this.user = data.item;
            this.isLoading = false;
            this.initializeForm();
            this.getBankData();
          }
        });
      }

      ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
      }

      onUserSubmit(event: any) {
    event.preventDefault();
    this.submitted = true;

    if (this.frmUser.valid) {
      this.update(this.frmUser.value);
    }

      }

      private update(request: userModel.UpdateUserRequest) {
          request.id = Number(this.id);
          this.userService.update(request).subscribe((data) => {
            if (data.isSuccess) {
                this.toastr.success(MESSAGES.user.update.success);
              }
            }, (err) => {
              console.log(err);
              this.toastr.error(MESSAGES.user.update.error);
            });
      }

      private initializeForm() {
        this.buildForm();
      }

      buildForm() {
        const IS_EDITING = !this.isAddMode;

        this.frmUser = this.formBuilder.group({
          email: new FormControl(IS_EDITING ? this.user.email : null),
          mobile: new FormControl(IS_EDITING ? this.user.mobile : null),
          phone: new FormControl(IS_EDITING ? this.user.phone : null),
          name: new FormControl(IS_EDITING ? this.user.name : null),
          surname: new FormControl(IS_EDITING ? this.user.surname : null),
          fatherName: new FormControl(IS_EDITING ? this.user.fatherName : null),
          nationalCode: new FormControl(IS_EDITING ? this.user.nationalCode : null),

          // OnlyForUpdate
          isLock: new FormControl(IS_EDITING ? this.user.isLock : null),
          role: new FormControl(IS_EDITING ? this.user.role : null),
          userStatus: new FormControl(IS_EDITING ? this.user.userStatus : null),
          isEmailConfirmed: new FormControl(IS_EDITING ? this.user.isEmailConfirmed : null),
          isMobileConfirmed: new FormControl(IS_EDITING ? this.user.isMobileConfirmed : null),
          isPhoneConfirmed: new FormControl(IS_EDITING ? this.user.isPhoneConfirmed : null),
          isNationalCardVerified: new FormControl(IS_EDITING ? this.user.isNationalCardVerified : null),
          userLevel: new FormControl(IS_EDITING ? this.user.userLevel : null),
        });
      }



  submitMeliCard() {
    if (this.meliCard != undefined) {
      const request = new userModel.CreateAttachmentRequest();
      request.file = this.meliCard;
      request.type = userModel.UserAttachmentType.MeliCard;
      request.userId = Number(this.id);

      this.attachmentService.create(request).subscribe((data) => {

        if (data.isSuccess) {
          this.toastr.success(MESSAGES.user.attachment.create.success);
          if (!this.user.attachments) {
            this.user.attachments = [];
          }
          const meliCardItem = this.user.attachments.find(x => x.type == userModel.UserAttachmentType.MeliCard);
          if (meliCardItem) {
            meliCardItem.id = data.id;
          } else {
          }

          this.meliCard = [];
        }
      }, (err) => {
        this.toastr.error(MESSAGES.user.attachment.create.error);
      });
    } else {
      // error
    }
  }

  deleteAttachment(id: number) {
    const index = this.user.attachments.findIndex(y => y.id == id);
    const request = new baseModels.DeleteRequest();
    request.id = [];
    request.id.push(id);
    this.attachmentService.delete(request)
    .subscribe(x => {
      if (x.isSuccess) {
        this.user.attachments.splice(index, 1);
        this.toastr.success(MESSAGES.user.attachment.delete.success);
      }
    }, err => {
      this.toastr.error(MESSAGES.user.attachment.delete.error);
    });
  }
  getBankData() {
    this.isBankLoading = true;
    const request = new bankModel.BankAccountGetListRequest();
    request.customerId = Number(this.id);
    request.pageSize = 50;
    this.bankService.getList(request).subscribe(x => {
      if (x.isSuccess) {
        this.bankaccounts = x.list;
        this.isBankLoading = false;
      }
    }, err => {
    this.isBankLoading = false;

    });
  }

}
