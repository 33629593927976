<div class="registerContainer">
<div class="container"
[ngClass]="{ 'right-panel-active':!isSignUp }" 
id="container">
	<div class="form-container sign-up-container">
		<div class="dir-rtl form">
			<h1>ایجاد حساب</h1>
			<input type="text" [(ngModel)]="user.name" placeholder="نام" />
			<input type="text" [(ngModel)]="user.surname" placeholder="نام خانوادگی"/>
			<input type="email" [(ngModel)]="user.email" placeholder="ایمیل" />
			<input type="tel" [(ngModel)]="user.mobile" placeholder="موبایل"/>
			<button class="pointer" (click)="sendOtp('register')">{{sendCodeText}}</button>
		</div>
	</div>
	<div class="form-container sign-in-container" *ngIf="!registerMode">
		<div class="dir-rtl form" *ngIf="!isLoginOtpSent">
			<h1>ورود</h1>
			<input type="tel" [(ngModel)]="user.mobile" placeholder="موبایل"/>
			<button class="pointer" (click)="sendOtp('login')">{{sendCodeText}}</button>
		</div>
		<div class="dir-rtl form" *ngIf="isLoginOtpSent">
			<h1>تایید کد یکبار مصرف</h1>
			<input type="tel" [(ngModel)]="code" placeholder="کد یکبار مصرف"/>
			<button class="pointer" (click)="Login()">تایید</button>
			<small class="mt-1">{{timeLeft}} ثانیه باقی مانده</small>
		</div>
	</div>
	<div class="form-container sign-in-container" *ngIf="registerMode">
		<div class="dir-rtl form">
			<h1>تایید موبایل</h1>
			<input type="tel" [(ngModel)]="code" placeholder="کد یکبار مصرف"/>
			<button class="pointer" (click)="register()">تایید</button>
			<small class="mt-1">{{timeLeft}} ثانیه باقی مانده</small>
		</div>
	</div>
	<div class="overlay-container-register">
		<div class="overlay">
			<div class="overlay-panel overlay-left">
        		<h1 class="dir-rtl">مجددا خوش آمدید!</h1>
				<p class="dir-rtl">برای ورود، لطفا حساب خود را وارد نمایید. اگر تا به حال در سایت ما عضو نشده اید همین حالا و با چند قدم ساده عضو شوید.</p>
				<button class="dir-rtl" class="ghost pointer"  (click)="changeCol(true)">ورود</button>
			</div>
			<div class="overlay-panel overlay-right" *ngIf="registerMode">
				<h1 class="dir-rtl">ثبت نام</h1>
				<p class="dir-rtl">کد یکبار مصرف برای شماره موبایل شما ارسال شد، لطفا بلافاصله پس از دریافت آن را وارد کنید</p>
				<button class="ghost pointer" (click)="changeCol(false)">ثبت نام</button>
			</div>
			<div class="overlay-panel overlay-right" *ngIf="!registerMode">
				<h1 class="dir-rtl">ثبت نام</h1>
				<p class="dir-rtl">برای ورود، لطفا حساب خود را وارد نمایید. اگر تا به حال در سایت ما عضو نشده اید همین حالا و با چند قدم ساده عضو شوید.</p>
				<button class="ghost pointer" (click)="changeCol(false)">ثبت نام</button>
			</div>
		</div>
	</div>
</div>
<footer>
	<p>
</p>
</footer>

</div>
