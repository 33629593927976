import { Component, AfterViewInit, ViewChild, ChangeDetectorRef, OnInit, AfterViewChecked, Input } from '@angular/core';

import { Router } from '@angular/router';

import { OrderService } from '~app/services/order.service';

import * as baseModel from '~app/models';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~utils/messages';
import { OrderGetListRequest, OrderViewModel } from '~app/models/orders';
import { Pager } from '../ng-pager/pager.model';
import { NgxSmartModalService } from 'ngx-smart-modal';


@Component({
    selector: 'app-order-list.row , app-order-list',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
    providers: [OrderService]
  })
  export class OrderComponent implements AfterViewInit, OnInit, AfterViewChecked {
    pager: Pager;
    public isAdmin = false;
    public pageSize = 20;
    public resultsLength = 0;
    public page = 0 ;
    public isLoading = false;
    public isTotalReached = false;
    public totalItems = 0;
    public search = '';
    public customerId = null;
    public orders: OrderViewModel[] = [];
    key:string;
    code:null | string;
    public currentPage = 0;
    constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private orderService: OrderService,
      private toastr: ToastrService,
      private ngxSmartModalService: NgxSmartModalService
    ) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
      this.getData();
    }

    ngAfterViewChecked() {
      this.changeDetectorRef.detectChanges();
    }
    getData(request ?: OrderGetListRequest): void {

      if (request == undefined) {
        request = new OrderGetListRequest();
        request.pageSize = this.pageSize;
      }
      if(this.key != undefined){
        request.key = this.key;
      }
      if(this.code != undefined){
        request.code = this.code;
      }


      this.orderService.getList(request).subscribe((x) => {
        if (x.isSuccess) {
          this.orders = x.list;
          this.page = x.currentPage;
          this.totalItems = x.totalItems;
          this.currentPage = request == undefined ? 0 : request.pageIndex;
          this.pager = new Pager( x.totalItems, this.currentPage, this.pageSize);
          this.isLoading = true;
        }
      }, (err) => {
        this.isLoading = true;
      });
    }

    selectedOrder:OrderViewModel={};
    detail(item:OrderViewModel){
      this.selectedOrder = item;
      this.ngxSmartModalService.getModal('adminOrderModal').open();
    }

  }
