  import {
    Pipe,
    PipeTransform
  } from '@angular/core';
  
  @Pipe({
    name: 'isactive'
  })
  export class IsActivePipe implements PipeTransform {
  
    transform(isActive: boolean | null): string {
      if(isActive == undefined){
        return '';
      }
       if(isActive==true){
        return 'فعال';
       }
       return 'غیر فعال';
    }
  }
  