
import { ChangeDetectorRef, NgZone, ElementRef, ViewChild, Renderer2, OnDestroy, AfterViewInit, ViewEncapsulation, Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { UserViewModel } from '~app/models/user/UserViewModel';
import { UserService } from '~app/services/user.service';
import { Observable  } from 'rxjs';

import { AuthService } from '../../services/auth.service';

import { GlobalService } from '~app/services/global.service';

import { interval } from 'rxjs';
import { StatsViewModel } from '~app/models/global/StatsViewModel';
import { TicketService } from '~app/services/ticket.service';
import { GetListTicketRequest, TicketViewModel } from '~app/models/ticket';
import {isNumeric} from 'rxjs/util/isNumeric';
@Component({
    selector: 'app-customer-dashboard.col-xl-9.col-lg-9',
    templateUrl: './customerDashboard.component.html',
    styleUrls:['./customerDashboard.component.scss']
  })

  export class CustomerDashboardComponent implements OnInit, AfterViewInit {
    user: UserViewModel;
    isLoggedIn$: Observable<boolean>;
    isBuyer: boolean;
    isSeller: boolean;
    isAdmin: boolean;
    isCustomer: boolean;
    stats: StatsViewModel;
    tickets : TicketViewModel[];
    constructor(
      private authService: AuthService,
    private userService: UserService,
    private globalService: GlobalService,
    private ticketService:TicketService,
    ) { }
  ngAfterViewInit(): void {
  }
  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;

    this.isAdmin = this.authService.isAdmin();
    this.isBuyer = this.authService.isBuyer();
    this.isSeller = this.authService.isSeller();
    this.isCustomer = this.authService.isCustomer();

    const temp = {} as any;
    this.stats = temp;
    this.user = temp;
    this.tickets = temp;

    this.me();
    this.getStats();
    this.getTickets();
    this.initInterVals();
  }

  getStats() {
    this.globalService.getOne().subscribe((data) => {
      if (data.isSuccess) {
        this.stats = data.item;
        if(isNumeric(this.stats.smsCharge)){
          this.stats.smsCharge = Math.round(Number(this.stats.smsCharge));
        }
      }
    });
  }

  getTickets(){
    const request = new GetListTicketRequest();
    request.pageSize= 20;
    this.ticketService.getList(request).subscribe((data)=>{
      if(data.isSuccess){
        this.tickets = data.list; 
      }
    })
  }

    me() {
      this.userService.me().subscribe((data) => {
        if (data.isSuccess) {
          this.user = data.item;
        }
      });
    }

    initInterVals() {
      const second = 1000; // every 1 sec
      const minute = second * 60;
      const delay = minute * 1;
  
  
      const getStateInterval = interval(delay);
      getStateInterval.subscribe(x => { // will execute every 30 seconds
        this.getStats();
      });
  
    }

  }
