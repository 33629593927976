import { Component } from '@angular/core';
import { AppCorkButtonBaseComponent } from './base.component';

@Component({
  selector: 'ck-btn-info',
  templateUrl: './base.component.html'
})
export class AppCorkButtonInfoComponent extends AppCorkButtonBaseComponent {
  className = 'btn-info';
}
