// const HOST = 'http://localhost:8081/';
const HOST = '';
const CPANEL = 'api/cpanel/';

export const CONSTANST = {
    permissions: {},
    routes: {

        authorization: {
            login:            HOST + '/api/auth/login',
            otp :             HOST + '/api/auth/otp',
            register :        HOST + '/api/auth/register',
            verifyOtp :       HOST + '/api/auth/verify-otp',
            fakeAdmin :       HOST + '/api/auth/fakeAdmin',
            fakeBuyer :       HOST + '/api/auth/fakeBuyer',
            fakeSeller :      HOST + '/api/auth/fakeSeller',
            fakeBuyerSeller : HOST + '/api/auth/fakeBuyerSeller',
        },
        client: {
            list:   HOST + '/api/client',
            delete: HOST + '/api/client/delete',
            save:   HOST + '/api/client/save',
            get:    HOST + '/api/client/:id'
        },
        ticket: {
            list :      HOST + CPANEL + 'tickets',
            get :       HOST + CPANEL + 'tickets/:id',
            update :    HOST + CPANEL + 'tickets/:id',
            create :    HOST + CPANEL + 'tickets',
            reply :     HOST + CPANEL + 'tickets/:ticketId',
            attachment:
            {
                create :    HOST + CPANEL + 'tickets/:ticketId/attachments',
                getOne :    HOST + CPANEL + 'tickets/:ticketId/attachments/:id',
                delete :    HOST + CPANEL + 'tickets/:ticketId/attachments'
            },
        },
        user: {
            list:       HOST + CPANEL + 'users',
            get:        HOST + CPANEL + 'users/:id',
            update:     HOST + CPANEL + 'users/:id',
            updateCustomer:     HOST + CPANEL + 'users/:id/customer',
            create:     HOST + CPANEL + 'users',
            delete:     HOST + CPANEL + 'users',

            changerole:               HOST + CPANEL + 'users/:id/changerole',
            changelock:               HOST + CPANEL + 'users/:id/changelock',
            sendconfirmationmail:     HOST + CPANEL + 'users/:id/sendconfirmationmail',
            sendmobileconfirmation:   HOST + CPANEL + 'users/:id/sendmobileconfirmation',
            verifyconfirmationmobile: HOST + CPANEL + 'users/verifyconfirmationmobile',
            verifyconfirmationmail:   HOST + CPANEL + 'users/verifyconfirmationmail',

            sellerList:   HOST + CPANEL + 'users/sellers',
            buyerList:   HOST + CPANEL + 'users/buyers',
            customerList:   HOST + CPANEL + 'users/customers',

            me: HOST + CPANEL + 'users/me',
            wannaBeSeller: HOST + CPANEL + 'users/WannaBeSeller',

            attachment : {
                get : HOST + CPANEL + 'users/attachments/:id',
                create : HOST + CPANEL + 'users/attachments',
                delete : HOST + CPANEL + 'users/attachments',
            }
        },
        bankaccount: {
            get:    HOST + CPANEL + 'bankaccount/:id',
            create: HOST + CPANEL + 'bankaccount',
            lock:   HOST + CPANEL + 'bankaccount/:id/lock',
            unlock: HOST + CPANEL + 'bankaccount/:id/unlock',
            update: HOST + CPANEL + 'bankaccount/:id',
            list:   HOST + CPANEL + 'bankaccount',
            delete: HOST + CPANEL + 'bankaccount'
        },
        variant: {
            list:   HOST + CPANEL + 'variants/:productId',
            get:    HOST + CPANEL + 'variants/:id',
            update: HOST + CPANEL + 'variants/:id',
            create: HOST + CPANEL + 'variants',
            delete: HOST + CPANEL + 'variants'
        },
        product: {
            list:   HOST + CPANEL + 'products',
            get:    HOST + CPANEL + 'products/:id',
            update: HOST + CPANEL + 'products/:id',
            create: HOST + CPANEL + 'products',
            delete: HOST + CPANEL + 'products'
        },
        variz: {
            list:   HOST + CPANEL + 'variz',
            get:    HOST + CPANEL + 'variz/:id',
            update: HOST + CPANEL + 'variz/:id',
            create: HOST + CPANEL + 'variz',
            delete: HOST + CPANEL + 'variz',
            attachment : {
                get: HOST + CPANEL + 'variz/' + 'attachments/:id',
                create: HOST + CPANEL + 'variz/' + 'attachments',
                delete: HOST + CPANEL + 'variz/' + 'attachments'
            }
        },
        license: {
            list:   HOST + CPANEL + 'license',
            get:    HOST + CPANEL + 'license/:id',
            update: HOST + CPANEL + 'license/:id',
            updateRange: HOST + CPANEL + 'license',
            sold:   HOST + CPANEL + 'license/:code/sold',
            create: HOST + CPANEL + 'license',
            createRangeWithFile: HOST + CPANEL + 'license/bulkWithFile',
            createRange: HOST + CPANEL + 'license/bulk',
            checkRange: HOST + CPANEL + 'license/checkbulk',
            checkRangeWithFile: HOST + CPANEL + 'license/checkbulkWithFile',
            delete: HOST + CPANEL + 'license',
            checkExist: HOST + CPANEL + 'license/:code/CheckExist',
        },
        global: {
            stats : HOST + CPANEL + 'global/stats',
            telegramAlive : HOST + CPANEL + 'health/TelegramBotAliveCheck'
        },
        orders:{
            get : HOST + CPANEL + 'orders/:id',
            list : HOST + CPANEL + 'orders',
        }

    },
    lang: {},
    session: {},
    parameters: {}
};
