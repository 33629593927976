import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  AfterViewChecked,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '~app/services/auth.service';
import { UserService } from '~app/services/user.service';
import * as userModels from '~app/models/user/index';
import * as baseModels from '~app/models';
import { UserViewModel } from '~app/models/user/index';

import { VarizService } from '~app/services/variz.service';
import * as varizModel from '~models/variz/index';

import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~utils/messages';
import { VarizViewModel } from '~models/variz/index';
import { FileModel } from '~app/models/FileModel';
import { UtilitiesService } from '~app/services/utilities.service';
import { VarizAttachmentService } from '~app/services/varizAttachment.service';
import * as moment from 'jalali-moment';
@Component({
  selector: 'app-forms.row',
  templateUrl: './form.component.html',
})
export class FormComponent
  implements AfterViewInit, OnInit, AfterViewChecked, OnDestroy {
  selectedUserId: number;
  payDate:string;
  files: FileModel[];
  frmMain: FormGroup;
  submitted = false;
  public id?: number;
  public isAddMode = false;
  public isLoading = false;
  public isCustomerLoading = false;
  public isAdmin = false;
  public variz: VarizViewModel = {};
  public customers: UserViewModel[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,

    private authService: AuthService,
    private varizService: VarizService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private utilities: UtilitiesService,
    private attachmentService: VarizAttachmentService
  ) { }
  ngOnDestroy(): void { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.isAdmin = this.authService.isAdmin();

    this.initializeForm();
  }
  get f() {
    return this.frmMain.controls;
  }
  initializeForm() {
    const IS_EDITING = !this.isAddMode;
    this.frmMain = this.formBuilder.group({
      description: new FormControl(IS_EDITING ? this.variz.description : null),
      payDate: new FormControl(IS_EDITING ? this.variz.payDate : Date.now()),
      price: new FormControl(IS_EDITING ? this.variz.price : null),
      userId: new FormControl(IS_EDITING ? this.variz.userId : null),
      id: new FormControl(IS_EDITING ? this.variz.id : null),
      files: new FormControl(null),
    });
    this.selectedUserId = Number(this.variz.userId);
    this.payDate =  this.variz.payDate;
  }

  ngAfterViewInit() {
    if (this.isAdmin) {
      this.getCustomers();
    }

    if (!this.isAddMode) {
      this.getVarizItem();
    }
  }

  getVarizItem() {
    this.isLoading = true;
    const request = new baseModels.GetItemRequest();
    request.id = this.id;

    this.varizService.getOne(request).subscribe((data) => {
      if (data.isSuccess) {
        this.variz = data.item;
        this.isLoading = false;
        this.initializeForm();
      }
    });
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  getCustomers(): void {
    this.isCustomerLoading = true;
    const request = new userModels.SearchUserRequest();
        request.pageSize = 999999;
        this.userService.getList(request).subscribe(
      (data) => {
        if (data.isSuccess) {
          this.customers = data.list;
          this.isCustomerLoading = false;
          this.initializeForm();
        }
      },
      () => { }
    );
  }

  public create(value: varizModel.CreateVarizRequest) {
    const request = { ...value };
    request.payDate = this.utilities.longToDate(request.payDate);

    if (this.files != undefined) {
      if (this.files.length > 0) {
        request.files = [...this.files];
      }
    }

    request.userId = Number(request.userId);
    this.varizService.create(request).subscribe(
      (data) => {
        if (data.isSuccess) {
          this.toastr.success(MESSAGES.variz.create.success);
          this.router.navigate(['/admin/variz']);
          this.ClearFiles();
        }
      },
      () => {
        this.toastr.error(MESSAGES.variz.create.error);
      }
    );
  }

  public update(value: varizModel.UpdateVarizRequest) {
    const request = { ...value };
    

    if (this.files != undefined) {
      if (this.files.length > 0) {
        request.files = [...this.files];
      }
    }

    request.id = Number(this.id);
    request.userId = Number(request.userId);
    this.varizService.update(request).subscribe(
      (data) => {
        if (data.isSuccess) {
          this.toastr.success(MESSAGES.variz.update.success);
          this.router.navigate(['/admin/variz']);
          this.ClearFiles();
        }
      },
      () => {
        this.toastr.error(MESSAGES.variz.update.error);
      }
    );
  }

  public delete() {
    const request = new baseModels.DeleteRequest();
    request.id.push(this.id);

    this.varizService.delete(request).subscribe(
      (data) => {
        if (data.isSuccess) {
          this.toastr.success(MESSAGES.variz.delete.success);
          this.router.navigate(['/admin/variz']);
        }
      },
      () => {
        this.toastr.error(MESSAGES.variz.delete.error);
      }
    );
  }

  onSubmit(event: any) {
    this.frmMain.controls['userId'].setValue(Number(this.selectedUserId));
    this.frmMain.controls['payDate'].setValue(this.utilities.longToDate(this.payDate));

    event.preventDefault();
    this.submitted = true;

    if (this.frmMain.valid) {
      if (this.isAddMode) {
        this.create(this.frmMain.value);
      } else {
        this.update(this.frmMain.value);
      }
    }
  }

  deleteAttachment(id: number) {
    const index = this.variz.attachments.findIndex((y) => y.id == id);
    const request = new baseModels.DeleteRequest();
    request.id = [];
    request.id.push(id);
    this.attachmentService.delete(request).subscribe(
      (x) => {
        if (x.isSuccess) {
          this.variz.attachments.splice(index, 1);
          this.toastr.success(MESSAGES.variz.attachment.delete.success);
        }
      },
      () => {
        this.toastr.error(MESSAGES.variz.attachment.delete.error);
      }
    );
  }

  ClearFiles(): void {
    this.files = [];
  }
}
