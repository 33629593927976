
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '~services/auth.service';

import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate  {

  constructor(private authService: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.authService.isExpired()) {
      this.router.navigate(['']);
      return false;
    }

    return true;
  }
}
