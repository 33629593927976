import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from '~services/auth.service';
import { UserService } from '~app/services/user.service';
import { UserViewModel } from '~app/models/user/UserViewModel';
import { NgPopupsService } from 'ng-popups';
import { MESSAGES } from '~app/utils/messages';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare var $: any;

@Component({
    selector: 'app-customer-layout',
    templateUrl: './customer-layout.component.html',
    styleUrls:['./customer-layout.component.scss'],

    encapsulation: ViewEncapsulation.None,
    providers: [AuthService]
  })
  
  export class CustomerLayoutComponent implements AfterViewInit,OnInit {
    user: UserViewModel;
    
    isBuyer: boolean;
    isSeller: boolean;
    isAdmin: boolean;
    isCustomer: boolean;
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private ngPopups: NgPopupsService,
    public ngxSmartModalService: NgxSmartModalService
    ) {
    this.loadScripts();
  }
  @ViewChild(TemplateRef, { static: false }) tpl: TemplateRef<any>;
  ngOnInit(): void {
    this.me();
    
    this.isAdmin = this.authService.isAdmin();
    this.isBuyer = this.authService.isBuyer();
    this.isSeller = this.authService.isSeller();
    this.isCustomer = this.authService.isCustomer();

    const temp = {} as any;
    this.user = temp;
  }
   



  
  
  ngAfterViewInit() {
    $('body').removeClass( 'alt-menu sidebar-noneoverflow' );
    $('html').removeAttr('dir');

    $('[data-toggle="tooltip"]').tooltip();
    console.log('remove attr');
    
    // $('body').on('click','#closeCustomerSideMenu',()=>{

    // });



    if( window.localStorage )
    {
      if( !localStorage.getItem('firstLoad') )
      {
        localStorage['firstLoad'] = true;
        window.location.reload();
      }  
      else
        localStorage.removeItem('firstLoad');
    }
}

loadScripts() {
  const dynamicScripts = [
    '/assets/js/customer/jQuery.js',
    '/assets/js/customer/bootstrap.min.js',
    '/assets/js/customer/jquery.appear.min.js',
    '/assets/js/customer/aup-main.js',
  ];
  for (let i = 0; i < dynamicScripts.length; i++) {
    const node = document.createElement('script');
    node.src = dynamicScripts[i];
    node.type = 'text/javascript';
    node.async = false;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}

logout(){
  this.authService.destroy();
  this.router.navigate(['/login']);
}

me() {
  this.userService.me().subscribe((data) => {
    if (data.isSuccess) {
      this.user = data.item;
    }
  });
}

@ViewChild(TemplateRef, { static: false }) userLevelInfo: TemplateRef<any>;




}