import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { TicketService } from '~services/ticket.service';
import * as ticketModels from '~app/models/ticket';
import * as models from '~app/models';
import { CreateTicketRequest, TicketViewModel, CreateReplyRequest } from '~app/models/ticket';
import { AuthService } from '~app/services/auth.service';
import { UserService } from '~app/services/user.service';
import { UserViewModel } from '~app/models/user/UserViewModel';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { GetItemRequest } from '~app/models';
import { TicketStatus } from '~app/models/ticket/TicketStatus';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~app/utils/messages';
import { FileModel } from '~app/models/FileModel';
import { SearchUserRequest } from '~app/models/user/SearchUserRequest';

@Component({
  selector: 'app-forms.row',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements AfterViewInit, OnInit , AfterViewChecked {
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private authService: AuthService,
        private fb: FormBuilder,
        private ticketService: TicketService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService

      ) { }
  get f() { return this.frm.controls; }
  public ticketStatus: TicketStatus = TicketStatus.Open;
    isSubmitted = false;
    public id ?: number;
    public isAddMode = false;
    public frm: FormGroup;
    public isLoading = false;
    public isAdmin = false;
    customerCtrl = new FormControl();
    public customers: UserViewModel[] = [];
    public ticket: TicketViewModel = {};
    ticketStatuses=['Closed','Open','InProgress','OnHold','PendingCustomerReply'];
    ticketStatusSelected:TicketStatus = TicketStatus.Open;
    customerId: number;

      modifiedAt = new Date();

      ss: any;
      files: FileModel[];
      rawFiles: any;
      ngOnInit() {
        const temp = {} as any;
        const temp2 = [] as any;
        this.ticket = temp;
        this.ticket.replies = temp2;

        this.modifiedAt = new Date();

        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;

        this.isAdmin = this.authService.isAdmin();
        this.initializeForm();

      }

      updateSelectedCustomer(event) {
        console.log(event);
      }
      ngAfterViewInit() {
        if (this.isAdmin && this.isAddMode) {
          this.getCustomers();
        }

         if (!this.isAddMode) {
          this.getTicketItem();
        }
      }
      private initTicket() {
        this.modifiedAt = this.ticket.replies[this.ticket.replies.length - 1].createdAt;
      }

      selectUserChange(event) {
        console.log(event);
      }

      getTicketItem() {
        this.isLoading = true;
        const request = new GetItemRequest();
        request.id = this.id;

        this.ticketService.getOne(request).subscribe((data) => {
          if (data.isSuccess) {
            this.ticket = data.item;
            this.ticketStatus = this.ticket.ticketStatus;
            this.isLoading = false;
            this.initTicket();
            this.ticketStatusSelected = data.item.ticketStatus;
            this.customerId = data.item.customerId;
          }
        });
      }

      ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
      }

      getCustomers(): void {
        const request = new SearchUserRequest();
        request.pageSize = 999999;
        this.userService.getList(request).subscribe((data) => {
          if (data.isSuccess) {
            this.customers = data.list;
          }
        });
      }

      changeStatus() {
       const newStatus = this.ticketStatusSelected;
       if(newStatus == undefined || newStatus == null){
         return;
       }
       
        const request = new ticketModels.UpdateTicketRequest();
        request.id = Number(this.id);
        request.ticketStatus = newStatus;

        this.ticketService.update(request).subscribe((data) => {
          if (data.isSuccess) {
            this.toastr.success(MESSAGES.ticket.changeStatus.success);
          }
        }, (err) => {
          this.toastr.error(MESSAGES.ticket.changeStatus.error);
        });
      }

      private initializeForm() {
        this.modifiedAt = new Date();
        const temp = {} as any;
        this.ticket = temp;
        if (this.isAddMode) {
          this.frm = this.fb.group({
            customerId : new FormControl(null, [Validators.required]),
            subject: new FormControl(null, [Validators.required]),
            message: new FormControl(null, [Validators.required]),
            files : new FormControl(null)
          });
        } else {
          this.frm = this.fb.group({
            message: new FormControl(null, [Validators.required]),
            files :  new FormControl(null)
          });
        }
      }


      public reply(value: ticketModels.CreateReplyRequest) {
        console.log('reply');
        const request = {...value};
        request.ticketId = Number(this.id);

        if (this.files != undefined) {
          if (this.files.length > 0) {
            request.files = [...this.files];
          }
        }

        this.ticketService.reply(request).subscribe((data) => {
          if (data.isSuccess) {
            this.toastr.success(MESSAGES.ticket.reply.success);
            this.router.navigate(['/admin/tickets']);
          }
        }, (err) => {
          this.toastr.error(MESSAGES.ticket.reply.error);
        });
      }

  public create(value: ticketModels.CreateTicketRequest) {
    const request = {...value};

    if (this.files != undefined) {
      if (this.files.length > 0) {
        request.files = [...this.files];
      }
    }


    request.customerId = Number(request.customerId);
    this.ticketService.create(request).subscribe((data) => {
      if (data.isSuccess) {
        this.isAddMode = false;
        this.router.navigate(['/admin/tickets']);
        this.toastr.success(MESSAGES.ticket.create.success);
      }
    }, (err) => {
      this.toastr.error(MESSAGES.ticket.create.error);
    });
  }

  public onSubmit(event: any) {
    if(this.isAddMode){
      this.frm.controls['customerId'].setValue(Number(this.customerId));
    }
    event.preventDefault();
    this.isSubmitted = true;
    console.log(this.frm.valid);
      if (this.frm.valid) {
        if (!this.isAddMode) {
          this.reply(this.frm.value);
        } else {
          this.create(this.frm.value);
        }
        this.ClearFiles();
      }
  }

  public getSubjectErrorMessage() {
    if (this.isAddMode) {
      return this.frm.controls.subject.hasError('required') ? 'عنوان الزامی است' :
      this.frm.controls.subject.hasError('minlength') ? 'حداقل ۳ کاراکتر بنویسید' : '';
    }
  }

  public getMessageErrorMessage() {
    return this.frm.controls.message.hasError('required') ? 'پیغام ارزامی است' :
      this.frm.controls.message.hasError('minlength') ? 'حداقل ۱۰ کاراکتر بنویسید' : '';
  }

  onFileChanges($event) {
  }

  ClearFiles(): void {
    this.files = [];
  }

}
