<div class="col-lg-12 col-12 idkSpacing">
    <div class="statbox widget box box-shadow">
        <div class="widget-header">
            <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
                        <h4 *ngIf="isAddMode">ثبت محصول جدید</h4>
                        <h4 *ngIf="!isAddMode">ویرایش محصول</h4>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
                        <ck-btn-cancel class="back-button" routerLink="/admin/products"></ck-btn-cancel>
                    </div>
            </div>
        </div>
        <div class="widget-content widget-content-area">
            

            <form #frmProduct="ngForm" (ngSubmit)="onProductSubmit(frmProduct.value)">
                <div class="form-row mb-4">
                    <div class="form-group col-md-6">
                        <label for="name">نام محصول</label>
                        <input type="text" class="form-control" name="name" [(ngModel)]="product.name" id="name" placeholder="نام محصول">
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-check pl-0">
                        <div class="custom-control custom-checkbox checkbox-info">
                            <input type="checkbox" class="custom-control-input" name="isActive" [(ngModel)]="product.isActive" id="isActive">
                            <label class="custom-control-label" for="isActive">فعال؟</label>
                        </div>
                    </div>
                </div>
                <ck-btn-save>ذخیره محصول</ck-btn-save>
            </form>

        </div>
    </div>
</div>
    
<div class="col-lg-12 col-12 idkSpacing">
    <div class="statbox widget box box-shadow">
        <div class="widget-header">
            <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-right-side">
                       <ck-btn-primary style="margin-right: 10px;" (click)="addNewVariant()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        تنوع جدید
                        </ck-btn-primary>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6 card-left-side">
                        <ck-btn-cancel class="back-button" routerLink="/admin/products"></ck-btn-cancel>
                    </div>
                 
            </div>
        </div>
        <div class="widget-content widget-content-area">
            

            <form *ngIf="!closeDialog" #frmVariant="ngForm" (ngSubmit)="onVariantSubmit(frmVariant.value)">
                <div class="form-row mb-4">
                    <div class="form-group col-md-6">
                        <label for="variantName">نام تنوع</label>
                        <input type="text" class="form-control" name="variantName"
                         [(ngModel)]="inEditVariant.variantName" id="variantName" placeholder="نام محصول">
                    </div>
                </div>

                <div class="form-row mb-4">
                    <div class="form-group col-md-6">
                        <label for="wordpressVariantId">شناسه تنوع وردپرس</label>
                        <input type="number" class="form-control" name="wordpressVariantId"
                         [(ngModel)]="inEditVariant.wordpressVariantId" id="wordpressVariantId" placeholder="شناسه تنوع وردپرس">
                    </div>
                </div>

                <div class="form-row mb-4">
                    <div class="form-group col-md-6">
                        <label for="price">قیمت</label>
                        <input type="number" class="form-control" name="price"
                         [(ngModel)]="inEditVariant.price" id="price" placeholder="قیمت">
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-check pl-0">
                        <div class="custom-control custom-checkbox checkbox-info">
                            <input type="checkbox" class="custom-control-input" name="isActive" [(ngModel)]="inEditVariant.isActive" id="isActiveVariant">
                            <label class="custom-control-label" for="isActiveVariant">فعال؟</label>
                        </div>
                    </div>
                </div>
                <ck-btn-save>ذخیره تنوع</ck-btn-save>
                <ck-btn-cancel (click)="closeVariant()"></ck-btn-cancel>
            </form>




            <div class="table-responsive">
                <div>
                </div>
                <table class="table table-bordered table-hover table-striped table-checkable table-highlight-head mb-4">
                    <thead>
                        <tr>
                            <th class="checkbox-column">
                                <label class="new-control new-checkbox checkbox-primary" style="height: 18px; margin: 0 auto;">
                                    <input type="checkbox" class="new-control-input todochkbox" id="todoAll">
                                    <span class="new-control-indicator"></span>
                                </label>
                            </th>
                            <th class="">نام</th>
                            <th class="">قیمت</th>
                            <th class="">شناسه تنوع وردپرس</th>
                            <th class="">وضعیت</th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>


            <tr *ngFor="let row of variants; index as i;">
                <td class="checkbox-column">
                    <label class="new-control new-checkbox checkbox-primary" style="height: 18px; margin: 0 auto;">
                        <input type="checkbox" class="new-control-input todochkbox">
                        <span class="new-control-indicator"></span>
                    </label>
                </td>
                <td>
                    <p class="mb-0">{{row.variantName}}</p>
                </td>
                <td>
                    {{row.price}}
                </td>
                <td>{{row.wordpressVariantId}}</td>
                <td>{{row.isActive | isactive }}</td>

                <td class="text-center">
                    <ul class="table-controls">
                        <li>
                            <button class="btn btn-dark mb-2 mr-2 rounded-circle" (click)="editVariant(row.id)" data-toggle="tooltip" data-placement="top" title="Edit">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-edit-2 text-success">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                </svg>
                            </button>
                        </li>
                        <li>
                            <button class="btn btn-dark mb-2 mr-2 rounded-circle" (click)="deleteVariant(row.id)" data-toggle="tooltip" data-placement="top" title="حذف">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                  class="feather feather-trash-2 text-danger">
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                        </li>
                    </ul>
                </td>
            </tr>


        </tbody>
    </table>
</div>

</div>
</div>

         


        </div>
    
