import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, AfterViewChecked, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ProductService } from '~services/product.service';
import { VariantService } from '~services/variant.service';

import * as variantModels from '~app/models/productVariants';
import * as productModel from '~app/models/product';
import * as baseModel from '~app/models';

import { AuthService } from '~app/services/auth.service';

import { Location } from '@angular/common';
import { GetItemRequest } from '~app/models';
import { ProductViewModel } from '~app/models/product';
import { VariantViewModel } from '~app/models/productVariants';

import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '~app/utils/messages';

@Component({
  selector: 'app-forms.row',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements AfterViewInit, OnInit , AfterViewChecked {
  [x: string]: any;
    constructor(
        private changeDetectorRef: ChangeDetectorRef,

        private authService: AuthService,
        private productService: ProductService,
        private variantService: VariantService,
        private router: Router,
        private route: ActivatedRoute,
        private _location: Location,
        private toastr: ToastrService

      ) { }
    public isActive: true;
    public id ?: number;
    public isAddMode = false;
    public isLoading = false;
    public isAdmin;
    public product: ProductViewModel = { isActive : true, name : '' , variants : [  ] };
    public variants: VariantViewModel[] = [];
    public inEditVariant: VariantViewModel = { isActive : true , variantName : '' , productId : 0};

      public closeDialog = true;
  isVariantEditMode = false;

      ngOnInit() {

        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;

        this.isAdmin = this.authService.isAdmin();
        this.initializeForm();
        this.variants = [];
      }

      ngAfterViewInit() {
         if (!this.isAddMode) {
          this.getProductItem();
        } else {
          this.isActive = true;
        }
      }

      getProductItem(id?: number) {
        this.isLoading = true;
        const request = new GetItemRequest();

        if (id) {
          request.id = Number(id);
        } else {
          request.id = Number(this.id);
        }

        this.productService.getOne(request).subscribe((data) => {
          if (data.isSuccess) {
            this.product = data.item;
            this.variants = data.item.variants;
            this.isLoading = false;
          }
        });
      }

      ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
      }

      onProductSubmit(form) {
        console.log(form);
        if (this.isAddMode) {
          this.createProduct(form);
        } else {
          this.updateProduct(form);
        }
      }
      onVariantSubmit(form) {
        console.log(this.isVariantEditMode);
        if (!this.isVariantEditMode) {
          this.createVariant(form);
        } else {
          this.updateVariant(form);
        }
          this.clearVariantForm();
          this.closeVariant();
      }

      private initializeForm() {
        this.product = { isActive : true , name : '' , variants : []};
        this.variants = [];
      }

      public createVariant(request: variantModels.VariantCreateRequest): void {
        request.productId = Number(this.id);
        request.wordpressVariantId = Number(request.wordpressVariantId);
        this.variantService.create(request).subscribe((data) => {
          if (data.isSuccess) {
            const vm: VariantViewModel = {...request};
            vm.id = data.id;

            if (!this.variants) {
              this.variants = [];
            }
            this.variants.push(vm);
          }
        });
      }
      public closeVariant() {
        this.closeDialog = true;
      }

      public updateVariant(request: variantModels.VariantUpdateRequest): void {
        request.productId = Number(this.id);
        request.id = Number(this.inEditVariant.id);
        request.wordpressVariantId = Number(request.wordpressVariantId);

        this.variantService.update(request).subscribe((data) => {
          if (data.isSuccess) {
            this.toastr.success(MESSAGES.variant.update.success);
            let item = this.variants.find(x => x.id == request.id);
            item = request;
          }
        }, () => {
          this.toastr.error(MESSAGES.variant.update.error);
        });
      }

      public updateProduct(request: productModel.ProductUpdateRequest): void {
        request.id = Number(this.id);
        this.productService.update(request).subscribe((data) => {
          if (data.isSuccess) {
            this.toastr.success(MESSAGES.product.update.success);
            this.reload();
          }
        }, () => {
          this.toastr.error(MESSAGES.product.update.error);
        });
      }

      private reload() {
        this.getProductItem(this.id);
      }

  public createProduct(request: productModel.ProductCreateRequest): void {
    this.productService.create(request).subscribe((data) => {

      if (data.isSuccess) {
          this.id = data.id;
          this.isAddMode = false;
          this.toastr.success(MESSAGES.product.create.success);
      }
    }, () => {
      this.toastr.error(MESSAGES.product.create.error);
    });
  }

  public deleteVariant(id ?: number) {
    const request = new baseModel.DeleteRequest();
    request.id = [];
    request.id.push(id);

    this.variantService.delete(request).subscribe((data) => {

      if (data.isSuccess) {
        this.toastr.success(MESSAGES.variant.delete.success);
        this.reload();
      }
    }, () => {
      this.toastr.error(MESSAGES.variant.delete.error);
    });
  }

  public editVariant(id: number) {
    this.inEditVariant = this.variants.find(x => x.id == id);
    this.isVariantEditMode = true;
    this.closeDialog = false;
  }
  addNewVariant(): void {
    this.isVariantEditMode = false;
    this.clearVariantForm();
    this.closeDialog = false;
  }
  clearVariantForm(): void {
    this.isVariantEditMode = false;
    this.inEditVariant = { variantName : '' , isActive : true, productId : this.id , id : null};
  }

}
